import { t } from 'i18next';
import { ReportType } from 'src/pages/Dashboard/constants/ReportType';

export const getAverageValue = (average: any, type: ReportType): string => {
  if (!average) {
    return type === ReportType.Movement ? `0${t('seconds_unit_2')}` : '0';
  }
  if (type !== ReportType.Movement) {
    return `${average}`;
  }

  let wasPreviousTimeUnitRounded = false;

  let minutes = Math.round((average % 3600) / 60);
  if (minutes !== Math.floor((average & 3600) / 60)) {
    wasPreviousTimeUnitRounded = true;
  }

  let hours = wasPreviousTimeUnitRounded
    ? Math.floor((average % (3600 * 24)) / 3600)
    : Math.round((average % (3600 * 24)) / 3600);
  if (hours !== Math.floor((average % (3600 * 24)) / 3600)) {
    wasPreviousTimeUnitRounded = true;
  }

  let days = wasPreviousTimeUnitRounded
    ? Math.floor((average * 1000) / (1000 * 60 * 60 * 24))
    : Math.round((average * 1000) / (1000 * 60 * 60 * 24));

  if (minutes % 60 === 0 && minutes > 0) {
    hours += 1;
    minutes = 0;
  }
  if (hours % 24 === 0 && hours > 0) {
    days += 1;
    hours = 0;
  }

  return `${days ? (days > 1 ? days + t('days') : days + t('day')) : ''} ${
    hours ? hours + t('hours_unit_1') : ''
  } ${minutes ? minutes + t('minutes_unit_2') : ''} ${
    !days && !hours && !minutes ? average + t('seconds_unit_1') : ''
  }`.trim();
};
