import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useCallback, useState } from 'react';

import useBoundStore from '../store/store';

export const useRefreshPetData = () => {
  const [dataRefreshing, setDataRefreshing] = useState(false);
  const [onceLoaded, setOnceLoaded] = useState(false);

  const activeHousehold = useBoundStore(state => state.householdStore.activeHousehold);
  const { isAuthenticated } = useBoundStore(s => s.accountStore);
  const { loadHouseholdStatistics, loadPetStatistics } = useBoundStore(s => s.petStore);
  const { loadPet } = useBoundStore(s => s.petStore);
  const { loadDevice } = useBoundStore(s => s.deviceStore);

  const currentPets = usePetsByHousehold();
  const currentDevices = useDevicesByHousehold();

  const refreshStats = useCallback(async () => {
    if (!isAuthenticated || dataRefreshing) {
      return;
    }
    try {
      if (currentDevices.length && currentPets.length) {
        await loadPetStatistics(currentPets, currentDevices, new Date(), true, 7);
      } else {
        await loadHouseholdStatistics(new Date(), true, 7);
      }
    } catch (error) {
      console.error('Error refreshing statistics:', error);
    } finally {
      setOnceLoaded(true);
    }
  }, [
    isAuthenticated,
    dataRefreshing,
    currentDevices,
    currentPets,
    loadHouseholdStatistics,
    loadPetStatistics,
  ]);

  const refreshPetData = useCallback(async () => {
    if (!isAuthenticated || dataRefreshing) {
      return;
    }

    setDataRefreshing(true);

    try {
      await Promise.all([loadPet(true), refreshStats()]);
    } catch (e) {
      console.error('Refresh pet data error:', e);
    } finally {
      setDataRefreshing(false);
      setOnceLoaded(true);
    }
  }, [isAuthenticated, dataRefreshing, setOnceLoaded, activeHousehold?.id, refreshStats]);

  return {
    dataRefreshing,
    refreshPetData,
    refreshStats,
    setOnceLoaded,
    onceLoaded,
  };
};
