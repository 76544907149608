import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { useDetectPushNotification } from '@hooks/useDetectPushNotification';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useRefreshPetData } from '@hooks/useRefreshPetData';
import { useRemoteConfigBooleanValue } from '@hooks/useRemoteConfigBooleanValue';
import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import useBoundStore from '../../../store/store';
import { Pet2Cards } from '../molecules/Pet2Cards';
import { PetGridCards } from '../molecules/PetGridCards';
import { SpCenter } from 'src/components/SpCenter';
import { PetCarousel } from '../Pets/pages/PetCarousel/PetCarousel';

export const PetList = () => {
  const { refreshPetData, refreshStats, onceLoaded, dataRefreshing, setOnceLoaded } =
    useRefreshPetData();
  const activeHousehold = useBoundStore(state => state.householdStore.activeHousehold);
  const {
    loadDevice,
    deviceData,
    updateDevicesIdsRequestsTrack,
    devicesIdsRequestsTrack,
    refreshPetsDevices,
  } = useBoundStore(s => s.deviceStore);
  const { gridView } = useBoundStore(s => s.accountStore);

  const [notEmpty, setNotEmpty] = React.useState([]);
  const isFocused = useIsFocused();
  const pets = usePetsByHousehold();
  const { saveAction } = useDetectPushNotification();
  const focusedStateRef = useRef(false);
  const [focusPreload, setFocusPreload] = useState(false);

  useEffect(() => {
    if (!isFocused && focusedStateRef.current) {
      focusedStateRef.current = false;
      setFocusPreload(true);
    } else if (isFocused && !focusedStateRef.current) {
      focusedStateRef.current = true;
      refreshStats().then(() => {
        setFocusPreload(false);
      });
    }
  }, [isFocused, refreshStats]);

  useEffect(() => {
    setOnceLoaded(false);
    updateDevicesIdsRequestsTrack(
      deviceData
        .filter(item => item.household_id === activeHousehold?.id)
        .reduce((acc, elem) => {
          acc[elem.id] = [1]; // check all devices on init
          return acc;
        }, {}),
    );
    refreshPetData();
  }, [activeHousehold?.id]);

  useEffect(() => {
    const res = [];
    for (const id in devicesIdsRequestsTrack) {
      if (devicesIdsRequestsTrack[id]?.length) res.push(id);
    }
    setNotEmpty(res);
  }, [devicesIdsRequestsTrack]);

  const fetching = async () => {
    await loadDevice(true);
    await refreshPetData();
  };

  useEffect(() => {
    const fetchAndUpdate = async ids => {
      if (!notEmpty.length) {
        await refreshPetData();
        return;
      } else {
        // console.log(notEmpty);
      }
      const result = await refreshPetsDevices(ids);

      if (notEmpty.length !== result.newIdsArray.length || result.needRefresh) {
        await fetching();
      }
      updateDevicesIdsRequestsTrack({
        ...(devicesIdsRequestsTrack ?? {}),
        ...result.result,
      });

      setNotEmpty(result.newIdsArray);
    };

    if (isFocused) {
      fetchAndUpdate(notEmpty);
      const fetchInterval = setInterval(() => {
        fetchAndUpdate(notEmpty);
      }, 10000);

      if (!Object?.values(devicesIdsRequestsTrack ?? {}).some(elem => elem.length)) {
        clearInterval(fetchInterval);
      }

      return () => {
        clearInterval(fetchInterval);
      };
    }
  }, [isFocused, onceLoaded]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFocused) {
      if (
        !Object?.values(devicesIdsRequestsTrack ?? {})
          ?.flat()
          ?.filter(e => e !== 1)?.length
      ) {
        refreshPetData();
      }
      saveAction(refreshPetData, 'pet carousel refresh');

      const id = setInterval(() => {
        refreshPetData();
      }, 1000 * 60);

      return () => {
        clearInterval(id);
      };
    }
    // TODO - i used to have device data here, removed it because it cased circular triggering of this effect
    // as refreshPetData was updating device data
  }, [isFocused, activeHousehold?.id, updateDevicesIdsRequestsTrack, devicesIdsRequestsTrack]);
  const multiPetView = useRemoteConfigBooleanValue(RemoteConfigKeys.multiPetView);

  return multiPetView && gridView && pets.length > 1 ? (
    pets.length > 2 ? (
      <PetGridCards pets={pets} />
    ) : (
      <Pet2Cards pets={pets} />
    )
  ) : (
    <>
      <View style={styles.spacer} />
      <SpCenter style={styles.container}>
        <PetCarousel
          pets={pets}
          loading={!onceLoaded || focusPreload}
          dataRefreshing={dataRefreshing}
        />
      </SpCenter>
      <View style={styles.spacer} />
    </>
  );
};
const styles = StyleSheet.create({
  linearGradient: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  events: {
    width: '100%',
    height: '7%',
  },
  container: {
    width: '100%',
    flex: 1,
  },
  spacer: {
    height: '7%',
  },
});
