import { ConsumptionInsight, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useConsumptionInsightConfig } from './hooks/useConsumptionHabitConfig.hook';
import { SpView } from '../../../../components/SpView';
import { SpText } from '../../../../components/SpText/SpText';
import React, { useMemo, useState } from 'react';
import ChevronRightSVG from '../../../../components/SvgIcons/ChevronRightSVG';
import CircleProgress from './components/ConsumptionCircleProgress';
import noop from '@utils/noop';
import ConsumptionModalBottomSheet from './components/ConsumptionModalBottomSheet';

interface ConsumptionHabitProps {
  insight: ConsumptionInsight;
  isAlert?: boolean;
  withoutButton?: boolean;
  withoutFullDescription?: boolean;
  onViewGraph?: (date: string) => void;
  withDataHeader?: boolean;
}

const InsightNotification = ({
  insight,
  isAlert = false,
  onViewGraph = noop,
  withoutButton = false,
  withDataHeader = false,
  withoutFullDescription = false,
}: ConsumptionHabitProps) => {
  const [opened, setOpened] = useState(false);

  const config = useConsumptionInsightConfig(
    insight.outcome,
    isAlert,
    insight.n_days + 1,
    insight.last_drinking_event_utc,
  );

  console.log('insight', insight);

  const isIconAvailable = useMemo(() => {
    if (isAlert) {
      return true;
    }

    return ![
      ConsumptionInsightOutcome.Unavailable,
      ConsumptionInsightOutcome.WillBeAvailable,
    ].includes(insight.outcome);
  }, [isAlert, insight.outcome]);

  const onOpenInsight = () => {
    setOpened(true);
  };

  if (!insight) {
    return null;
  }

  return (
    <>
      <TouchableOpacity
        activeOpacity={1}
        style={[styles.wrapper, config.wrapper, isIconAvailable && styles.wrapperWithIcon]}
        onPress={onOpenInsight}
      >
        {isIconAvailable && (
          <SpView style={[styles.iconWrapper, config.iconBg]}>{config.icon}</SpView>
        )}
        {insight.outcome === ConsumptionInsightOutcome.WillBeAvailable && !isAlert && (
          <SpView style={styles.circle}>
            <CircleProgress
              value={insight.n_days + 1}
              maxValue={insight.target_days + 1}
              size={48}
              strokeWidth={5}
            />
          </SpView>
        )}
        <SpView style={styles.textWrapper}>
          <SpText
            style={styles.text}
            size="md"
            fontFamily="Rubik_Medium"
          >
            {config.title}
          </SpText>
        </SpView>
        <SpView>
          <ChevronRightSVG
            width={20}
            height={20}
            viewBox="0 0 20 20"
          />
        </SpView>
      </TouchableOpacity>
      <ConsumptionModalBottomSheet
        opened={opened}
        insight={insight}
        withoutFullDescription={withoutFullDescription}
        withoutButton={withoutButton}
        isAlert={isAlert}
        withDataHeader={withDataHeader}
        onViewGraph={onViewGraph}
        onDismiss={() => setOpened(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    paddingVertical: 5,
    backgroundColor: 'rgba(45, 181, 142, 0.2)',
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'rgba(45, 181, 142, 0.2)',
    height: 80,
    paddingHorizontal: 16,
  },
  wrapperWithIcon: {
    paddingLeft: 0,
  },
  iconWrapper: {
    backgroundColor: 'rgba(45, 181, 142, 0.4)',
    width: 56,
    height: 40,
    paddingRight: 4,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    marginRight: 12,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  textWrapper: {
    flex: 1,
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    marginRight: 16,
  },
});

export default InsightNotification;
