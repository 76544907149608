import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import { HouseholdModel } from '@models/Household';
import SpTitle from 'src/components/SpTitle';
import {
  CommonActions,
  NavigationProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import useBoundStore from '../../../../store/store';
import { ConfirmRemoveHouseholdDialog } from './ConfirmRemoveHouseholdDialog';
import { ConfirmRemoveHouseholdDialogContext } from '../contexts/ConfirmRemoveHouseholdDialogContext';
import { useGroupedHouseholds } from '../hooks/useGroupedHouseholds';
import { RemoveAccountParamList } from '../index';
import { Layout } from './Layout';
import { HouseholdSelect } from './HouseholdSelect';
import { PlaceholderText } from './PlaceholderText';
import { WhatWillRemoved } from './WhatWillRemoved';
import { findHouseholdsWithViewersAndOtherCreated } from '../utils/findHouseholdsWithViewersAndOtherCreated';
import { getConfirmRemoveHouseholdMode } from '../utils/getConfirmRemoveHouseholdMode';

export const OwnerStep = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RemoveAccountParamList>>();
  const { user } = useBoundStore(s => s.accountStore);
  const groupedHouseholds = useGroupedHouseholds();
  const [selectedHousehold, setSelectedHousehold] = useState<HouseholdModel>(null);
  const { setDialogConfig } = useContext(ConfirmRemoveHouseholdDialogContext);

  const household = useMemo(() => groupedHouseholds.householdsAsOwner[0], [groupedHouseholds]);
  const otherOwners = useMemo(() => {
    return household.users.filter(item => item.id !== user.id && item.owner && item.write);
  }, [household, user]);

  const selectHouseholdAction = useCallback(() => {
    const state = !!selectedHousehold;

    if (!state) {
      if (household?.created_user_id === user.id) {
        setSelectedHousehold(household);
        return;
      }

      const { viewers, notOriginallyCreated } = findHouseholdsWithViewersAndOtherCreated(
        [household],
        user,
      );

      setDialogConfig({
        households: [household],
        mode: getConfirmRemoveHouseholdMode(viewers, notOriginallyCreated),
        onConfirm: () => {
          navigation.navigate('OwnerStep');
          setSelectedHousehold(household);
        },
      });
      navigation.navigate('ConfirmRemoveHouseholdDialog');
    } else {
      setSelectedHousehold(null);
    }
  }, [household, user, selectedHousehold]);

  const confirmAction = useCallback(() => {
    const continueAction = () => {
      navigation.navigate('ConfirmStep', {
        selectedHouseholds: otherOwners.length && !selectedHousehold ? [] : [household],
      });
    };

    if (otherOwners.length) {
      continueAction();
      return;
    }

    const { viewers, notOriginallyCreated } = findHouseholdsWithViewersAndOtherCreated(
      [household],
      user,
    );

    if (viewers.length || notOriginallyCreated.length) {
      setDialogConfig({
        households: [household],
        mode: getConfirmRemoveHouseholdMode(viewers, notOriginallyCreated),
        onConfirm: () => {
          navigation.dispatch(StackActions.pop(1));
          continueAction();
        },
      });
      navigation.navigate('ConfirmRemoveHouseholdDialog');
    } else {
      continueAction();
    }
  }, [otherOwners, user, selectedHousehold, household]);

  return (
    <Layout
      withBackButton
      nextButtonText={t('continue')}
      onNext={confirmAction}>
      {otherOwners.length ? (
        <SpView marginTop={24}>
          <SpVStack space={12}>
            <SpText
              align="center"
              color={colors.errorRed.color}>
              {t('ad_delete_household_with_other_members')}
            </SpText>
            <SpText
              align="center"
              color={colors.errorRed.color}>
              {t('ad_delete_household_confirm')}
            </SpText>
          </SpVStack>
          <SpView marginTop={40}>
            <HouseholdSelect
              text={household.name}
              active={!!selectedHousehold}
              onActive={selectHouseholdAction}
            />
          </SpView>
        </SpView>
      ) : (
        <SpVStack
          marginTop={24}
          space={12}>
          <SpText
            align="center"
            color={colors.errorRed.color}>
            {t('ad_delete_household')}
          </SpText>
          <PlaceholderText text={household.name} />
        </SpVStack>
      )}

      <SpView marginTop={20}>
        <SpTitle
          align="center"
          text={t('ad_explanation_delete_household_title')}
        />
      </SpView>

      <SpView marginBottom={24}>
        <WhatWillRemoved short={false} />
      </SpView>

      <SpRoundedHeaderButton
        title={t('more_information')}
        backgroundColor={colors.primary.color}
        onPress={() => navigation.navigate('RemoveHouseholdInformationDialog')}
      />
    </Layout>
  );
};
