import { MeStartModel } from '@models/MeStart';
import { UpdatedPhoto } from '@models/Photo';
import { UserModel } from '@models/User';
import Http from './Http';

class MeApi {
  static getUser(forceToken: string = null): Promise<UserModel> {
    return Http.get(`/api/me`, {
      headers: {
        forceToken,
      },
    }).then(response => response.data.data);
  }

  static updateUser(user: UserModel): Promise<UserModel> {
    return Http.put(`/api/me`, user).then(response => {
      return response.data.data;
    });
  }

  static deleteUser(households: number[], password: string): Promise<UserModel> {
    return Http.post(`/api/me/delete`, {
      households,
      password,
    }).then(response => response.data.data);
  }

  static acceptTerms(): Promise<UserModel> {
    return Http.put(`/api/me/accept-terms`).then(response => response.data.data);
  }

  static updatePhoto(form: FormData): Promise<UpdatedPhoto> {
    return Http.post(`/api/photo`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      return response.data;
    });
  }

  static deletePhoto(id: number): Promise<string> {
    return Http.delete(`/api/photo/${id}`).then(response => {
      return response.data;
    });
  }

  static getStartData(): Promise<MeStartModel> {
    return Http.get(`/api/me/start`).then(response => response.data.data);
  }
}

export default MeApi;
