import { BackHandler, Platform, Pressable, StyleSheet, Text, TextProps, View } from 'react-native';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { SpRoundedHeaderButton } from './SpRoundedHeaderButton';
import { FieldsObj, FormikSteps, StepsObjWrap, SubmitValues } from '@models/FormikTypes';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { HouseholdStackParamList } from 'src/pages/Household';
import { testProperties } from '@utils/testProperties';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CustomKeyboardAvoidingView from './CustomKeyboardAvoidingView';
import { SpView } from './SpView';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { SpText } from './SpText/SpText';
import { DeviceType } from '@constants/Device';
import ProgressHeader from './ProgressHeader';
import CustomHeader from './CustomHeader';
import { WebWrapper } from './WebWrapper';

import BackSwipeHandler from './BackSwipeHandler';

interface IStepFormCreatorProps {
  step: number;
  setStep: (arg: number) => void;
  headerTitle?: string;
  steps: StepsObjWrap;
  submitValues?: SubmitValues;
  setSubmitValues?: Dispatch<SetStateAction<SubmitValues>>;
  bottomButton?: JSX.Element;
  nextFlowName?: keyof HouseholdStackParamList;
  disableKeyboardAwoiding?: boolean;
  skipSumbitValues?: boolean;
  leaveButtonAction?: () => void;
  buttonTestProperties?: Record<string, any>;
  scrollViewTestProps?: Record<string, any>;
  textProps?: Omit<TextProps, 'style'>;
  safeAreaView?: boolean;
  deviceType?: DeviceType;
  fromRegister?: boolean;
  /**
   * Whether the back swipe handler is enabled. When false the handler will not respond to gestures.
   */
  enableBackSwipeHandler?: boolean;
  /**
   * Whether the back swipe action is enabled. When false the action will not be executed.
   */
  enableIOSBackSwipeActions?: boolean;
}

const StepFormCreator = ({
  step,
  setStep,
  headerTitle,
  steps,
  submitValues,
  setSubmitValues,
  bottomButton,
  nextFlowName,
  disableKeyboardAwoiding = false,
  skipSumbitValues = false,
  leaveButtonAction,
  buttonTestProperties,
  scrollViewTestProps = {},
  safeAreaView = true,
  deviceType,
  fromRegister = false,
  enableBackSwipeHandler = false,
  enableIOSBackSwipeActions = false,
}: IStepFormCreatorProps) => {
  const [error, setError] = useState('');

  const [formikSteps, setFormikSteps] = useState<FormikSteps>();

  const [submitErrors, setSubmitErrors] = useState<FieldsObj>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [skippedSteps, setSkippedSteps] = useState([]);

  const [isShowHeaderShadow, setIsShowHeaderShadow] = useState(false);

  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const insets = useSafeAreaInsets();
  const LoadingComponent = steps[step]?.prevButtonElement;

  const { t } = useTranslation();

  useEffect(() => {
    const obj: FormikSteps = {};
    const obj2: FieldsObj = {};
    /* eslint-disable no-restricted-syntax */
    for (const [key, value] of Object.entries(steps)) {
      if (value?.formik?.names) {
        const arr = value?.formik?.names;
        obj[Number(key)] = arr;
        arr.forEach((a: string) => {
          obj2[a] = null;
        });
      }
    }
    setFormikSteps(obj);
    setSubmitErrors(obj2);
  }, [step, steps]);

  const plusStep = useCallback(() => {
    // Starting from the next step
    let nextStep = step + 1;

    // Loop through the steps until a step that should not be skipped is found
    while (steps[nextStep]?.skipStep) {
      if (!skippedSteps.includes(nextStep)) {
        setSkippedSteps([...skippedSteps, nextStep]);
      }
      nextStep += 1;
    }
    setStep(nextStep);
  }, [step, steps, skippedSteps]);

  const initValuesFunc = useCallback(() => {
    const arr = formikSteps?.[step];
    return arr?.reduce((acc: any, item: string) => {
      acc[item] = submitValues?.[item] || '';
      return acc;
    }, {});
  }, [step, formikSteps, submitValues]);

  const formik = useFormik({
    validationSchema: steps[step]?.formik?.schema || null,
    initialValues: initValuesFunc() || null,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const onContinuePress = useCallback(async () => {
    if (step === Object.keys(steps).length && nextFlowName) {
      navigation.navigate(nextFlowName);
      return;
    }
    if (steps[step]?.forcePressHandler) {
      steps[step]?.forcePressHandler();
      return;
    }
    if (formikSteps[step] && formikSteps[step].length > 0) {
      const { validateForm, errors, values, touched } = formik;
      await validateForm();
      const convertErrorFields = formikSteps[step]
        ?.map(item => ({
          [item]: true,
        }))
        .reduce((obj, item) => {
          return Object.assign(obj, item);
        }, {});

      setSubmitErrors({ ...submitErrors, ...convertErrorFields });
      const valid = steps[step]?.skipValidation
        ? [true]
        : formikSteps[step].reduce((acc, item) => {
            const subValues = skipSumbitValues ? true : submitValues?.[item];
            const isValid = Boolean((touched[item] || subValues) && !errors[item]);
            acc.push(isValid);
            return acc;
          }, []);

      if (!valid.includes(false)) {
        const convertValueFields = formikSteps[step]
          .map(
            item => ({
              [item]: values[item],
            }),
            [],
          )
          .reduce((obj, item) => {
            return Object.assign(obj, item);
          }, {});
        if (!skipSumbitValues) {
          setSubmitValues({
            ...submitValues,
            ...convertValueFields,
          });
        }
        if (steps[step]?.formikValidInterceptor) {
          setIsButtonLoading(true);
          const formikInterceptorResult = await steps[step]?.formikValidInterceptor({
            formik,
            setError,
          });
          if (formikInterceptorResult) {
            plusStep();
            setIsButtonLoading(false);
            return;
          }
          setIsButtonLoading(false);
          return;
        }
        plusStep();
      }
    } else if (steps[step]?.interceptor) {
      setIsButtonLoading(true);
      try {
        const interceptorResult = await steps[step]?.interceptor();
        if (interceptorResult) {
          plusStep();
        }
      } catch {
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      plusStep();
    }
  }, [step, formik, submitErrors, submitValues]);

  const onBackPress = useCallback(() => {
    const findStepToGoBack = (currentStep: number): number => {
      const wasSkipped = steps[currentStep - 1]?.skipStep;
      if (currentStep === 1) {
        return 1;
      } else if (wasSkipped) {
        return findStepToGoBack(currentStep - 1);
      } else {
        return currentStep - 1;
      }
    };

    const newStep = findStepToGoBack(step);
    if (newStep !== step) {
      setStep(newStep);
    } else {
      navigation.goBack();
    }
  }, [step, setStep, navigation, steps]);

  const handleAndroidBackPress = useCallback(() => {
    const androidBackPressHandler = steps[step]?.handleAndroidBackPress;
    const withLeaveButton = steps[step]?.customHeaderProps?.withLeaveButton;
    const leaveButtonAction = steps[step]?.customHeaderProps?.leaveButtonAction;
    const goBackFunc = steps[step]?.customHeaderProps?.goBackFunc;
    const backBottomButton = steps[step]?.backBottomButton;

    if (androidBackPressHandler) {
      const result = androidBackPressHandler();
      if (result === 'goBack') {
        onBackPress();
        return true;
      }
      return result;
    }
    if (backBottomButton) {
      onBackPress();
      return true;
    }
    if (goBackFunc) {
      goBackFunc();
      return true;
    }
    if (withLeaveButton) {
      if (leaveButtonAction) {
        leaveButtonAction();
        return true;
      } else {
        return navigation.canGoBack()
          ? (() => {
              navigation.goBack();
              return true;
            })()
          : false;
      }
    } else {
      onBackPress();
      return true;
    }
  }, [step, setStep, navigation, steps, onBackPress]);

  const backSwipeAction = useCallback(() => {
    if (enableIOSBackSwipeActions) handleAndroidBackPress();
  }, [enableIOSBackSwipeActions, handleAndroidBackPress]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleAndroidBackPress);

    return () => {
      backHandler.remove();
    };
  }, [handleAndroidBackPress]);

  const customHeader = useMemo(() => {
    if (steps[step]?.hideCustomHeader) {
      return null;
    }
    if (steps[step]?.invisibleHeader) {
      return <View style={{ height: 50 }} accessible={false}/>;
    }
    return (
      <CustomHeader
        withTitle={false}
        withArrowBack
        leaveButtonAction={leaveButtonAction}
        onBackPress={onBackPress}
        {...steps[step]?.customHeaderProps}
      />
    );
  }, [step, steps, onBackPress, leaveButtonAction]);

  const progressHeader = useMemo(() => {
    return (
      !steps[step]?.hideProgressHeader && (
        <ProgressHeader
          title={steps[step]?.customTitleProgressHeader || headerTitle || ''}
          step={step - skippedSteps.length}
          stepsCount={Object.keys(steps).length - skippedSteps.length}
          style={steps[step]?.customStyleProgressHeader}
          isShowShadow={isShowHeaderShadow}
        />
      )
    );
  }, [headerTitle, step, steps, skippedSteps.length, isShowHeaderShadow]);

  useEffect(() => {
    const stepEffect = steps[step]?.stepEffect;
    if (stepEffect) stepEffect({ formik });
  }, [step]);

  useEffect(() => {
    const effect = steps[step]?.effect;
    if (effect) effect({ formik });
  }, [step, formik]);

  useEffect(() => {
    skippedSteps.map(numOfStep => {
      if (step < numOfStep) {
        const index = skippedSteps.indexOf(numOfStep);
        if (index > -1) {
          skippedSteps.splice(index, 1);
        }
      }
      return null;
    });
  }, [step]);
  const extraScrollHeightAndroid = steps[step];

  const handleScroll = e => {
    if (e?.nativeEvent?.contentOffset.y > 0) {
      setIsShowHeaderShadow(true);
    } else {
      setIsShowHeaderShadow(false);
    }
  };

  const formButtons = (
    <>
      {steps[step]?.prevButtonElement ? <LoadingComponent /> : <></>}
      {steps[step]?.textAboveButton && steps[step]?.textAboveButton?.length && (
        <SpText
          size="xl"
          style={styles.textAboveButton}
        >
          {steps[step]?.textAboveButton}
        </SpText>
      )}
      {steps[step]?.hideButton && steps[step]?.loadingElement && (
        <View
          accessible={false}
          style={[
            fromRegister ? styles.registerButtonWrap : styles.buttonWrap,
            { flexDirection: 'column' },
          ]}
        >
          {steps[step]?.loadingElement}
        </View>
      )}
      {(!steps[step]?.hideButton || !steps[step]?.hideBackBottomButton) && (
        <View
          accessible={false}
          style={[
            step === 3 && deviceType ? styles.buttonRelativeWrap : styles.buttonWrap,
            steps[step]?.customButtonContainer || {},
            steps[step]?.backBottomButton ? styles.withBackButtonWrapper : {},
            steps[step]?.backBottomButton && steps[step]?.hideButton
              ? styles.withOnlyBackButtonWrapper
              : {},
            steps[step]?.additionalButton
              ? {
                  bottom: insets.bottom + 60,
                  marginBottom: 0,
                }
              : {},
            steps[step]?.loadingElement ? { flexDirection: 'column' } : {},
          ]}
        >
          {steps[step]?.backBottomButton && (
            <Pressable
              accessible={false}
              onPress={onBackPress}
              style={[styles.backBottomButton, steps[step]?.customStyleBackButton || {}]}
            >
              <FontAwesomeIcon
                color={colors.greyText.color}
                size={28}
                icon={faChevronLeft}
              />
            </Pressable>
          )}
          {!steps[step]?.hideButton && (
            <SpRoundedHeaderButton
              {...testProperties('Continue', 'stepFormButton')}
              disabled={steps[step]?.buttonDisabled}
              title={t(steps[step]?.buttonText) || t('continue')}
              onPress={onContinuePress}
              backgroundColor={colors.primary.color}
              textProps={{ numberOfLines: 2, adjustsFontSizeToFit: true }}
              h={56}
              w={
                fromRegister && [1, 5, 6].includes(step)
                  ? '90%'
                  : fromRegister
                    ? '100%'
                    : steps[step]?.backBottomButton
                      ? '100%'
                      : '90%'
              }
              isLoading={isButtonLoading || steps[step]?.isButtonLoading}
              stylesForContainer={[
                { flex: 1, marginTop: 10 },
                steps[step]?.backBottomButton
                  ? {
                      alignItems: 'center',
                      bottom: 0,
                      width: '100%',
                    }
                  : {
                      alignItems: 'center',
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                steps[step]?.loadingElement ? { width: '100%' } : {},
                steps[step]?.customStyleButton,
              ]}
            />
          )}
        </View>
      )}
      {steps[step]?.additionalButton && (
        <Pressable
          onPress={steps[step].additionalButtonAction}
          style={[styles.additionalButton, { bottom: insets.bottom }]}
          accessible={false}
        >
          <Text
            style={styles.additionalButtonText}
            numberOfLines={2}
            adjustsFontSizeToFit={true}
          >
            {steps[step].additionalButtonName}
          </Text>
        </Pressable>
      )}
      {steps[step]?.showBottomBackground && (
        <View style={[styles.bottomBackground, { height: insets.bottom + 130 }]} accessible={false} />
      )}
      {bottomButton}
    </>
  );

  const form = useMemo(() => {
    const Component = steps[step]?.ui;
    const wrapper = steps[step]?.wrap;
    const type = typeof Component;
    if (type === 'object') {
      return Component;
    }
    if (type === 'function') {
      const comp = (
        <Component
          formik={formik}
          submitErrors={submitErrors}
          error={t(error)}
          setError={setError}
          isStep
          onContinuePress={onContinuePress}
          {...steps[step]?.props}
          accessible={false}
        >
          {fromRegister && formButtons}
        </Component>
      );
      return wrapper ? wrapper(comp) : comp;
    }
    return null;
  }, [steps, step, formik, submitErrors]);

  const formContainer = (
    <View
      accessible={false}
      style={[styles.formWrapper, { paddingBottom: insets.bottom }]}
    >
      {form}
    </View>
  );

  return (
    <BackSwipeHandler
      onBackSwipeAction={backSwipeAction}
      enabled={enableBackSwipeHandler}
    >
      <SafeAreaView
        accessible={false}
        style={styles.container}
        edges={[safeAreaView ? 'top' : 'bottom']}
      >
        <WebWrapper>
          {customHeader}
          {progressHeader}
          {disableKeyboardAwoiding ? (
            <ScrollView accessible={false}>{formContainer}</ScrollView>
          ) : (
            <CustomKeyboardAvoidingView
              onScroll={steps[step]?.showHeaderShadow && handleScroll}
              scrollEventThrottle={400}
              androidAdjustType="custom"
              extraScrollHeight={Platform.OS === 'ios' ? undefined : insets.bottom}
              keyboardShouldPersistTaps="handled"
              step={step}
              {...steps[step]?.keyboardAwoidingProps}
              accessible={false}
            >
              <View
                accessible={false}
                style={[styles.formWrapper, { paddingBottom: 110 }]}
              >
                {form}
              </View>
              {extraScrollHeightAndroid ? <SpView style={styles.spacer} /> : null}
            </CustomKeyboardAvoidingView>
          )}
          {(!fromRegister || (fromRegister && [1, 5, 6].includes(step))) && formButtons}
        </WebWrapper>
      </SafeAreaView>
    </BackSwipeHandler>
  );
};

export default StepFormCreator;

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
  formWrapper: {
    paddingHorizontal: 19,
    flex: 1,
    height: '100%',
    flexGrow: 1,
  },
  registerButtonWrap: {
    width: '100%',
    bottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 34,
    zIndex: 2,
  },
  buttonWrap: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 34,
    zIndex: 2,
  },
  buttonRelativeWrap: {
    width: '100%',
    bottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 34,
  },
  withOnlyBackButtonWrapper: {
    position: 'absolute',
    left: '38%',
    bottom: '2%',
    width: '100%',
  },
  backBottomButton: {
    width: 56,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: '#919ba0',
    marginRight: 24,
    marginTop: 16,
    backgroundColor: 'white',
  },
  spacer: {
    width: '100%',
    height: 20,
  },
  max: {
    flex: 1,
  },
  additionalButton: {
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 56,
    zIndex: 2,
  },
  additionalButtonText: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 16,
    color: colors.greyText.color,
  },
  withBackButtonWrapper: {
    flex: 1,
    paddingHorizontal: 19,
  },
  textAboveButton: {
    width: '100%',
    paddingHorizontal: 19,
    color: colors.greyText.color,
    textAlign: 'center',
    position: 'absolute',
    bottom: 127,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bottomBackground: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
});
