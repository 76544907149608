import { DayFullMonthFormat, WithoutTimeDateFormat } from '@constants/DateFormat';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import SpToggleButton from 'src/components/SpToggleButton';
import { SpView } from 'src/components/SpView';

import { SpText } from '../../../components/SpText/SpText';
import { TimeService } from '../../../services/TimeService';
import { ReportRangeType, ReportRangeTypeName } from '../constants/ReportRangeType';
import { useDateNavigationNextDays } from '../hooks/useDateNavigationNextDays';

interface DateNavigationProps {
  currentDate: Date;
  setDate?: (arg: number) => void;
  setViewedDays?: (arg: number) => void;
  viewedDays: number;
  withButtons?: boolean;
  title?: string;
  range?: ReportRangeType;
}

const DateNavigation = ({
  currentDate,
  setDate,
  setViewedDays,
  viewedDays,
  withButtons = false,
  title,
  range,
}: DateNavigationProps) => {
  const { t } = useTranslation();

  const dateStr = TimeService.parseDate(currentDate).toFormat(DayFullMonthFormat);
  const nextDays = useDateNavigationNextDays({
    range,
    currentDate,
    viewedDays,
  });
  const arrowsHitSlop = {
    top: 15,
    left: 15,
    bottom: 15,
    right: 15,
  };
  const rightArrowDisabled =
    TimeService.parseDate(currentDate).toFormat(WithoutTimeDateFormat) ===
      TimeService.toLocal().toFormat(WithoutTimeDateFormat) ||
    +currentDate > +TimeService.toLocal().toMillis();
  const subtractedValue = TimeService.parseDate(currentDate)
    .minus({ days: viewedDays || 1 })
    .toFormat(DayFullMonthFormat);

  const arrowLeftHandler = useCallback(() => {
    setDate(
      TimeService.parseDate(currentDate)
        .minus({ days: viewedDays + 1 })
        .toMillis(),
    );
  }, [currentDate, viewedDays]);

  const arrowRightHandler = useCallback(() => {
    if ([ReportRangeType.SixMonths, ReportRangeType.OneYear].includes(range)) {
      const calculatedNewDate = TimeService.parseDate(currentDate).plus({ days: nextDays }).toMillis();
      const today = TimeService.toLocal().toMillis();
  
      setDate(calculatedNewDate > today ? today : calculatedNewDate);
    } else {
      setDate(
        TimeService.parseDate(currentDate)
          .plus({ days: nextDays + (viewedDays ? 1 : 0) })
          .toMillis(),
      );
    }
  }, [currentDate, nextDays]);

  // eslint-disable-next-line consistent-return
  const dayCountSwitcher = useMemo(() => {
    if (withButtons) {
      return (
        <SpView
          flexDirection="row"
          justifyContent="center"
          marginBottom={16}
          marginTop={28}>
          <SpToggleButton
            onPress={() => {
              // setDate(TimeService.endOfDay().toMillis());
              setViewedDays(7);
            }}
            text={t(ReportRangeTypeName[7], { value: 7 })}
            width={64}
            isActive={viewedDays === 6}
          />
          <SpToggleButton
            onPress={() => {
              // setDate(TimeService.endOfDay().toMillis());
              setViewedDays(28);
            }}
            text={t(ReportRangeTypeName[28], { value: 28 })}
            width={64}
            isActive={viewedDays === 27}
            stylesForContainer={{ marginLeft: 17 }}
          />
        </SpView>
      );
    }
    return null;
  }, [withButtons, viewedDays]);

  const currentDateRange = useMemo(() => {
    if (viewedDays === 0) {
      return (
        <SpText
          color={colors.greyText.color}
          size="sm">
          {dateStr}
        </SpText>
      );
    }

    return (
      <SpText
        color={colors.greyText.color}
        size="sm">
        {subtractedValue} - {dateStr}
      </SpText>
    );
  }, [subtractedValue, dateStr, viewedDays]);

  return (
    <>
      {dayCountSwitcher}
      <SpView
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingTop={8}
        paddingBottom={12}
        paddingHorizontal={24}>
        <TouchableOpacity
          hitSlop={arrowsHitSlop}
          onPress={arrowLeftHandler}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            size={20}
          />
        </TouchableOpacity>
        <SpView alignItems="center">
          {title && (
            <SpText
              fontFamily="Rubik_Medium"
              color={colors.greyText.color}>
              {rightArrowDisabled && title}
            </SpText>
          )}
          {currentDateRange}
        </SpView>
        <TouchableOpacity
          onPress={arrowRightHandler}
          hitSlop={arrowsHitSlop}
          disabled={rightArrowDisabled}>
          <FontAwesomeIcon
            color={rightArrowDisabled ? colors.greyOpacity.color : colors.greyText.color}
            icon={faChevronRight}
            size={20}
          />
        </TouchableOpacity>
      </SpView>
    </>
  );
};

export default DateNavigation;
