import { DeviceType, PetBowlFoodType, SubstanceType } from '@constants/Device';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { DeviceCerberusControlModel, DeviceModel } from '@models/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { navigateToProducts } from '@utils/navigationShortcuts';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import EssenceCreated from 'src/components/EssenceCreated';
import { SpView } from 'src/components/SpView';
import { FoodTypes } from 'src/pages/Dashboard/Products/Device/components/DeviceBowlSetup/FoodTypes';
import { SubstanceTypes } from 'src/pages/Dashboard/Products/Device/components/DeviceBowlSetup/SubstanceTypes';
import { navigationRef } from 'src/pages/RootNavigation';
import { AnalyticsService } from 'src/services/AnalyticsService';
import useBoundStore from 'src/store/store';

import SpTitle from '../../../../../components/SpTitle';
import StepFormCreator from '../../../../../components/StepFormCreator';
import { openModal as openModalBox } from '../../../../../services/ModalBox';
import useValidation from '../../../../validation';
import { ConfigureCDBCarouselFood } from '../../../DogBowl/ConfigureCDBCarouselFood';
import { ConfigureCDBCarouselWater } from '../../../DogBowl/ConfigureCDBCarouselWater';
import DogBowlReady from '../../../DogBowl/DogBowlReady';
import { FirstConfigureProduct } from '../../../DogBowl/FirstConfigureProduct';
import HowToFactoryResetYourCDB from '../../../DogBowl/HowToFactoryResetYourCDB';
import { InsertBatteries } from '../../../DogBowl/InsertBatteries';
import InstructionStep1 from '../../../DogBowl/InstructionStep1';
import InstructionStep2 from '../../../DogBowl/InstructionStep2';
import { InstructionStep3 } from '../../../DogBowl/InstructionStep3';
import NameFormWrap from '../../../DogBowl/NameFormWrap';
import Paired from '../../../DogBowl/Paired';
import PairingFailed from '../../../DogBowl/PairingFailed';
import PairingToHouseholdStep from '../../../DogBowl/PairingToHouseholdStep';
import ThereSeemsToBeAProblem from '../../../DogBowl/ThereSeemsToBeAProblem';
import ThereSeemsToBeAProblemContactCS from '../../../DogBowl/ThereSeemsToBeAProblemContactCS';
import LocalDevicePermission from '../../../DogBowl/TriggerLocalDevicePopup';
import WeHaveNotManageToConnectToCDB from '../../../DogBowl/WeHaveNotManageToConnectToCDB';
import WiFiConnectToDevice from '../../../DogBowl/WiFiConnectToDevice';
import WiFiPasswordInput from '../../../DogBowl/WiFiPasswordInput';
import WiFiSSIDSelect from '../../../DogBowl/WiFiSSIDSelect';
import { CompleteSetupProduct } from '../../../Generic/CompleteSetupProduct';
import NameForm from '../../../Generic/NameForm';
import { FlowStackParamList } from '../../../index';
import { PairingInternetFailed } from '../../../DogBowl/PairingInternetFailed';
import { PairingServerFailed } from '../../../DogBowl/PairingServerFailed';
import PairingMqttFailed from '../../../DogBowl/PairingMqttFailed';
import PairingWIFIFailed from '../../../DogBowl/PairingWiFiFailed';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddDogBowl'>;

export enum stepsEnums {
  InstructionStep1 = 1,
  InsertBatteries,
  LocalDevicePermission,
  InstructionStep2,
  InstructionStep3,
  WiFiConnectToDevice,
  WiFiSSIDSelect,
  WiFiPasswordInput,
  PairingToHouseholdStep,
  Paired,
  NameForm,
  CompleteSetupProduct,
  SubstanceTypes,
  FoodTypes,
  FirstConfigureProduct,
  ConfigureCDBCarousel,
  DogBowlReady,
  ThereSeemsToBeAProblem,
  ThereSeemsToBeAProblemContactCS,
  WeHaveNotManageToConnectToCDB,
  HowToFactoryResetYourCDB,
  PairingFailed,
  PairingWIFIFailed,
  PairingInternetFailed,
  PairingServerFailed,
  PairingMqttFailed,
  SetupComplete,
}

const AddDogBowl = ({ route }: Props) => {
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const { commonNameSchema } = useValidation();
  const { t } = useTranslation();
  const { loadDevice, updateNameRequest, getDeviceById, updateCerberusControl, deviceData } =
    useBoundStore(s => s.deviceStore);

  const {
    setSettingUpWiFiDevice,
    startSearchForProduct,
    connectedToWiFiDevice,
    loading,
    error,
    stopSearch,
    setTimeoutForWiFiProduct,
    startScanDevice,
    startCheckDeviceStatus,
    deviceSSIDS,
    targetNetworkID,
    targetNetworkPassword,
    setTargetNetworkPassword,
    WiFiDeviceConnectedToServer,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToInternet,
    connectDeviceToWifi,
    success,
    pairedDeviceDeviceID,
    reset,
    resetStatus,
    closeConnection,
    WiFiDeviceConnectedToMqtt,
    WiFiDeviceFirmwareUpdate,
    pairingToHousehold,
  } = useBoundStore(s => s.WiFiDeviceStore);

  // const pairedDeviceDeviceID = 1915;
  const pets = usePetsByHousehold();
  const navigation = useNavigation<NavigationProp<FlowStackParamList>>();

  const [submitValues, setSubmitValues] = useState<any>();
  const [step, setStep] = useState<stepsEnums>(route.params?.step || 1);
  const [substanceType, setSubstanceType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceModel | undefined>(null);
  const [lastBatteriesTemplate, setLastBatteriesTemplate] = useState(false);
  const [lastCDBConnectTemplate, setLastCDBConnectTemplate] = useState(false);
  const [lastCDBCarouselFoodTemplate, setLastCDBCarouselFoodTemplate] = useState(false);
  const [lastCDBCarouselWaterTemplate, setLastCDBCarouselWaterTemplate] = useState(false);
  const [numberTemplates, setNumberTemplates] = useState(0);
  const [numberCDBConnectTemplates, setNumberCDBConnectTemplates] = useState(0);
  const [numberWaterTemplates, setNumberWaterTemplates] = useState(0);
  const [numberFoodTemplates, setNumberFoodTemplates] = useState(0);
  const [searchForDeviceAttempts, setSearchForDeviceAttempts] = useState(0);
  const [returnToStep, setReturnToStep] = useState(0);
  const [foodType, setFoodType] = useState(PetBowlFoodType.wet);
  const [bowlFlashingOrange, setBowlFlashingOrange] = useState(null);
  const [retryConnectToDevice, setRetryConnectToDevice] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [wifiConnectToDeviceTimerEnds, setWifiConnectToDeviceTimerEnds] = useState(false);

  useEffect(() => {
    if (step !== stepsEnums.WiFiConnectToDevice) return;

    const timer = setTimeout(() => {
      setWifiConnectToDeviceTimerEnds(true);
    }, 3000);

    return () => {
      clearTimeout(timer);
      setWifiConnectToDeviceTimerEnds(false);
    };
  }, [step]);

  const changeCerberusFoodTypeAction = useCallback(
    async (type: PetBowlFoodType) => {
      const control = { food_type: type } as DeviceCerberusControlModel;
      setFoodType(control.food_type);
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      setSelectedDevice(deviceObj);
      await updateCerberusControl(selectedDevice.id, control);
    },
    [setFoodType, foodType, selectedDevice, pairedDeviceDeviceID],
  );
  const changeSubstanceTypeAction = useCallback(
    async (type: SubstanceType) => {
      const control = {
        substance_type: type,
      } as DeviceCerberusControlModel;
      setSubstanceType(type);
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      setSelectedDevice(deviceObj);
      await updateCerberusControl(deviceObj.id, control);
    },
    [setSubstanceType, substanceType, selectedDevice, pairedDeviceDeviceID],
  );

  useEffect(() => {
    AnalyticsService.logEvent(`AddDogBowl - reset`);
    reset();
  }, []);

  useEffect(() => {
    AnalyticsService.logEvent(`AddDogBowl - step - ${stepsEnums[step]}`, {
      step,
      stepName: stepsEnums[step],
    });
  }, [step]);

  useEffect(() => {
    setSettingUpWiFiDevice(true);
    return () => setSettingUpWiFiDevice(false);
  }, [setSettingUpWiFiDevice]);

  useEffect(() => {
    if (route.params?.step) {
      setStep(route.params?.step);
      AnalyticsService.logEvent('AddDogBowl - useEffect - route.params?.step', {
        step: route.params?.step,
      });
    }
  }, [route.params?.step]);

  useEffect(() => {
    return () => {
      stopSearch(true);
      reset();
      closeConnection();
    };
  }, [closeConnection, reset, stopSearch]);

  useEffect(() => {
    if (pairedDeviceDeviceID) {
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      if (deviceObj) {
        setSelectedDevice(deviceObj);
        AnalyticsService.logEvent('AddDogBowl - useEffect - pairedDeviceDeviceID');
      }
    }
  }, [pairedDeviceDeviceID, getDeviceById, setSelectedDevice, selectedDevice, deviceData]);

  // effect to start looking for devices when ever the step changes to step 5 so long as the number of attempts is less than 3

  useEffect(() => {
    if (step === stepsEnums.WiFiConnectToDevice) {
      if (connectedToWiFiDevice) {
        AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiConnectToDevice');

        return;
      }
      // increment search attempts and start searching
      if (!loading && searchForDeviceAttempts < 3) {
        if (error) {
          setReturnToStep(stepsEnums.InstructionStep3);
          setStep(stepsEnums.ThereSeemsToBeAProblem);
          stopSearch();
          AnalyticsService.logEvent(
            'AddDogBowl - useEffect - searchForDeviceAttempts - ThereSeemsToBeAProblemContactCS',
          );

          return;
        }
        setSearchForDeviceAttempts(searchForDeviceAttempts + 1);
        setTimeoutForWiFiProduct(14000);
        startSearchForProduct('CDB');
        setRetryConnectToDevice(false);
        AnalyticsService.logEvent('AddDogBowl - useEffect - searchForDeviceAttempts - searchAgain');
      }
      if (!loading && searchForDeviceAttempts >= 3 && error) {
        setStep(stepsEnums.ThereSeemsToBeAProblemContactCS);
        AnalyticsService.logEvent(
          'AddDogBowl - useEffect - searchForDeviceAttempts >=3 - ThereSeemsToBeAProblem',
        );

        stopSearch();
      }
    }
  }, [step, loading, searchForDeviceAttempts, connectedToWiFiDevice, retryConnectToDevice]);

  useLayoutEffect(() => {
    /*if (step === stepsEnums.SubstanceTypes) {
      changeSubstanceTypeAction(SubstanceType.water);
      AnalyticsService.logEvent('AddDogBowl - useEffect - SubstanceTypes');
    }*/
    if (step === stepsEnums.FoodTypes) {
      changeCerberusFoodTypeAction(PetBowlFoodType.dry);
      AnalyticsService.logEvent('AddDogBowl - useEffect - FoodTypes');
    }
  }, [step]);

  useEffect(() => {
    if (step === stepsEnums.WiFiSSIDSelect) {
      startScanDevice();
      AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiSSIDSelect');
    }
    if (step === stepsEnums.NameForm) {
      loadDevice(true);
    }
  }, [step]);

  useEffect(() => {
    if (step === stepsEnums.WiFiPasswordInput && !loading && !error && success) {
      AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiPasswordInput - success');
      setTimeout(() => {
        setReturnToStep(stepsEnums.WiFiPasswordInput);
        startCheckDeviceStatus();
        setStep(stepsEnums.PairingToHouseholdStep);
      }, 300);
    }
  }, [step, setStep, success, error, loading]);

  useEffect(() => {
    if (step === stepsEnums.PairingToHouseholdStep && pairingToHousehold) return;
    // Failed to connect Bowl to Wi-Fi!
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      !WiFiDeviceConnectedToNetwork
    ) {
      if (error === 'ECONNABORTED') {
        AnalyticsService.logEvent(
          'AddDogBowl - useEffect - PairingToHouseholdStep - error - ECONNABORTED',
        );
        setReturnToStep(stepsEnums.WiFiConnectToDevice);
        setStep(stepsEnums.PairingWIFIFailed);
      } else {
        AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - error');
        setStep(stepsEnums.WiFiPasswordInput);
      }
      resetStatus();
      return;
    }

    // Failed to connect Bowl to Internet!
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      !WiFiDeviceConnectedToInternet
    ) {
      setReturnToStep(stepsEnums.WiFiConnectToDevice);
      setStep(stepsEnums.PairingInternetFailed);
      resetStatus();
      AnalyticsService.logEvent(
        'AddDogBowl - useEffect - PairingToHouseholdStep - error - !WiFiDeviceConnectedToNetwork',
      );
      return;
    }

    // Failed to connect Bowl to Server!
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      !WiFiDeviceConnectedToServer
    ) {
      setReturnToStep(stepsEnums.WiFiConnectToDevice);
      setStep(stepsEnums.PairingServerFailed);
      resetStatus();
      AnalyticsService.logEvent(
        'AddDogBowl - useEffect - PairingToHouseholdStep - error - !WiFiDeviceConnectedToNetwork',
      );
      return;
    }

    // Failed to connect Bowl to Communicate to Server!
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      !WiFiDeviceConnectedToMqtt
    ) {
      setReturnToStep(stepsEnums.WiFiConnectToDevice);
      setStep(stepsEnums.PairingMqttFailed);
      resetStatus();
      AnalyticsService.logEvent(
        'AddDogBowl - useEffect - PairingToHouseholdStep - error - !WiFiDeviceConnectedToNetwork',
      );
      return;
    }

    // We stopped loading, there was an error after we connected to wifi so its probably a problem with the server
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      WiFiDeviceConnectedToNetwork &&
      !WiFiDeviceConnectedToServer
    ) {
      console.log('error connecting to server, will go to step 20');
      setStep(stepsEnums.PairingFailed);
      resetStatus();
      AnalyticsService.logEvent(
        'AddDogBowl - useEffect - PairingToHouseholdStep - error - WiFiDeviceConnectedToNetwork',
      );
      // return;
    }
  }, [
    step,
    pairedDeviceDeviceID,
    error,
    loading,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToInternet,
    WiFiDeviceConnectedToServer,
    WiFiDeviceFirmwareUpdate,
    getDeviceById,
  ]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        AnalyticsService.logEvent('AddDogBowl - onSubmit - updateNameRequest');
        await updateNameRequest(pairedDeviceDeviceID, {
          name: submitValues.name,
        });
        await loadDevice(true);
        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, pairedDeviceDeviceID],
  );

  const onOpenGetHelpModal = useCallback(() => {
    AnalyticsService.logEvent('AddDogBowl - onOpenGetHelpModal', { step });
    switch (step) {
      case stepsEnums.InstructionStep1:
      case stepsEnums.InsertBatteries:
        navigation.navigate('CDBHelpInsertBatteries');
        break;
      case stepsEnums.LocalDevicePermission:
      case stepsEnums.InstructionStep2:
        navigation.navigate('CDBHelpPairingMode');
        break;
      case stepsEnums.InstructionStep3:
        navigation.navigate('CDBHelpWifiNetwork');
        break;
      case stepsEnums.PairingWIFIFailed:
      case stepsEnums.PairingInternetFailed:
      case stepsEnums.PairingServerFailed:
      case stepsEnums.PairingMqttFailed:
        navigation.navigate('CDBHelpFailedOnboardingPairingMode');
        break;
      case stepsEnums.WiFiSSIDSelect:
        navigation.navigate('HavingIssuesFindingWiFi');
        break;
      case stepsEnums.PairingFailed:
        navigation.navigate('CDBHelpFailedPairing');
        break;
      case stepsEnums.ThereSeemsToBeAProblemContactCS:
        Linking.openURL('https://www.surepetcare.com/support');
        break;
      default:
        Linking.openURL('https://www.surepetcare.com/support');
    }
    return false;
  }, [openModalBox, step]);

  const headerProps = {
    withLeaveButton: true,
    withRightButton: true,
    rightButtonText: t('get_help'),
    rightButtonAction: () => {
      onOpenGetHelpModal();
    },
  };

  const enableInsertBatteriesContinue = (indexTemplate: number) => {
    setLastBatteriesTemplate(indexTemplate === numberTemplates - 1);
  };

  const enableCDBConnectContinue = (indexTemplate: number) => {
    setLastCDBConnectTemplate(indexTemplate === numberCDBConnectTemplates - 1);
  };

  const enableConfigureCDBCarouselWaterContinue = (indexTemplate: number) => {
    setLastCDBCarouselWaterTemplate(indexTemplate === numberWaterTemplates - 1);
  };

  const enableConfigureCDBCarouselFoodContinue = (indexTemplate: number) => {
    setLastCDBCarouselFoodTemplate(indexTemplate === numberFoodTemplates - 1);
  };

  const PlacementInfoWrapper = useCallback((children: JSX.Element) => {
    return <SpView flex={1}>{children}</SpView>;
  }, []);

  const stopSearchLeaveAction = useCallback(() => {
    stopSearch(true);
    navigation.goBack();
  }, [navigation, stopSearch]);

  const FoodTypesUI = useMemo(() => {
    return (
      <SpView style={styles.container}>
        <SpView>
          <SpTitle
            text={t('select_food_type')}
            align="center"
          />
        </SpView>
        <FoodTypes
          active={foodType}
          onChange={changeCerberusFoodTypeAction}
        />
      </SpView>
    );
  }, [foodType, changeCerberusFoodTypeAction, t]);

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        [stepsEnums.InstructionStep1]: {
          ui: InstructionStep1,
          customHeaderProps: { ...headerProps, withRightButton: false },
          backBottomButton: true,
        },
        [stepsEnums.InsertBatteries]: {
          ui: InsertBatteries,
          props: {
            enableInsertBatteriesContinue,
            setNumberItems: setNumberTemplates,
          },
          customHeaderProps: headerProps,
          backBottomButton: true,
          wrap: PlacementInfoWrapper,
          buttonDisabled: !lastBatteriesTemplate,
        },
        [stepsEnums.LocalDevicePermission]: {
          ui: LocalDevicePermission,
          customHeaderProps: headerProps,
          backBottomButton: true,
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - LocalDevicePermission - interceptor');
            setTimeoutForWiFiProduct(300);
            startSearchForProduct('CDB', 1);
            setTimeout(() => {
              stopSearch(true);
              reset();
            }, 1000);

            return true;
          },
        },
        [stepsEnums.InstructionStep2]: {
          ui: InstructionStep2,
          customHeaderProps: headerProps,
          backBottomButton: true,
        },
        [stepsEnums.InstructionStep3]: {
          ui: InstructionStep3,
          props: {
            enableCDBConnectContinue,
            setNumberItems: setNumberCDBConnectTemplates,
            setStep,
            screenActive: step === stepsEnums.InstructionStep3,
          },
          customHeaderProps: headerProps,
          hideButton: true,
          backBottomButton: true,
          hideBackBottomButton: false,
        },
        [stepsEnums.WiFiConnectToDevice]: {
          ui: WiFiConnectToDevice({
            connectedToWiFiDevice: connectedToWiFiDevice && wifiConnectToDeviceTimerEnds,
            setRetryConnectToDevice,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: !connectedToWiFiDevice,
          interceptor: () => {
            if (connectedToWiFiDevice) {
              AnalyticsService.logEvent('AddDogBowl - WiFiConnectToDevice - interceptor - true');

              return true;
            }
            AnalyticsService.logEvent('AddDogBowl - WiFiConnectToDevice - interceptor - false');

            return false;
          },
        },
        [stepsEnums.WiFiSSIDSelect]: {
          ui: WiFiSSIDSelect({
            deviceSSIDS,
            targetNetworkID,
            loading,
            currentStep: step === 6,
          }),
          customHeaderProps: {
            ...headerProps,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: deviceSSIDS.length === 0 || targetNetworkID === -99,
          interceptor: () => {
            if (deviceSSIDS.length > 0 || targetNetworkID === 999) {
              AnalyticsService.logEvent('AddDogBowl - WiFiSSIDSelect - interceptor - true');

              return true;
            }
            AnalyticsService.logEvent('AddDogBowl - WiFiSSIDSelect - interceptor - false');

            // startSearch();
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            if (routeName === 'SelectSSID') {
              navigationRef.goBack();
              return true;
            }
            if (routeName === 'AddDogBowl') {
              stopSearchLeaveAction();
              return true;
            }
            return false;
          },
        },
        [stepsEnums.WiFiPasswordInput]: {
          ui: WiFiPasswordInput({
            SelectedWiFiSSID: deviceSSIDS[targetNetworkID]?.ssid || '',
            setTargetNetworkPassword,
            targetNetworkPassword,
            loading,
            error: error ? t(String(error)) : null,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: loading,
          buttonDisabled: targetNetworkPassword?.length < 5,
          interceptor: () => {
            if (!targetNetworkPassword) {
              AnalyticsService.logEvent('AddDogBowl - WiFiPasswordInput - interceptor - false');

              return false;
            }
            AnalyticsService.logEvent(
              'AddDogBowl - WiFiPasswordInput - interceptor - connectDeviceToWifi',
            );

            connectDeviceToWifi();
            return false;
          },
        },

        [stepsEnums.PairingToHouseholdStep]: {
          // @ts-ignore
          ui: PairingToHouseholdStep({
            isLoading: loading,
            stepActive: step === stepsEnums.PairingToHouseholdStep,
          }),
          hideButton: (() => {
            if (error && !loading) return false;
            return !pairedDeviceDeviceID;
          })(),
          buttonText: error && !loading ? t('try_again') : t('continue'),
          customHeaderProps: {
            withLeaveButton: false,
            goBackFunc: () => {
              if (error && !loading) {
                reset();
                setReturnToStep(stepsEnums.WiFiConnectToDevice);
                setStep(stepsEnums.WiFiConnectToDevice);
              } else {
                setStep(stepsEnums.WiFiPasswordInput);
              }
              stopSearch();
            },
          },
          interceptor: () => {
            if (error && !loading) {
              stopSearch();
              reset();
              setReturnToStep(stepsEnums.WiFiConnectToDevice);
              setStep(stepsEnums.WiFiConnectToDevice);
            } else {
              setStep(stepsEnums.NameForm);
            }
            return false;
          },
        },
        [stepsEnums.Paired]: {
          // @ts-ignore
          ui: Paired,
          props: { getHelpAction: onOpenGetHelpModal },
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
        },

        [stepsEnums.NameForm]: {
          ui: NameForm,
          props: {
            setName,
            label: t('product_name'),
            title: t('name_dogbowl_title'),
            subtitle: t('subtitle {{productName}} {{example}}', {
              example: t('name_dogbowl_example'),
              productName: t('dog_bowl_connect'),
            }),
            subtitle2: t('help_if_have_more_products'),
            disableKeyboardAwoiding: true,
            titleStyles: { marginTop: 0 },
            hideSubtitleOnFocus: false,
          },
          keyboardAwoidingProps: {
            androidAdjustType: 'custom',
            extraHeight: 75,
          },
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          wrap: NameFormWrap,
          formik: {
            schema: commonNameSchema(),
            names: ['name'],
          },
          extraScrollHeightAndroid: 100,
          buttonDisabled: name === '',
        },
        [stepsEnums.CompleteSetupProduct]: {
          ui: (
            <CompleteSetupProduct
              productId={DeviceType.Cerberus}
              onLoadedCallback={onSubmit}
              deviceId={pairedDeviceDeviceID}
              hideButton
            />
          ),
          // buttonDisabled: devicesReloaded,

          interceptor: () => {
            if (pets.length === 0) {
              AnalyticsService.logEvent(
                'AddDogBowl - CompleteSetupProduct - interceptor - pets.length === 0',
              );

              // This push causes CDB-1924? step 13-> create -> step 13-> create
              flowNavigation.navigate('CreatePetProfile', {
                cdb: true,
                device_id: pairedDeviceDeviceID,
              });
              return false;
            } else {
              AnalyticsService.logEvent(
                'AddDogBowl - CompleteSetupProduct - interceptor - pets.length > 0',
              );
              flowNavigation.navigate('AddPets', {
                deviceId: pairedDeviceDeviceID,
                deviceType: DeviceType.Cerberus,
                step: 3,
                loading: true,
                returnToStep: stepsEnums.SubstanceTypes,
              });
              return false;
            }
            AnalyticsService.logEvent('AddDogBowl - CompleteSetupProduct - interceptor - true');
            return true;
          },
        },
        [stepsEnums.SubstanceTypes]: {
          ui: SubstanceTypes({
            active: substanceType,
            onChange: changeSubstanceTypeAction,
            isSetup: true,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
          customTitleProgressHeader: t('configure_product'),
          buttonDisabled: !substanceType,
        },
        [stepsEnums.FoodTypes]: {
          ui: FoodTypesUI,
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
          skipStep: substanceType === SubstanceType.water,
          customTitleProgressHeader: t('configure_product'),
        },
        [stepsEnums.FirstConfigureProduct]: {
          ui: FirstConfigureProduct,
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          customTitleProgressHeader: t('configure_product'),
          backBottomButton: true,
        },
        [stepsEnums.ConfigureCDBCarousel]: {
          ui:
            substanceType === SubstanceType.water
              ? ConfigureCDBCarouselWater
              : ConfigureCDBCarouselFood,
          props:
            substanceType === SubstanceType.water
              ? {
                  enableConfigureCDBCarouselWaterContinue,
                  setNumberItems: setNumberWaterTemplates,
                }
              : {
                  enableConfigureCDBCarouselFoodContinue,
                  setNumberItems: setNumberFoodTemplates,
                },
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          customTitleProgressHeader: t('configure_product'),
          backBottomButton: true,
          buttonDisabled:
            substanceType === SubstanceType.water
              ? !lastCDBCarouselWaterTemplate
              : !lastCDBCarouselFoodTemplate,
        },
        [stepsEnums.DogBowlReady]: {
          ui: DogBowlReady,
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          customTitleProgressHeader: t('configure_product'),
          backBottomButton: true,
          interceptor: () => {
            setStep(stepsEnums.SetupComplete);
            return false;
          }
        },
        [stepsEnums.SetupComplete]: {
          ui: (
            <View style={[styles.center, { marginTop: 112 }]}>
              <EssenceCreated
                msg={t('setup_complete')}
                initialValue
              />
            </View>
          ),
          hideCustomHeader: true,
          customStyleProgressHeader: {
            marginTop: 50,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - SetupComplete - interceptor');
            navigateToProducts();
            return false;
          },
        },
        [stepsEnums.PairingFailed]: {
          // @ts-ignore
          ui: PairingFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },
        [stepsEnums.PairingWIFIFailed]: {
          // @ts-ignore
          ui: PairingWIFIFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },
        [stepsEnums.PairingInternetFailed]: {
          // @ts-ignore
          ui: PairingInternetFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },
        [stepsEnums.PairingServerFailed]: {
          // @ts-ignore
          ui: PairingServerFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },
        [stepsEnums.PairingMqttFailed]: {
          // @ts-ignore
          ui: PairingMqttFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('try_again'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },
        [stepsEnums.ThereSeemsToBeAProblem]: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblem,
          props: {
            bowlFlashingOrange,
            setIsFlashingOrange: setBowlFlashingOrange,
          },
          buttonText: t('continue'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: bowlFlashingOrange === null,
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - ThereSeemsToBeAProblem - interceptor');

            setStep(bowlFlashingOrange ? returnToStep : stepsEnums.WeHaveNotManageToConnectToCDB);
            setBowlFlashingOrange(null);
            return false;
          },
        },
        [stepsEnums.ThereSeemsToBeAProblemContactCS]: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblemContactCS,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          interceptor: () => {
            setStep(5);
            setBowlFlashingOrange(null);
            setSearchForDeviceAttempts(0);
            return false;
          },
        },
        [stepsEnums.WeHaveNotManageToConnectToCDB]: {
          // @ts-ignore
          ui: WeHaveNotManageToConnectToCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          hideProgressHeader: true,
          buttonText: t('try_again'),
          interceptor: () => {
            setStep(stepsEnums.HowToFactoryResetYourCDB);
            return false;
          },
        },
        [stepsEnums.HowToFactoryResetYourCDB]: {
          // @ts-ignore
          ui: HowToFactoryResetYourCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          hideProgressHeader: true,
          interceptor: () => {
            setStep(stepsEnums.InstructionStep2);
            return false;
          },
        },
        // 9: {
        //   // @ts-ignore
        //   ui: WeFailedToSetup,
        //   customHeaderProps: {...headerProps, withRightButton: false},
        //   hideProgressHeader: true,
        //   props:{},
        //   buttonText: t('setup_cdb_failed_to_pair_button'),
        // },
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  stepWrapper: {},
  formWrapper: { paddingHorizontal: 24 },
  center: {
    alignItems: 'center',
  },
});

export default AddDogBowl;
