import { faClock, faDroplet, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { usePetQuickActions } from '@hooks/usePetQuickActions';
import textStyles from '@styles/text';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { usePetCardQuickActions } from '../usePetCardQuickActions';
import useBoundStore from '../../../../store/store';
import { SpCenter } from '../../../../components/SpCenter';
import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from '../../../../components/SpView';
import PetLastActionTime from '../../Pets/pages/PetCarousel/components/PetLastActionTime';
import PetDrinkingFeeding from '../../Pets/pages/PetCarousel/components/PetDrinkingFeeding';
import { SpDivider } from '../../../../components/SpDivider';
import PetTimeAtBowl from '../../Pets/pages/PetCarousel/components/PetTimeAtBowl';
import { PetMovementNotification } from '../../components/PetMovementNotification';
import PetWalking from '../../Pets/pages/PetCarousel/components/PetWalking';
import PetTripsToday from '../../Pets/pages/PetCarousel/components/PetTripsToday';
import { SpVStack } from '../../../../components/SpVStack';
import InsightNotification from '../../components/ConsumptionHabit';
import { useTodayPetInsight } from '../../Pets/pages/PetCarousel/hooks/useTodayPetInsight';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { ReportTabs } from '../useReportTabs';
import { ReportType } from '../../constants/ReportType';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../index';

const usePetDisplayContentLogic = (
  pet,
  petActions: any,
  pendingChecking,
  adaptiveSize,
  viewStyle: 'normal' | 'compact' | 'subCompact' = 'normal',
) => {
  const { petStatisticsLoading } = useBoundStore(s => s.petStore);
  const { devicesIdsRequestsTrack, loadingControl } = useBoundStore(s => s.deviceStore);
  const quickActions = usePetCardQuickActions(pet);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { t } = useTranslation();
  const { QuickActionsModal, isModalOpen, setIsModalOpen, allowQuickActions } =
    usePetQuickActions(pet);

  const todayInsight = useTodayPetInsight({
    habits: petActions?.consumption_habit,
    alerts: petActions?.consumption_alert,
  });

  const onViewGraph = (date: string) => {
    navigate('PetsNavigation', {
      screen: 'PetDashboardReport',
      params: {
        type: ReportType.Drinking,
        tab: ReportTabs.Summary,
        viewedDays: ReportRangeType.OneDay,
        date,
      },
    });
  };

  const displayContentLogicInner = useMemo(() => {
    const drinking = petActions?.drinking;
    const feeding = petActions?.feeding;
    const movement = petActions?.movement;
    const notEmpty = {
      drinking: Object.keys(drinking || {}).length !== 0,
      movement: Object.keys(movement || {}).length !== 0,
      feeding: Object.keys(feeding || {}).length !== 0,
    };
    switch (true) {
      // Device has pending requests and it's only device for the pet
      case pendingChecking:
        return (
          <SpCenter flex={1}>
            <SpText
              style={[
                textStyles.smallTitle,
                styles.noDataText,
                viewStyle !== 'normal' && { fontSize: 10, lineHeight: 16 },
              ]}
            >
              {t('in_progress_warning_pet_card')}
            </SpText>
          </SpCenter>
        );

      // Drinking
      case notEmpty.drinking && !notEmpty.movement && !notEmpty.feeding:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faDroplet}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faDroplet}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                {todayInsight.insight && (
                  <InsightNotification
                    insight={todayInsight.insight}
                    isAlert={todayInsight.isAlert}
                    onViewGraph={onViewGraph}
                  />
                )}
                <PetDrinkingFeeding
                  data={drinking}
                  isDrinking
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                <SpDivider />
                <PetLastActionTime
                  icon={faClock}
                  data={drinking}
                  title={t('last_drank')}
                  disablePadding
                />
                <SpDivider />
                {!todayInsight.insight && (
                  <PetTimeAtBowl
                    data={drinking as any}
                    isFeeds={false}
                  />
                )}
              </SpView>
            );
        }

      // Movement
      case notEmpty.movement && !notEmpty.feeding && !notEmpty.drinking:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpView style={{ width: '100%' }}>
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  viewStyle={viewStyle}
                  setIsModalOpen={setIsModalOpen}
                />
              </SpView>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle="normal"
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  setIsModalOpen={setIsModalOpen}
                />
                <PetWalking
                  data={movement}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                <SpDivider />
                <PetTripsToday
                  data={movement}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
              </SpView>
            );
        }

      // Feeding
      case notEmpty.feeding && !notEmpty.movement && !notEmpty.drinking:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faUtensils}
                    data={feeding}
                    title={t('last_ate')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faUtensils}
                    data={feeding}
                    title={t('last_ate')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                <PetDrinkingFeeding
                  data={feeding}
                  isDrinking={false}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                <SpDivider />
                <PetLastActionTime
                  icon={faClock}
                  data={feeding}
                  title={t('last_ate')}
                  disablePadding
                />
                <SpDivider />
                {/* <PetMealsGivenNotification mealsGiven={1} /> */}
                <PetTimeAtBowl
                  data={feeding as any}
                  isFeeds
                />
              </SpView>
            );
        }

      // Drinking and Movement
      case notEmpty.drinking && notEmpty.movement && !notEmpty.feeding:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpVStack
                space={6}
                style={{
                  // alignItems: 'center',
                  alignContent: 'center',
                  justifyItems: 'center',
                  width: '100%',
                }}
              >
                <SpView>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle={viewStyle}
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
                <SpView style={{ marginBottom: 8, width: '100%' }}>
                  <SpDivider />
                </SpView>
                <SpView
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <PetLastActionTime
                    icon={faDroplet}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="subCompact"
                  />
                </SpView>
              </SpVStack>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle="compact"
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faDroplet}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  setIsModalOpen={setIsModalOpen}
                />
                <PetWalking
                  data={movement}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                {todayInsight.insight ? (
                  <InsightNotification
                    insight={todayInsight.insight}
                    isAlert={todayInsight.isAlert}
                    onViewGraph={onViewGraph}
                  />
                ) : (
                  <>
                    <SpDivider />
                    <PetDrinkingFeeding
                      data={drinking}
                      isDrinking
                      disablePadding
                      adaptiveSize={adaptiveSize}
                    />
                  </>
                )}
              </SpView>
            );
        }

      // Feeding and Movement
      case notEmpty.feeding && notEmpty.movement && !notEmpty.drinking:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpVStack
                space={6}
                style={{
                  // alignItems: 'center',
                  alignContent: 'center',
                  justifyItems: 'center',
                  width: '100%',
                }}
              >
                <SpView>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle={viewStyle}
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
                <SpView style={{ marginBottom: 8 }}>
                  <SpDivider />
                </SpView>
                <SpView
                  style={{
                    alignItems: 'center',
                    paddingHorizontal: 10,
                  }}
                >
                  <PetLastActionTime
                    icon={faUtensils}
                    data={feeding}
                    title={t('last_ate')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="subCompact"
                  />
                </SpView>
              </SpVStack>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle="compact"
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faUtensils}
                    data={feeding}
                    title={t('last_ate')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  setIsModalOpen={setIsModalOpen}
                />
                <PetWalking
                  data={movement}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                <SpDivider />
                <PetDrinkingFeeding
                  data={feeding}
                  isDrinking={false}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
              </SpView>
            );
        }

      // Drinking and Feeding
      case notEmpty.feeding && !notEmpty.movement && notEmpty.drinking:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpVStack
                space={6}
                style={{
                  alignContent: 'center',
                  justifyItems: 'center',
                  width: '100%',
                  paddingHorizontal: 10,
                }}
              >
                <PetLastActionTime
                  icon={faDroplet}
                  data={drinking}
                  title={t('last_drank')}
                  disablePadding
                  withTimeMarker={false}
                  viewStyle="subCompact"
                />

                <PetLastActionTime
                  icon={faUtensils}
                  data={feeding}
                  title={t('last_ate')}
                  disablePadding
                  withTimeMarker={false}
                  viewStyle="subCompact"
                />
              </SpVStack>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faDroplet}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
                <SpView style={{ flex: 1 }}>
                  <PetLastActionTime
                    icon={faUtensils}
                    data={feeding}
                    title={t('last_ate')}
                    disablePadding
                    withTimeMarker={false}
                    viewStyle="compact"
                  />
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                {todayInsight.insight ? (
                  <InsightNotification
                    insight={todayInsight.insight}
                    isAlert={todayInsight.isAlert}
                    onViewGraph={onViewGraph}
                  />
                ) : (
                  <PetLastActionTime
                    icon={faClock}
                    data={drinking}
                    title={t('last_drank')}
                    disablePadding
                  />
                )}
                <PetDrinkingFeeding
                  data={drinking}
                  isDrinking
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
                <SpDivider />
                <PetDrinkingFeeding
                  data={feeding}
                  isDrinking={false}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
              </SpView>
            );
        }

      // Drinking and Feeding and Movement
      case notEmpty.drinking && notEmpty.movement && notEmpty.drinking:
        switch (viewStyle) {
          case 'subCompact':
            return (
              <SpVStack
                space={6}
                style={{
                  alignContent: 'center',
                  justifyItems: 'center',
                  width: '100%',
                  paddingHorizontal: 10,
                }}
              >
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  viewStyle="ultraSubCompact"
                  setIsModalOpen={setIsModalOpen}
                />
                <SpView style={{ marginBottom: 8 }}>
                  <SpDivider />
                </SpView>
                <PetLastActionTime
                  icon={faUtensils}
                  data={feeding}
                  title={t('last_ate')}
                  disablePadding
                  withTimeMarker={false}
                  viewStyle="subCompact"
                />
                <PetLastActionTime
                  icon={faDroplet}
                  data={drinking}
                  title={t('last_drank')}
                  disablePadding
                  withTimeMarker={false}
                  viewStyle="subCompact"
                />
              </SpVStack>
            );
          case 'compact':
            return (
              <SpView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <SpView style={{ flex: 1 }}>
                  <PetMovementNotification
                    locationSince={movement.since}
                    location={movement.where}
                    viewStyle="compact"
                    setIsModalOpen={setIsModalOpen}
                  />
                </SpView>
                <SpView style={{ flex: 1 }}>
                  <SpView style={{ paddingLeft: '20%' }}>
                    <SpVStack space={5}>
                      <PetLastActionTime
                        icon={faUtensils}
                        data={feeding}
                        title={t('last_ate')}
                        disablePadding
                        withTimeMarker={false}
                        viewStyle="subCompact"
                      />
                      <PetLastActionTime
                        icon={faDroplet}
                        data={drinking}
                        title={t('last_drank')}
                        disablePadding
                        withTimeMarker={false}
                        viewStyle="subCompact"
                      />
                    </SpVStack>
                  </SpView>
                </SpView>
              </SpView>
            );
          case 'normal':
            return (
              <SpView style={{ height: '100%', flex: 1, justifyContent: 'space-evenly' }}>
                <PetMovementNotification
                  locationSince={movement.since}
                  location={movement.where}
                  setIsModalOpen={setIsModalOpen}
                />
                {todayInsight.insight ? (
                  <InsightNotification
                    insight={todayInsight.insight}
                    isAlert={todayInsight.isAlert}
                    onViewGraph={onViewGraph}
                  />
                ) : (
                  <>
                    <PetDrinkingFeeding
                      data={drinking}
                      isDrinking
                      disablePadding
                      adaptiveSize={adaptiveSize}
                    />
                    <SpDivider />
                  </>
                )}
                <PetDrinkingFeeding
                  data={feeding}
                  isDrinking={false}
                  disablePadding
                  adaptiveSize={adaptiveSize}
                />
              </SpView>
            );
        }

      default:
        return (
          <SpCenter flex={1}>
            <SpText style={[textStyles.smallTitle, styles.noDataText]}>
              {t('no_assigned_pets')}
            </SpText>
          </SpCenter>
        );
    }
  }, [
    pet,
    petActions,
    petStatisticsLoading,
    devicesIdsRequestsTrack,
    quickActions,
    loadingControl,
    pendingChecking,
  ]);
  const displayContentLogic = useMemo(() => {
    return (
      <>
        {displayContentLogicInner}
        {isModalOpen && QuickActionsModal}
      </>
    );
  }, [pet, petActions, quickActions, isModalOpen]);
  return { displayContentLogic };
};
const styles = StyleSheet.create({
  noDataText: {
    fontSize: 16,
    fontFamily: 'Rubik',
  },
});

export default usePetDisplayContentLogic;
