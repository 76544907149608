import { DeviceType } from '@constants/Device';
import { HouseholdUserPermissionLevel } from '@constants/HouseholdUserPermissionLevel';
import { useCustomToast } from '@hooks/useToast';
import useToastEffect from '@hooks/useToastEffect';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import { EditMenuItem } from '@models/Menu';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import SpAlert from '@utils/alert';
import i18n from '@utils/i18n';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SpButton } from 'src/components/SpButton';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { SpMenuStack } from 'src/components/SpMenuStack';
import { SpView } from 'src/components/SpView';
import { PetsPreviewModal } from './PetsPreviewModal';
import useBoundStore from '../../../../../../store/store';
import { RootStackParamList } from '../../../../../index';
import { getActions } from '../../constants/SettingActions';
import { DeviceStackParamList } from '../../index';
import { WebWrapper } from '../../../../../../components/WebWrapper';

interface NavigationListProps {
  device: DeviceModel;
  pets: PetModel[];
}

export const NavigationList = ({ device, pets }: NavigationListProps) => {
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const userHasWriteAccess = useUserHasWriteAccess();
  const {
    deleteDevice,
    assignPetSuccess,
    loadingControl,
    updateTargetSuccess,
    updateProperty,
    updateTargetError,
    updateCloseDeviceResult,
  } = useBoundStore((state) => state.deviceStore);
  const { show } = useCustomToast();
  const userLevel = useBoundStore(state => state.householdStore.userPermission());
  const { t } = useTranslation();
  const isOnline = device?.status.online;

  const onHandleDelete = useCallback(async () => {
    const result = await deleteDevice(device.id);
    if (result && device.product_id === DeviceType.Cerberus) {
      show({
        description: t('success_delete_device_{{device_name}}', { device_name: device?.name }),
        isError: !result,
      });
      setTimeout(
        () => rootNavigation.navigate('FlowNavigation', { screen: 'FactoryResetCDB' }),
        4200,
      );
    } else {
      show({
        description: t(result ? 'success_delete_device' : 'error_delete_device'),
        isError: !result,
      });
    }
    rootNavigation.navigate('DashboardNavigation');
  }, [deleteDevice, device.id, device.product_id, rootNavigation, show, t]);

  const deleteHandler = useCallback(() => {
    SpAlert(i18n.t('delete_product'), i18n.t('delete_product_message'), [
      {
        text: t('delete'),
        onPress: () => onHandleDelete(),
        style: 'destructive',
      },
      {
        text: t('cancel'),
        style: 'cancel',
      },
    ]);
  }, [device]);

  const clearStoreProperties = useCallback(() => {
    updateProperty({
      updateTargetError: null,
      updateTargetSuccess: false,
      loadingDevice: false,
    });
  }, [updateProperty]);

  useToastEffect({
    resetter: clearStoreProperties,
    successCondition: updateTargetSuccess,
    errorCondition: !!updateTargetError,
    successToastMessage: 'success_update_target',
    errorToastMessage: 'error_update_target',
    translate: true,
  });

  useToastEffect({
    resetter: () => {
      updateProperty({
        updateCloseDelayError: null,
        loadingDevice: false,
        updateCloseDeviceResult: 'none',
      });
    },
    successCondition: updateCloseDeviceResult === 'success',
    errorCondition: updateCloseDeviceResult === 'error',
    successToastMessage: 'success_lid_delay',
    errorToastMessage: 'error_lid_delay',
    translate: true,
  });

  const actions = useMemo(() => {
    if (!device) return [];
    const actionList = getActions(device, pets, navigation, userHasWriteAccess, loadingControl);
    // If device requires separate button types, segment list here on presence of svg.
    if (
      device.product_id === DeviceType.FeederConnect ||
      device.product_id === DeviceType.Cerberus
    ) {
      const testList: EditMenuItem[] = [];
      actionList.forEach((action) => {
        if (action.iconSVG) {
          testList.push(action);
        }
      });
      const actionsReturn = actionList.filter((action) => !action.iconSVG);
      return [actionsReturn, testList];
    }
    return [actionList, []];
  }, [device, navigation, userHasWriteAccess, pets, assignPetSuccess]);

  if (!device) return null;

  return (
    <SpView style={{ height: '100%' }}>
      <SpView style={styles.container}>
        <WebWrapper>
          <PetsPreviewModal />
          <SpView style={styles.menuStackWrap}>
            <SpEditMenuStack buttons={actions[0]} />
            {(device.product_id === DeviceType.FeederConnect ||
              device.product_id === DeviceType.Cerberus) && (
              <SpView style={styles.spMenuStackWrap}>
                <SpMenuStack buttons={actions[1]} />
              </SpView>
            )}
          </SpView>
        </WebWrapper>
      </SpView>
      {userLevel === HouseholdUserPermissionLevel.Admin && (
        <SpButton
          // TODO: Just call the delete endpoint - api handles the rest.
          onPress={deleteHandler}
          color="transparent"
          style={{ position: 'absolute', bottom: 60, width: '100%' }}
          textStyle={{
            color: colors.greyText.color,
            fontSize: 16,
            fontFamily: 'Rubik_Medium',
          }}
          title={i18n.t('delete_product')}
        />
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#fff',
    height: '100%',
    paddingBottom: 40,
  },
  menuStackOffset: {
    flexDirection: 'row',
    marginStart: 19,
    marginEnd: 19,
    marginTop: 19,
  },
  menuStackWrap: {
    marginTop: 1,
  },
  spMenuStackWrap: {
    marginTop: 32,
  },
});
