import { useMemo } from 'react';
import { ConsumptionInsight, ConsumptionInsightByDay } from '@models/ConsumptionInsight';
import { ConsumptionInsightWarningOutcomes } from '@constants/ConsumptionInsightTypes';

export const useInsightAlertStatus = (data: Record<number, ConsumptionInsightByDay>) => {
  return useMemo((): { habits: ConsumptionInsight[]; alerts: ConsumptionInsight[] } => {
    if (!data || !Object.values(data || {})) {
      return { habits: [], alerts: [] };
    }

    return Object.keys(data).reduce(
      (acc, curr) => {
        const habit = ConsumptionInsightWarningOutcomes.includes(data[curr]?.habit?.outcome)
          ? data[curr]?.habit
          : null;
        const alert = data[curr]?.alert;

        if (habit) {
          acc.habits.push(habit);
        }

        if (alert) {
          acc.alerts.push(alert);
        }

        return acc;
      },
      { habits: [], alerts: [] },
    );
  }, [data]);
};
