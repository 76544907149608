import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import React, { forwardRef, Ref } from 'react';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { SpText } from './SpText/SpText';
import { testProperties } from '@utils/testProperties';

interface SpToggleButtonProps {
  text: string;
  onPress: () => void;
  isActive: boolean;
  withIcon?: boolean;
  width?: number | string;
  stylesForContainer?: StyleProp<ViewStyle>;
  fullWidth?: boolean;
}

const SpToggleButton = forwardRef(function ToggleButton(
  {
    text,
    onPress,
    isActive,
    withIcon,
    width,
    stylesForContainer,
    fullWidth = false,
  }: SpToggleButtonProps,
  ref: Ref<any>,
): JSX.Element {
  const color = isActive ? colors.white.color : colors.greyText.color;
  return (
    <Pressable
      {...testProperties('SpToggleButton', `${text}`)}
      ref={ref}
      onPress={onPress}
      style={[
        styles.container,
        {
          width,
          paddingHorizontal: width ? 0 : 15,
          backgroundColor: isActive ? colors.greyText.color : 'transparent',
          flex: fullWidth ? 1 : 0,
        },
        stylesForContainer,
      ]}>
      <SpText color={color}>{text}</SpText>
      {withIcon && (
        <FontAwesomeIcon
          style={styles.icon}
          color={color}
          icon={faCaretDown}
        />
      )}
    </Pressable>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    borderWidth: 1,
    borderColor: colors.greySmallTitle.color,
    borderRadius: 8,
  },
  icon: { marginLeft: 4, marginBottom: 2 },
});

export default SpToggleButton;
