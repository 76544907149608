import RadioCheckboxWithText from './RadioCheckboxWithText';
import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import { SpVStack } from './SpVStack';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import { noop } from 'lodash-es';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { testProperties } from '@utils/testProperties';

export interface SpTermsCheckboxState {
  privacyPolicy: boolean;
  endUserLicenceAgreement: boolean;
}

interface SpTermsCheckboxProps {
  active: SpTermsCheckboxState;
  disabled?: SpTermsCheckboxState;
  languageCode: string;
  onChange: (data: SpTermsCheckboxState) => void;
}

export const SpTermsCheckboxes: React.FC<SpTermsCheckboxProps> = ({
  disabled,
  active,
  languageCode,
  onChange,
}) => {
  const { t } = useTranslation();
  const privacyPolicy = useMemo(() => {
    const result = [
      { text: t('read_and_agree') },
      {
        text: t('privacy_policy'),
        link: t('privacy_policy_link'),
      },
    ];

    return languageCode === 'en'
      ? result
      : [
          ...result,
          { text: ' (' + t('view_in_english') + ')', link: t('privacy_policy_english_link') },
        ];
  }, [languageCode, t]);

  const termsOfUse = useMemo(() => {
    const result = [
      { text: t('read_and_agree') },
      {
        text: t('end_user_agreement'),
        link: t('end_user_licence_agreement_link'),
      },
    ];

    return languageCode === 'en'
      ? result
      : [
          ...result,
          {
            text: ' (' + t('view_in_english') + ')',
            link: t('end_user_licence_agreement_english_link'),
          },
        ];
  }, [languageCode, t]);

  return (
    <SpVStack space={8}>
      <RadioCheckboxWithText
        active={active.endUserLicenceAgreement}
        handler={($event: boolean) => {
          onChange({ ...active, endUserLicenceAgreement: $event });
        }}
        disabled={disabled?.endUserLicenceAgreement}
        text={
          <TextTerm
            data={termsOfUse}
            id={1}
          />
        }
        title={termsOfUse[1].text}
      />
      <RadioCheckboxWithText
        active={active.privacyPolicy}
        handler={($event: boolean) => {
          onChange({ ...active, privacyPolicy: $event });
        }}
        disabled={disabled?.privacyPolicy}
        text={
          <TextTerm
            data={privacyPolicy}
            id={2}
          />
        }
        title={privacyPolicy[1].text}
      />
    </SpVStack>
  );
};

type Params = {
  text: string;
  link?: string;
};

const getTextProps = (item: Params, index: number, data: Params[]) => {
  return {
    ...testProperties(
      `${item?.link ? 'Link' : 'Text'}`,
      ``,
      `${
        item?.link
          ? item.text
              ?.toString()
              ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return word.toUpperCase();
              })
              ?.replace(/\s+/g, '')
              ?.replace(/[!?,]/g, '')
          : `${item.text
              ?.toString()
              ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return word.toUpperCase();
              })
              ?.replace(/\s+/g, '')
              ?.replace(/[!?,]/g, '')}${data[index + 1].text}`
      }`,
    ),
  };
};

const TextTerm = memo(({ data, id }: { data: Params[]; id: number }) => {
  return (
    <Text
      {...testProperties(`Wrapper`, `${id}`, 'TextTerm')}
      style={styles.textContainer}>
      {data.map((item, index) => (
        <Text
          key={index}
          style={[styles.text, item?.link ? textStyles.linkText : null]}
          onPress={() => (item?.link ? Linking.openURL(item.link) : noop)}
          {...getTextProps(item, index, data)}>
          {item.text}
        </Text>
      ))}
    </Text>
  );
});

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontFamily: 'Rubik',
    color: colors.greyText.color,
  },
});
