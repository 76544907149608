import { AppLinkingPrefixes } from '@constants/AppLinkingPrefixes';
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

import { RootStackParamList } from './index';
import { Platform } from 'react-native';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    ...AppLinkingPrefixes,
    Linking.createURL('/'),
    'https://*.surehub.io',
    'https://surehub.io',
    'https://surepetcare.io',
    'spc://',
  ],

  config: {
    screens: {
      DashboardNavigation: {
        screens: {
          Pets: 'pets',
          Timeline: 'timeline',
          Products: 'products',
        },
      },
      PetsNavigation: {
        screens: {
          PetDashboardSummary: 'pet/:id/summary',
          PetProfileSettings: 'pet/:id/settings',
          PetDashboardEditSettings: 'pet/:id/settings-edit',
        },
      },
      DeviceNavigation: {
        screens: {
          DeviceDetails: 'device/:id/details',
          DeviceSetting: 'device/:id/settings',
          DeviceAssignPet: 'device/:id/assign-pet',
          DeviceName: 'device/:id/name',
          DeviceModalTaringNeeded: 'device/:id/taring-needed',
          DeviceBowlSetup: 'device/:id/bowl-setup',
          DevicePortioning: 'device/:id/portioning',
          DeviceLidDelay: 'device/:id/lid-delay',
        },
      },
      ResetPassword: 'auth/password/reset/:token?',

      LoginNavigation: 'login',
      // LoginNavigation: 'auth/password/reset/:token?',
      AuthTokenLogin: 'auth-token-login/:token?',
    },
  },
  async getInitialURL() {
    console.log('getInitialURL');
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      console.log('GOT INITIAL URL');
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    console.log('getLastNotificationResponseAsync response', response);
    // ios: response?.notification.request.trigger?.payload?.url
    return 'spc://' + Platform.OS === 'android'
      ? response?.notification.request.trigger?.remoteMessage?.data?.url
      : response?.notification.request.trigger?.payload?.url;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => {
      // if url include iam/ then its a deeplink, split and get everything after iam/ then open it
      if (url.includes('iam/')) {
        return;
      }

      return listener(url);
    };

    // Listen to incoming links from deep linking
    const listenerInstance = Linking.addEventListener('url', onReceiveURL);

    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      // For android we must have the url, channelId, title, and message property in the data object NOT the notification object
      // {
      //   "GCM": "{ \"data\": { \"message\": \"Sample message for Android endpoints\", \"url\": \"timeline\", \"channelId\": \"cat\" } }"
      // }

      // {
      //   "APNS": "{\"aps\":{\"alert\": \"Sample message for iOS endpoints\", \"sound\":\"cat.mp3\"}, \"url\":\"timeline\" }"
      // }
      const { url } =
        Platform.OS === 'android'
          ? response?.notification.request.trigger?.remoteMessage?.data
          : response.notification?.request?.trigger?.payload;
      // Any custom logic to see whether the URL needs to be handled
      // ...

      // Let React Navigation handle the URL
      listener('spc://' + url);
    });

    return () => {
      // Clean up the event listeners
      listenerInstance.remove();
      subscription.remove();
    };
  },
};

export default linking;
