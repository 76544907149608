import colors from '@styles/colors';
import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  LayoutChangeEvent,
  Platform,
  SafeAreaView,
  StyleSheet,
} from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';

export type CustomKeyboardAvoidingViewProps = {
  children: React.ReactNode;
  disabled?: boolean;
  androidAdjustType?: 'full' | 'custom' | 'force';
  scrollView?: boolean;
  modal?: boolean;
  enableManualScroll?: boolean;
  step?: number;
} & KeyboardAwareScrollViewProps;

const CustomKeyboardAvoidingView: React.FC<CustomKeyboardAvoidingViewProps> = ({
  children,
  disabled = false,
  androidAdjustType = 'full',
  scrollView = true,
  modal = false,
  step,
  enableManualScroll,
  ...props
}) => {
  const keyboardAwareScrollViewRef = useRef();
  const isAndroid = Platform.OS === 'android';
  const [height, setHeight] = useState(0);
  const layoutHandler = (event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  };

  useLayoutEffect(() => {
    if (keyboardAwareScrollViewRef.current) {
      keyboardAwareScrollViewRef.current.scrollToPosition?.(0);
    }
  }, [step]);

  if (disabled || Platform.OS === 'web') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const androidContentContainerStyle =
    isAndroid && androidAdjustType !== 'custom'
      ? {
          height: height + (androidAdjustType === 'force' ? EXTRA_SIZE_FOR_CONTENT : 0),
        }
      : undefined;
  const scrollToBottom = () => {
    if (keyboardAwareScrollViewRef.current && enableManualScroll === true) {
      keyboardAwareScrollViewRef.current?.scrollToEnd({ animated: true });
    }
  };
  return scrollView ? (
    <KeyboardAwareScrollView
      ref={keyboardAwareScrollViewRef}
      onKeyboardDidShow={() => isAndroid && scrollToBottom()}
      style={[styles.container, androidAdjustType === 'force' && isAndroid && styles.forceHeight]}
      contentContainerStyle={[styles.contentContainerStyle, androidContentContainerStyle]}
      enableAutomaticScroll
      enableOnAndroid={androidAdjustType !== 'full'}
      showsVerticalScrollIndicator={false}
      onLayout={height === 0 ? layoutHandler : undefined}
      extraHeight={Platform.OS === 'android' ? EXTRA_SIZE_FOR_CONTENT : undefined}
      {...props}
      accessible={false}>
      {children}
    </KeyboardAwareScrollView>
  ) : (
    <KeyboardAvoidingView
      style={[styles.container, androidAdjustType === 'force' && isAndroid && styles.forceHeight]}
      behavior={isAndroid ? 'height' : 'height'}
      keyboardVerticalOffset={modal ? 100 : 0}
      contentContainerStyle={[styles.contentContainerStyle, androidContentContainerStyle]}
      accessible={false}>
      <SafeAreaView style={{ flex: 1 }} accessible={false}>{children}</SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const EXTRA_SIZE_FOR_CONTENT = 70;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  forceHeight: {
    height: Dimensions.get('window').height * 0.7,
  },
});

export default CustomKeyboardAvoidingView;
