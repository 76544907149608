import { SpView } from 'src/components/SpView';
import { format } from 'date-fns';
import { maxBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { Dimensions } from 'react-native';
import { Circle, Defs, LinearGradient, Stop, Svg, Text as SvgText } from 'react-native-svg';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
} from 'victory-native';

const windowWidth = Dimensions.get('window').width;

export type WeeklyGraphPointModel = {
  x: number;
  y: number;
  label: string;
  warning: boolean;
  date: number;
};

interface WeeklyGraphProps {
  events: WeeklyGraphPointModel[];
  withLastSegment?: boolean;
  padding?: number;
  height?: number;
}

const windowHeight = Dimensions.get('window').height;

export const WeeklyGraph = ({
  events,
  padding = 0,
  withLastSegment = true,
  height = windowHeight < 810 ? 115 : 165,
}: WeeklyGraphProps) => {
  const warnings = useMemo(() => {
    return events.reduce((acc, item) => {
      if (item.warning) {
        acc.push({ x: item.x, y: item.y });
      }

      return acc;
    }, []);
  }, [events]);

  const current = useMemo(() => {
    return events.length ? [events.at(-1)] : [];
  }, [events]);

  const yAxisData = useMemo(() => {
    return events.map((item, index) =>
      index === 0 ? `${format(item.date, 'EEEEEE')}x` : format(item.date, 'EEEEEE'),
    );
  }, [events]);

  const maxY = useMemo(() => {
    return maxBy(events, item => item.y)?.y || 0;
  }, [events]);

  const startSegment = useMemo(() => {
    return events?.slice(0, events.length - 1);
  }, [events]);

  const lastSegment = useMemo(() => {
    const lastPoint = events?.[events.length - 1];
    const secondToLastPoint = events?.[events.length - 2];
    return [secondToLastPoint, lastPoint];
  }, [events]);

  if (!events.length) {
    return null;
  }

  return (
    <SpView width={windowWidth - padding}>
      <VictoryChart
        height={height}
        width={windowWidth - padding}
        padding={{ right: 10, left: -30, top: 10, bottom: 38 }}
        minDomain={{ y: 0 }}
        maxDomain={{ y: maxY * 1.5 }}
        domainPadding={{ x: [0, 35] }}
      >
        <Defs>
          <LinearGradient
            id="gradientStroke"
            gradientTransform="rotate(90)"
          >
            <Stop
              offset="0"
              stopOpacity={0.5}
              stopColor="#45acd9"
            />
            <Stop
              offset="0.15"
              stopOpacity={0.43}
              stopColor="rgba(104, 198, 230, 0.43)"
            />
            <Stop
              offset="0.4"
              stopOpacity={0}
              stopColor="#fff"
            />
          </LinearGradient>
        </Defs>
        <VictoryArea
          padding={{ bottom: 50 }}
          style={{
            data: {
              fill: 'url(#gradientStroke)',
            },
          }}
          labels={data => data}
          data={events}
          labelComponent={<CustomVictoryAreaLabel />}
        />
        <VictoryLine
          labelComponent={<></>}
          data={startSegment}
          style={{
            data: {
              stroke: '#02BCCD',
              strokeWidth: 3,
            },
          }}
        />
        <VictoryLine
          labels={data => data}
          labelComponent={<CustomVictoryAreaLabel />}
          data={lastSegment}
          style={{
            data: {
              stroke: '#03A2B1',
              strokeWidth: 3,
              strokeDasharray: withLastSegment ? '5, 5' : '0',
            },
          }}
        />
        <VictoryScatter
          dataComponent={<WarningCircleLabel />}
          data={warnings}
        />
        <VictoryScatter
          data={current}
          dataComponent={<CurrentDayCircleLabel />}
          labelComponent={<CustomVictoryAreaLabel />}
        />
        <VictoryAxis
          tickValues={yAxisData}
          tickLabelComponent={<CustomTickLabel />}
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
          }}
        />
      </VictoryChart>
    </SpView>
  );
};

const CustomText = ({
  x,
  y,
  text,
  color,
}: {
  x: number;
  y: number;
  text: string;
  color: string;
}) => {
  return (
    <SvgText
      fill={color}
      stroke="none"
      fontSize="14"
      fontWeight="500"
      x={x}
      y={y}
      textAnchor="middle"
    >
      {text}
    </SvgText>
  );
};

const CustomTickLabel = ({ index, x, y, text }: any) => {
  // 6 its last
  if (+index === 7) {
    return (
      <Svg>
        <Circle
          cx={x}
          cy={y + 13}
          r="13"
          fill="#02BCCD"
        />
        <CustomText
          x={x}
          y={y + 18}
          color="#ffffff"
          text={text}
        />
      </Svg>
    );
  }

  return (
    <CustomText
      x={x}
      y={y + 18}
      color="#919ba0"
      text={text}
    />
  );
};

const WarningCircleLabel = ({ x, y }: any) => {
  return (
    <Circle
      cx={x}
      cy={y - 2}
      r="10"
      fill="#ffa239"
    />
  );
};

const CurrentDayCircleLabel = ({ x, y }: any) => {
  return (
    <Circle
      cx={x}
      cy={y}
      r="8"
      strokeWidth="4"
      stroke="#02BCCD"
      fill="#fff"
    />
  );
};

const CustomVictoryAreaLabel = ({ index, y, x, datum }: any) => {
  // 7 is the last because with fetch 8 items for 7 days graph to render the line
  // before the first day on chart
  return +index === 7 ? (
    <VictoryLabel
      dy={-16}
      textAnchor="middle"
      y={y}
      x={x - 13}
      text={datum.label}
      style={{ fill: '#263a43' }}
    />
  ) : null;
};
