import { differenceInDays, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { TimeService } from 'src/services/TimeService';
import { MergedInterfaces } from '../store/models';
import useBoundStore from '../store/store';

const MAX_DAYS_TO_EXPIRE = 30;

export const useReconfirmTermsAndConditions = () => {
  const [date, setDate] = useState(null);
  const user = useBoundStore(s => s.accountStore.user);
  const { allowTemporaryViewAppBasedOnTerms } = useBoundStore(
    (state: MergedInterfaces) => state.UIStore,
  );

  const userTermsAccepted = user?.terms_accepted;

  if (userTermsAccepted && isValid(new Date(date))) {
    const acceptedDate = TimeService.convertOrUnconvertDateByUTC<number>(
      +new Date(userTermsAccepted),
      true,
    );
    const termsChangeDate = TimeService.convertOrUnconvertDateByUTC<number>(+new Date(date), true);
    const leftDays =
      MAX_DAYS_TO_EXPIRE -
      Math.abs(
        differenceInDays(
          TimeService.convertOrUnconvertDateByUTC<number>(Date.now(), true),
          termsChangeDate,
        ),
      );
    const daysFullyExpired = leftDays <= 0;

    // User approved
    if (acceptedDate > termsChangeDate) {
      return { isTermsChanged: false, daysToExpire: null, setDate };
    }

    // User can short-term use app
    if (allowTemporaryViewAppBasedOnTerms && !daysFullyExpired) {
      return { isTermsChanged: false, daysToExpire: null, setDate };
    }

    // Fully expired
    if (daysFullyExpired) {
      return { isTermsChanged: true, daysToExpire: leftDays, setDate };
    }

    return { isTermsChanged: true, daysToExpire: leftDays, setDate };
  }

  return { isTermsChanged: false, daysToExpire: null, setDate };

  // useEffect(() => {
  //   const checkFlow = async () => {
  //     if (termsChanged) {
  //       const firstTimeModalShowed = await AsyncStorage.getItem(
  //         'reconfirm_terms_date',
  //       );

  //       const parsed = firstTimeModalShowed
  //         ? JSON.parse(firstTimeModalShowed)
  //         : '';
  //       const firstModalShowedByID = parsed[user?.id];
  //       if (!firstModalShowedByID || firstModalShowedByID === 'undefined') {
  //         const current = await JSON.parse(
  //           `{"${String(user?.id)}": "${new Date().toISOString()}"}`,
  //         );
  //         await AsyncStorage.setItem(
  //           'reconfirm_terms_date',
  //           JSON.stringify({ ...parsed, ...current }),
  //         );
  //       }

  //       if (firstModalShowedByID && firstModalShowedByID !== 'undefined') {
  //         const diff = differenceInMinutes(
  //           new Date(),
  //           new Date(firstModalShowedByID),
  //         );

  //         setDaysToExpire(maxDaysToExpire - Number((diff / 1440).toFixed(0)));
  //       }
  //     }
  //   };

  //   checkFlow();
  // }, [termsChanged, setDaysToExpire]);
  // return [termsChanged, daysToExpire];
};
