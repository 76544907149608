import { SafeAreaView, StyleSheet, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { AppImages } from '@constants/AppImages';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { DeviceType } from '@constants/Device';
import { ScrollView } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';
import { FlowStackParamList } from '.';
import ProductButton from './components/ProductButton';
import useBoundStore from '../../store/store';
import { useRemoteConfigBooleanValue } from '@hooks/useRemoteConfigBooleanValue';

import { Env } from 'Env';
import { RemoteConfigKeys } from '@constants/RemoteConfig';

const SelectProduct = () => {
  const deviceStoreData = useBoundStore(state => ({
    setProductSetupSelection: state.deviceStore.setProductSetupSelection,
  }));
  const { t } = useTranslation();
  const [isHubAdded, setIsHubAdded] = useState(false);
  const devices = useDevicesByHousehold();
  useEffect(() => {
    const isHubExists = devices.find(d => d.product_id === 1);
    setIsHubAdded(Boolean(isHubExists));
  }, [devices]);
  const navigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const cerberusVisible = useRemoteConfigBooleanValue(RemoteConfigKeys.ceberusVisible);
  useEffect(() => {
    console.log('cerberusVisible', cerberusVisible);
  }, [cerberusVisible]);
  let productButtons = useMemo(() => {
    let existing = [
      {
        name: t('cat_flap'),
        image: AppImages.setupProductCatFlap,
        type: DeviceType.CatFlapConnect,
        action: () => navigation.navigate('AddCatFlap'),
      },
      {
        name: t('pet_door'),
        image: AppImages.setupProductPetDoor,
        type: DeviceType.PetDoorConnect,
        action: () => navigation.navigate('AddPetDoor'),
      },
      {
        name: t('pet_feeder'),
        image: AppImages.setupProductPetFeeder,
        type: DeviceType.FeederConnect,
        action: () => navigation.navigate('AddFeeder'),
      },
      {
        name: t('felaqua'),
        type: DeviceType.FelaquaConnect,
        image: AppImages.setupProductFelaqua,
        action: () => navigation.navigate('AddFelaqua'),
      },
    ];
    if (cerberusVisible) {
      existing.push({
        name: t('dog_bowl'),
        image: AppImages.cerberus,
        type: DeviceType.Cerberus,
        action: () => navigation.navigate('AddDogBowl'),
      });
    }
    return existing;
  }, [Env, cerberusVisible]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={styles.inner}
        contentContainerStyle={styles.contentContainer}>
        {productButtons.map((button, index) => {
          return (
            <View
              key={button.name + index}
              style={index !== 0 && { marginTop: 24 }}>
              <ProductButton
                {...button}
                action={() => {
                  deviceStoreData.setProductSetupSelection(button.type);
                  if (isHubAdded || button.type === DeviceType.Cerberus) {
                    return button.action();
                  }
                  navigation.navigate('AddHub');
                  return true;
                }}
              />
            </View>
          );
        })}
      </ScrollView>
    </SafeAreaView>
  );
};

export default SelectProduct;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: colors.white.color },
  inner: {
    flex: 1,
  },
  contentContainer: {
    paddingHorizontal: 19,
    paddingVertical: 32,
  },
  hubModal: {
    margin: 24,
    borderRadius: 15,
    overflow: 'hidden',
    minWidth: '90%',
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalText: {
    fontSize: 22,
    textAlign: 'center',
    color: colors.placeholderGrey.color,
    marginBottom: 20,
    paddingHorizontal: 24,
    fontWeight: 'bold',
  },
});
