export enum TimelineEventType {
  Movement,
  LowBattery,
  NewTag,
  NewDevice,
  HubPairingMode,
  LearnMode,
  DoorLockingMode,
  IntruderMovement,
  HubChildOnline = 9,
  PendingInvite,
  InviteHandled,
  UserJoinedHousehold,
  NewPet,
  NewPhoto,
  AccountCreated = 17,
  DeviceOnline,
  NewUserProfilePhoto,
  CurfewLockStatus,
  WeightChanged,
  Feeding,
  TargetWeightSet,
  Tare,
  PetPermissionsChanged,
  WeightChangedTargetMet = 27,
  TrainingMode = 28,
  PoseidonDrinking,
  PoseidonWeightChanged,
  PoseidonTar,
  PoseidonWaterFreshness,
  PoseidonLowWater,
  WaterRemoved,
  CurfewTimezoneChange = 40,
  TaringRequired = 52,
  TarringOccurred = 53,
}

export const AllowedTimelineEvents = [
  TimelineEventType.Movement,
  TimelineEventType.LowBattery,
  TimelineEventType.NewTag,
  TimelineEventType.NewDevice,
  TimelineEventType.DoorLockingMode,
  TimelineEventType.IntruderMovement,
  TimelineEventType.UserJoinedHousehold,
  TimelineEventType.NewPet,
  TimelineEventType.AccountCreated,
  TimelineEventType.CurfewLockStatus,
  TimelineEventType.WeightChanged,
  TimelineEventType.Feeding,
  TimelineEventType.TargetWeightSet,
  TimelineEventType.Tare,
  TimelineEventType.PetPermissionsChanged,
  TimelineEventType.WeightChangedTargetMet,
  TimelineEventType.TrainingMode,
  TimelineEventType.PoseidonDrinking,
  TimelineEventType.PoseidonWeightChanged,
  TimelineEventType.PoseidonWaterFreshness,
  TimelineEventType.PoseidonLowWater,
  TimelineEventType.WaterRemoved,
  TimelineEventType.CurfewTimezoneChange,
  TimelineEventType.DeviceOnline,
  TimelineEventType.TaringRequired,
  TimelineEventType.TarringOccurred,
];
