/* eslint-disable class-methods-use-this */
import PetApi from '@api/PetApi';
import { SubstanceType } from '@constants/Device';
import { PetPosition } from '@constants/Pet';
import { DeviceCerberusControlModel, DeviceModel, PetStatisticsModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import i18n from '@utils/i18n';
import cloneDeep from 'lodash-es/cloneDeep';

import { DdLogs } from './SPDataDogService';
import { ConsumptionInsightApiResponse } from '@models/ConsumptionInsight';

enum DeviceUpdatedUnum {
  Hub = 1,
  Repeater = 2,
  PetDoorConnect = 3,
  FeederConnect = 4,
  CatFlapConnect = 6,
  FelaquaConnect = 8,
  Curberus = 32,
  WaterCberus = 64,
  FoodCurberus = 128,
  BothCerberus = 256,
}

type PetsData = { petId: number; petDevice: DeviceUpdatedUnum[] }[];

class PetStatisticsService {
  private placeholders = {
    drinking: {
      date: undefined,
      total_consumption: 0,
      last_consumption: undefined,
      substance_type: SubstanceType.water,
      activity: [],
      number_of_visits: 0,
      consumption_time: 0,
    },
    feeding: {
      date: undefined,
      total_consumption: 0,
      last_consumption: undefined,
      substance_type: SubstanceType.food,
      activity: [],
      number_of_visits: 0,
      consumption_time: 0,
    },
    movement: {
      date: undefined,
      time_outside: 0,
      since: undefined,
      trips_outside: 0,
      activity: [],
      where: PetPosition.outside,
      last_entry: undefined,
    },
  };

  async startLoading(
    pets: PetModel[],
    devices: DeviceModel[],
    from: Date,
    prevPetStatistics?: PetStatisticsModel[],
    daysInHistory = 7,
  ): Promise<PetStatisticsModel[]> {
    // TODO: refactor pet statistic Store so our cache will be a collections of records not an array of data
    DdLogs.info(`PetStatisticsService - startLoading - ${daysInHistory}`, { days: daysInHistory });
    const convertedDate = from.toISOString();
    try {
      const petStatistics = await this.createRequests(pets, devices, convertedDate, daysInHistory);
      const prev = this.changeBasedOnAssigning(prevPetStatistics, pets, devices);
      const newPetStatistics = this.getTransoformedStatistics(pets, devices, petStatistics.flat());
      newPetStatistics.forEach((item, index) => {
        const convertedObj = this.formatStatistics(item);
        const prevIndex = prev.findIndex(elem => elem.pet_id === item.pet_id);
        if (prevIndex >= 0) {
          prev[prevIndex] = convertedObj;
        } else {
          prev.push(convertedObj);
        }
      });
      return prev;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPetsConsumptions(
    id: number,
    from: Date,
    daysInHistory = 1,
  ): Promise<ConsumptionInsightApiResponse> {
    return PetApi.getPetInsights(id);
  }

  getProductIdOrCerberusType(device: DeviceModel): boolean | number {
    if ((device.product_id as unknown) === DeviceUpdatedUnum.Curberus) {
      const substanceType = (device?.control as DeviceCerberusControlModel)?.substance_type;
      if (substanceType === SubstanceType.water) {
        return DeviceUpdatedUnum.WaterCberus;
      }
      if (substanceType === SubstanceType.food) {
        return DeviceUpdatedUnum.FoodCurberus;
      }
      return DeviceUpdatedUnum.BothCerberus;
    }
    return device.product_id;
  }

  getPetsDeviceData(pets: PetModel[], devices: DeviceModel[]) {
    const petsData: PetsData = [];
    pets.forEach(pet => {
      devices.forEach(device => {
        const foundTag = (device?.tags || []).find(tag => tag.id === pet.tag_id);
        if (!foundTag) return;
        const foundIndex = petsData.findIndex(item => item.petId === pet.id);
        const isSuccess = this.getProductIdOrCerberusType({
          product_id: device.product_id,
          control: device.control,
        });
        if (!isSuccess) return;
        if (foundIndex !== -1) {
          petsData[foundIndex].petDevice.push(isSuccess as unknown as DeviceUpdatedUnum);
        } else {
          petsData.push({
            petId: pet.id,
            petDevice: [isSuccess as unknown as DeviceUpdatedUnum],
          });
        }
      });
    });
    return petsData;
  }

  createRequests(pets: PetModel[], devices: DeviceModel[], from: string, daysInHistory?: number) {
    const petsData = this.getPetsDeviceData(pets, devices);
    const drinkingPets: number[] = [];
    const otherPets: number[] = [];
    const promises: Promise<any>[] = [];

    petsData.forEach(({ petDevice, petId }) => {
      if (petDevice.length) {
        if (
          petDevice.includes(DeviceUpdatedUnum.WaterCberus) ||
          petDevice.includes(DeviceUpdatedUnum.FelaquaConnect)
        ) {
          drinkingPets.push(petId);
          return;
        }
        otherPets.push(petId);
      }
    });
    if (drinkingPets.length) {
      promises.push(PetApi.getPetStatistics(drinkingPets, from, 7));
    }
    if (otherPets.length) {
      promises.push(PetApi.getPetStatistics(otherPets, from, daysInHistory));
    }

    return Promise.all(promises);
  }

  getTransoformedStatistics(
    pets: PetModel[],
    devices: DeviceModel[],
    stats: Record<string, any>[],
  ): Record<string, any>[] {
    const petStatistics = cloneDeep(stats);

    return petStatistics.map(item => {
      const petsData = this.getPetsDeviceData(pets, devices);
      const value = petsData.find(elem => elem.petId === item.pet_id);
      if (!value) return item;
      const { petDevice } = value;
      if (
        petDevice.includes(DeviceUpdatedUnum.CatFlapConnect) ||
        petDevice.includes(DeviceUpdatedUnum.PetDoorConnect)
      ) {
        if (!item.movement) {
          item.movement = { ...this.placeholders.movement };
        }
      } else {
        delete item.movement;
      }
      if (
        petDevice.includes(DeviceUpdatedUnum.WaterCberus) ||
        petDevice.includes(DeviceUpdatedUnum.FelaquaConnect) ||
        petDevice.includes(DeviceUpdatedUnum.BothCerberus)
      ) {
        if (!item.drinking) {
          item.drinking = { ...this.placeholders.drinking };
        }
      } else {
        delete item.drinking;
      }
      if (
        petDevice.includes(DeviceUpdatedUnum.FoodCurberus) ||
        petDevice.includes(DeviceUpdatedUnum.FeederConnect) ||
        petDevice.includes(DeviceUpdatedUnum.BothCerberus)
      ) {
        if (!item.feeding) {
          item.feeding = { ...this.placeholders.feeding };
        }
      } else {
        delete item.feeding;
      }
      return item;
    });
  }

  changeBasedOnAssigning(data: PetStatisticsModel[], pets: PetModel[], devices: DeviceModel[]) {
    const newStatistics: PetStatisticsModel[] = cloneDeep(data);
    pets.forEach(pet => {
      devices.forEach(device => {
        const foundTag = (device?.tags || []).find(tag => tag.id === pet.tag_id);
        let item = newStatistics.find(elem => elem.pet_id === pet.id);
        // If pet assigned and no data we still show default layout depending on device
        if (!item && foundTag) {
          item = {
            pet_id: pet.id,
            movement: null,
            feeding: null,
            drinking: null,
          } as PetStatisticsModel;
          newStatistics.push(item);
        }
        if (!item) return;

        const isSuccess = this.getProductIdOrCerberusType({
          product_id: device.product_id,
          control: device.control,
        });
        if (!isSuccess) return;

        const productId = isSuccess as unknown as DeviceUpdatedUnum;

        if (
          [
            DeviceUpdatedUnum.FeederConnect,
            DeviceUpdatedUnum.FoodCurberus,
            DeviceUpdatedUnum.BothCerberus,
          ].includes(productId)
        ) {
          if (!foundTag) {
            delete item.feeding;
          } else if (!item.feeding && foundTag) {
            item.feeding = { ...this.placeholders.feeding };
          }
        }
        if (
          [DeviceUpdatedUnum.PetDoorConnect, DeviceUpdatedUnum.CatFlapConnect].includes(productId)
        ) {
          if (!foundTag) {
            delete item.movement;
          } else if (!item.movement && foundTag) {
            item.movement = { ...this.placeholders.movement };
          }
        }
        if (
          [
            DeviceUpdatedUnum.WaterCberus,
            DeviceUpdatedUnum.FelaquaConnect,
            DeviceUpdatedUnum.BothCerberus,
          ].includes(productId)
        ) {
          if (!foundTag) {
            delete item.drinking;
          } else if (!item.drinking && foundTag) {
            item.drinking = { ...this.placeholders.drinking };
          }
        }
      });
    });
    return newStatistics;
  }

  formatStatistics = (obj: Record<string, any>): PetStatisticsModel => {
    const data = cloneDeep(obj);
    const convertToRegularTime = (item: any) => {
      return item ? new Date(item).getTime() : undefined;
    };
    const convertTimeOutside = (time: string) => {
      return !time ? i18n.t('just_now') : time;
    };

    if (data.movement) {
      data.movement.time_outside = convertTimeOutside(data.movement.time_outside);
      data.movement.last_entry = convertToRegularTime(data.movement?.last_entry);
      (data.movement.activity as any[]).forEach((item, index) => {
        const elem = data.movement.activity[index];
        elem.time_outside = convertTimeOutside(item.time_outside);
      });
      data.movement.date = convertToRegularTime(data.movement.date);
      data.movement.since = convertToRegularTime(data.movement.since);
      data.movement.where = data.movement.where || PetPosition.none;
    }

    if (data.drinking) {
      data.drinking.last_consumption = convertToRegularTime(data.drinking.last_consumption);
    }
    if (data.feeding) {
      data.feeding.last_consumption = convertToRegularTime(data.feeding.last_consumption);
    }

    return data as unknown as PetStatisticsModel;
  };

  convertMovementDaysToHours(data: PetStatisticsModel[]): PetStatisticsModel[] {
    return data.map(stats => {
      return {
        ...stats,
        movement: {
          ...stats.movement,
          time_outside: this.getMovementDayDuration(stats.movement.time_outside),
          activity: stats.movement.activity.map(activity => {
            return {
              ...activity,
              time_outside: this.getMovementDayDuration(activity.time_outside),
            };
          }),
        },
      };
    });
  }

  getMovementDayDuration(duration: string): string {
    const isAFullDay = duration.startsWith('1.');
    return isAFullDay ? '24:00:00' : duration;
  }
}

export default new PetStatisticsService();
