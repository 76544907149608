import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import CountryFlag from 'react-native-country-flag-icon';

import useBoundStore from 'src/store/store';
import { useTranslation } from 'react-i18next';
import SpSearchList from '../../../components/SpSearchList';

type CountryType = {
  id: number;
  name: string;
  native_name: string;
  itemImage: JSX.Element;
};

interface HouseholdCountriesProps {
  handleChange: (countryId: number) => void;
  closeOnSelect?: boolean;
  handleClose?: () => void;
  selectedItemId?: number;
  withButton?: boolean;
}
const isIos = Platform.OS === 'ios';
const HouseholdCountriesWrapper = ({
  handleChange,
  closeOnSelect = false,
  handleClose,
  selectedItemId,
  withButton = false,
}: HouseholdCountriesProps) => {
  const { countries, getCountryByID } = useBoundStore(state => state.countryStore);
  const [selectedItem, setSelectedItem] = useState<number[]>(
    selectedItemId ? [selectedItemId] : [],
  );
  const [showButton, setShowButton] = useState(true);

  const { t } = useTranslation();

  const CountriesArr = useMemo(() => {
    return countries
      .map(({ native_name, name, id, code }) => {
        const newName = name || native_name;
        const itemImage = (
          <CountryFlag
            isoCode={code}
            size={15}
          />
        );
        return { id, name: newName, native_name, itemImage };
      })
      .sort((a: CountryType, b: CountryType) => {
        if (a?.name && b?.name) {
          return a.name.localeCompare(b.name);
        }
        if (!b?.native_name) {
          return a.native_name.localeCompare(b.name);
        }
        return a.name.localeCompare(b.name);
      });
  }, [countries]);

  const onChangeCountry = useCallback(
    (ids: number[]) => {
      // handleChange(getCountryByID(id).native_name || getCountryByID(id).name);
      if (!withButton) handleChange(ids[0]);
      setSelectedItem(ids);
      if (closeOnSelect) handleClose();
    },
    [withButton],
  );

  const handleBlur = () => {
    if (!isIos) {
      setShowButton(true);
    }
  };

  const handleFocus = () => {
    if (!isIos) {
      setShowButton(false);
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        dismissKeyboard();
      }}>
      <View style={styles.container}>
        <SpSearchList
          arr={CountriesArr}
          selectedItems={selectedItem}
          setItemId={onChangeCountry}
          hasInput
          withImage
          inputProps={{
            handleBlur,
            handleFocus,
          }}
        />

        {withButton && showButton && (
          <View style={styles.buttonWrap}>
            <SpRoundedHeaderButton
              disabled={selectedItem.length === 0}
              backgroundColor={colors.primary.color}
              h={56}
              title={t('save')}
              onPress={eve => {
                eve.preventDefault();
                handleChange(selectedItem[0]);
                handleClose();
              }}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  buttonWrap: {
    position: 'absolute',
    bottom: 44,
    width: '100%',
    paddingHorizontal: 20,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
});

export default HouseholdCountriesWrapper;
