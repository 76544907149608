import { SpButton } from 'src/components/SpButton';
import { SpErrorText } from 'src/components/SpErrorText';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { DevicePetDoorCurfew } from '@models/Device';
import { testProperties } from '@utils/testProperties';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import SpTextButton from 'src/components/SpTextButton';
import { parseISO } from 'date-fns';
import SpDatePicker from 'src/components/SpDatePicker';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useSingleCurfew } from '../../hooks/useSingleCurfew';
import CurfewTitle from '../../components/CurfewTitle';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';

interface SingleCurfewProps {
  data: DevicePetDoorCurfew;
  error: string;
  onChangeCurfew: (data: DevicePetDoorCurfew) => void;
}

export const SingleCurfew = ({ data, error, onChangeCurfew }: SingleCurfewProps) => {
  const { currentCurfew, curfewIsChanged, handleSubmit, setCurrentCurfew, saveCurfewAction } =
    useSingleCurfew(data, onChangeCurfew);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const userTimeFormat = useUserTimeUnits();
  const { timeToFormat } = useTimeTransform();
  const { t } = useTranslation();
  const isLongLang = useCurrentLanguageCode() === 'pt-br';

  const formatDate = (time: Date) => {
    const hours = time.getUTCHours().toString().padStart(2, '0');
    const minutes = time.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const timeForPicker = useMemo(() => {
    if (!selectedValue) return null;
    return parseISO(`2024-01-01T${selectedValue.time}:14.509Z`);
  }, [selectedValue]);

  const toggleCurfewAction = useCallback(() => {
    if (isEditable) {
      const result = {
        ...currentCurfew,
        enabled: !currentCurfew.enabled,
        closePopup: currentCurfew.enabled,
      };
      setCurrentCurfew(result);
    } else {
      saveCurfewAction(true);
    }
  }, [currentCurfew, isEditable]);

  return (
    <>
      <CurfewTitle
        disabled={false}
        enabled={currentCurfew?.enabled}
        toggleCurfewAction={toggleCurfewAction}
      />

      <SpView>
        <SpView
          alignItems="center"
          flex={1}
          width="100%"
          marginTop={32}
          paddingHorizontal={isLongLang ? 8 : 32}
          flexDirection="row"
          justifyContent="space-between"
          gap={8}
          style={{opacity: Number(currentCurfew?.enabled || isEditable) || 0.5}}>
          <SpView alignItems="center">
            <View {...testProperties('faLock', 'icon')}>
              <FontAwesomeIcon
                icon={faLock}
                color={colors.primary.color}
                size={17}
              />
            </View>
            <SpText
              style={{ marginTop: 4 }}
              fontFamily="Rubik"
              color={colors.black.color}>
              {t('lock_time')}
            </SpText>
          </SpView>
          <SpView
            {...testProperties('faUnlock', 'icon')}
            alignItems="center">
            <FontAwesomeIcon
              color={colors.primary.color}
              icon={faUnlock}
              size={17}
            />
            <SpText
              style={{ marginTop: 4 }}
              fontFamily="Rubik"
              color={colors.black.color}>
              {t('unlock_time')}
            </SpText>
          </SpView>
        </SpView>
        <SpView
          flex={1}
          marginTop={18}
          width="100%"
          style={{opacity: Number(currentCurfew?.enabled || isEditable) || 0.5}}>
          <SpView
            flexDirection="row"
            backgroundColor={!isEditable ? colors.secondary3.color : colors.lightGrey.color}
            flex={1}
            width="100%"
            justifyContent="space-between"
            paddingHorizontal={isLongLang ? 15 : 31}
            paddingVertical={12}
            borderRadius={16}
            gap={7}>
            <SpView
              flexDirection="row"
              alignItems="center">
              <SpTextButton
                disabled={!isEditable}
                textStyle={[
                  styles.textTime,
                  { fontSize: isLongLang ? 16 : 20 },
                  currentCurfew?.lock_time === selectedValue?.time
                    ? styles.textTimeSelected
                    : styles.textTimeDefault,
                ]}
                text={
                  currentCurfew?.lock_time
                    ? timeToFormat(currentCurfew.lock_time, userTimeFormat)
                    : ''
                }
                onPress={() => {
                  setSelectedValue({
                    isLockedTime: true,
                    time: currentCurfew.lock_time,
                  });
                }}
              />
              {isEditable && <SpView style={styles.triangle} />}
            </SpView>
            <SpView
              flexDirection="row"
              alignItems="center">
              <SpTextButton
                disabled={!isEditable}
                textStyle={[
                  styles.textTime,
                  { fontSize: isLongLang ? 16 : 20 },
                  currentCurfew?.unlock_time === selectedValue?.time
                    ? styles.textTimeSelected
                    : styles.textTimeDefault,
                ]}
                text={
                  currentCurfew?.unlock_time
                    ? timeToFormat(currentCurfew.unlock_time, userTimeFormat)
                    : ''
                }
                onPress={() => {
                  setSelectedValue({
                    isLockedTime: false,
                    time: currentCurfew.unlock_time,
                  });
                }}
              />
              {isEditable && <SpView style={styles.triangle} />}
            </SpView>
          </SpView>
        </SpView>

        {!isEditable && (
          <SpView
            alignItems="center"
            justifyContent="center">
            <SpButton
              style={styles.primaryButton}
              color="transparent"
              onPress={() => setIsEditable(true)}
              title={t('edit_times')}
            />
          </SpView>
        )}

        {isEditable && (
          <SpView width="100%">
            <SpRoundedHeaderButton
              h={56}
              backgroundColor={colors.primary.color}
              stylesForContainer={styles.saveButton}
              onPress={handleSubmit(() => saveCurfewAction())}
              title={t('save')}
              disabled={!curfewIsChanged}
            />
          </SpView>
        )}
      </SpView>

      {error && (
        <SpView marginTop={8}>
          <SpErrorText>{error}</SpErrorText>
        </SpView>
      )}

      {selectedValue && (
        <SpDatePicker
          modal
          mode="time"
          androidVariant="iosClone"
          testID="TimePicker"
          open
          date={timeForPicker}
          onConfirm={date => {
            setCurrentCurfew(
              selectedValue.isLockedTime
                ? { ...currentCurfew, lock_time: formatDate(date) }
                : { ...currentCurfew, unlock_time: formatDate(date) },
            );
            setSelectedValue(null);
          }}
          onCancel={() => {
            setSelectedValue(null);
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  column: {
    paddingHorizontal: 10,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '50%',
  },
  button: {
    marginTop: 8,
  },
  saveButton: {
    marginTop: 52,
  },
  primaryButton: {
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
    paddingHorizontal: 19,
    paddingVertical: 2,
    marginTop: 36,
  },
  primaryButtonText: {
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
    fontSize: 16,
  },
  largeMargin: {
    marginTop: 18,
  },
  smallMargin: {
    marginTop: 8,
    backgroundColor: 'red.500',
  },
  textTime: {
    fontFamily: 'Rubik',
  },
  textTimeDefault: {
    color: colors.black.color,
  },
  textTimeSelected: {
    color: colors.primary.color,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderRightWidth: 7,
    borderTopWidth: 7,
    borderLeftWidth: 7,
    borderRadius: 2,
    borderTopColor: colors.greyText.color,
    marginLeft: 5,
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});
