import i18n from '@utils/i18n';
import { useMemo } from 'react';

export const fastTimeConvertor = (hours: number, minutes: number, seconds = 0, minutesUnit = 2) => {
  let result = '';

  if (hours !== 0) {
    result += `${hours} ${i18n.t('hours_unit_1')}`;
  }
  if (minutes !== 0) {
    result += ` ${minutes} ${i18n.t(`minutes_unit_${minutesUnit}`)}`;
  }
  if (seconds !== 0) {
    result += `${seconds} ${i18n.t('seconds_unit_2')}`;
  }

  return result !== '' ? result.trim() : `${minutes} ${i18n.t('minutes_unit_2')}`;
};

const useTotalConsumption = (time: number | string, minutesUnit = 2) => {
  return useMemo(() => {
    if (typeof time === 'string') {
      const chunks = time.split(':');
      return fastTimeConvertor(+chunks[0], +chunks[1]);
    }
    const totalTime = time * 1000;
    const hours = Math.trunc(totalTime / 3600000);
    const minutes = Math.trunc((totalTime - hours * 3600000) / 60000);
    const seconds = hours === 0 && minutes === 0 ? totalTime / 1000 : 0;

    return fastTimeConvertor(hours, minutes, seconds, minutesUnit);
  }, [time]);
};

export default useTotalConsumption;
