/* eslint-disable react/jsx-no-useless-fragment */

import useBoundStore from '../store/store';
import SpSentIcon from './RoundIcons/SpSentIcon';
import SpErrorBadge from './SpErrorBadge';
import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import { SpVStack } from './SpVStack';
import { DeviceControlResponseStatus } from '@constants/Device';
import colors from '@styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { DateTime } from 'luxon';

interface ZeroScalesProps {
  isQuickAction?: boolean;
  loading: boolean;
  success: boolean;
  error: DeviceControlResponseStatus;
  lastZeroed: string;
  convertedLastZerod: string | number;
  localLoader: boolean;
}

export const SpZeroScalesUpdateStatusMessage = ({
  isQuickAction = false,
  loading,
  success,
  error,
  lastZeroed,
  convertedLastZerod,
  localLoader,
}: ZeroScalesProps) => {
  const { t } = useTranslation();
  const { loadingControl } = useBoundStore(s => s.deviceStore);
  const zeroedError = error && error === DeviceControlResponseStatus.DeviceError;
  const errorExist = error || zeroedError;
  const fiveMinsInMS = DateTime.now().minus({ minutes: 5 }).toMillis();
  const currDate = new Date(convertedLastZerod).getTime();
  const renderContent = () => {
    if (errorExist && !loadingControl.tare && !localLoader && currDate > fiveMinsInMS) {
      return zeroedError ? (
        <SpErrorBadge
          marginBottom={isQuickAction ? 0 : 32}
          marginTop={isQuickAction ? 0 : 10}
          text={t('zero_error_description')}
          title={t('zero_error_title')}
        />
      ) : (
        <SpErrorBadge
          marginBottom={isQuickAction ? 0 : 32}
          marginTop={isQuickAction ? 0 : 10}
          text={t('zero_error_general')}
        />
      );
    } else if (success && !loadingControl.tare && !localLoader && currDate > fiveMinsInMS) {
      return (
        <SpView style={styles.rowSuccessContainer}>
          <SpSentIcon />
          <SpView flex={1}>
            <SpText style={styles.successMsg}>{t('zero_command_sent')}</SpText>
            <SpText style={styles.successDesc}>{t('zero_command_sent_description')}</SpText>
          </SpView>
        </SpView>
      );
    } else if (!loading && !loadingControl.tare && !isQuickAction && !localLoader) {
      return (
        <>
          <SpText
            size="xl"
            align="center">
            {t('zero_bowl_title')}
          </SpText>
          <SpText
            size="sm"
            style={styles.description}
            align="center">
            {t('last_zeroed', { value: lastZeroed })}
          </SpText>
        </>
      );
    }
  };

  return (
    <SpVStack
      space={8}
      style={styles.wrapper}>
      {renderContent()}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
  description: {
    marginTop: 16,
    marginBottom: 16,
  },
  wrapper: {
    position: 'relative',
  },
  rowSuccessContainer: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  successMsg: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  successDesc: {
    marginTop: 4,
  },
});
