import { DateTimeMaybeValid } from 'luxon/src/datetime';

import { TimeService } from './TimeService';

export class ReportService {
  static getReportsTimezoneRequests(fromNumberDays: number, fromDate: Date): string[][] {
    const dates = [];
    const from = TimeService.parseDate(fromDate).minus({ days: fromNumberDays });
    const to = TimeService.parseDate(fromDate);

    if (fromNumberDays === 1) {
      dates.push([from.startOf('day').toUTC().toString(), to.endOf('day').toUTC().toString()]);
    } else {
      dates.push(...ReportService.getDatesInSameOffset(from.startOf('day'), to.endOf('day')));
    }

    return dates;
  }

  private static getDatesInSameOffset(
    startDate: DateTimeMaybeValid,
    endDate: DateTimeMaybeValid,
  ): string[][] {
    const range = TimeService.getDatesRange(startDate, endDate);
    let firstMatch = range[0];
    const results = [];

    for (let i = 0; i < range.length; i++) {
      if (i !== range.length - 1) {
        const day = TimeService.parseDate(range[i]).endOf('day');
        const nextDay = TimeService.parseDate(range[i + 1]).endOf('day');
        if (day.offset !== nextDay.offset) {
          results.push([
            TimeService.parseDate(firstMatch).plus({days: firstMatch === range[0] ? 0 : 1}).startOf('day').toString(),
            TimeService.parseDate(range[i]).plus({ days: 1 }).endOf('day').toString(),
          ]);
          firstMatch = range[i + 1];
        }
      } else {
        results.push([
          TimeService.parseDate(firstMatch).plus({ days: 1 }).startOf('day').toString(),
          TimeService.parseDate(range[i]).endOf('day').toString(),
        ]);
      }
    }

    return results;
  }
}
