import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { useDevicesSearchService } from '@hooks/useDeviceService';
import { StepFormStepsObj } from '@models/FormikTypes';
import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { navigateToProducts } from '@utils/navigationShortcuts';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Linking, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import EssenceCreated from 'src/components/EssenceCreated';
import { SpView } from 'src/components/SpView';
import StepFormCreator from 'src/components/StepFormCreator';
import NameForm from 'src/pages/Flows/Generic/NameForm';
import useValidation from 'src/pages/validation';
import useBoundStore from 'src/store/store';

import { FirstFill } from '../../Felaqua/FirstFill';
import { PlaceInLocation } from '../../Felaqua/PlaceInLocation';
import { PlacementInfo } from '../../Felaqua/PlacementInfo';
import AddProduct from '../../Generic/AddProduct/AddProduct';
import { BeforeYouContinue } from '../../Generic/BeforeYouContinue';
import { CompleteSetupProduct } from '../../Generic/CompleteSetupProduct';
import { FlowStackParamList } from '../../index';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddCatFlap'>;

enum Steps {
  FIT_BATTERY = 1,
  PLACE_INFO,
  PLACE_IN_LOCATION,
  PAIR,
  ENTER_NAME,
}

const AddFelaqua = ({ route }: Props) => {
  const [step, setStep] = useState(route?.params?.step || Steps.FIT_BATTERY);
  const [deviceIdentifier, setDeviceIdentifier] = useState(route?.params?.deviceId);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { deviceSearchService, connected, deviceName, otherDevices, maxRetriesError } =
    useDevicesSearchService(DeviceType.FelaquaConnect, setSelectedDevice);

  const { commonNameSchema } = useValidation();
  const [name, setName] = useState<string>('');
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<any>>();
  const { loadDevice, updateNameRequest, getDeviceById } = useBoundStore(s => s.deviceStore);
  const [submitValues, setSubmitValues] = useState<any>();
  const [lastFillTemplate, setLastFillTemplate] = useState(false);
  const [lastInstructionCarouselTemplate, setLastInstructionCarouselTemplate] = useState(false);
  const [numberTemplates, setNumberTemplates] = useState(0);
  const [tryTimes, setTryTimes] = useState(0);
  const isFocused = useIsFocused();

  useLayoutEffect(() => {
    if (isFocused){
      setStep(route?.params?.step || 1);
    }
  }, [route?.params?.step, isFocused]);

  useEffect(() => {
    (async () => {
      await loadDevice(true);
      if (!selectedDevice && deviceIdentifier) {
        setSelectedDevice(getDeviceById(deviceIdentifier));
      }
    })();
  }, [selectedDevice, deviceIdentifier]);

  useEffect(() => {
    setName(deviceName);
  }, [deviceName]);

  const appStateRef = useRef(false);

  useEffect(() => {
    if (AppState.currentState !== 'active') {
      deviceSearchService.stop();
      appStateRef.current = true;
    }

    if (appStateRef.current && step === 4 && !maxRetriesError) {
      deviceSearchService.start();
      appStateRef.current = false;
    }
  }, [AppState.currentState]);

  useEffect(() => {
    return () => {
      deviceSearchService?.stop();
    };
  }, []);

  useEffect(() => {
    if (step === Steps.PAIR && !maxRetriesError) {
      deviceSearchService?.start();
    }
    return () => {
      return deviceSearchService ? deviceSearchService.stop() : null;
    };
  }, [step, name]);

  const onFelaquaSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(selectedDevice?.id, {
          name: submitValues.name,
        });
        await loadDevice(true);
        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, selectedDevice],
  );

  const FirstFillWrapper = useCallback((children: JSX.Element) => {
    return <SpView>{children}</SpView>;
  }, []);

  const PlacementInfoWrapper = useCallback((children: JSX.Element) => {
    return <SpView flex={1}>{children}</SpView>;
  }, []);

  const tryAgain = () => {
    if (tryTimes > 1) {
      setStep(1);
      Linking.openURL(t('get_help_url'));
      return;
    }
    deviceSearchService.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(4);
    deviceSearchService.start();
  };

  const step4 = useMemo((): StepFormStepsObj => {
    return maxRetriesError
      ? {
          ui: (
            <AddProduct
              connected={connected}
              imageUrl={AppImages.pairFelaqua}
              searchMsg={t('felaqua_looking_mgs')}
              subtitle={t('felaqua_subtitle')}
              connectedMsg={maxRetriesError ? t('felaqua_not_found') : t('felaqua_connected_msg')}
              maxRetriesError={maxRetriesError}
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          buttonText: tryTimes > 1 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <View style={{ marginLeft: -10, flex: 1 }}>
              <AddProduct
                connected={connected}
                searchMsg={t('felaqua_looking_mgs')}
                subtitle={t('felaqua_subtitle')}
                connectedMsg={t('felaqua_connected_msg')}
                imageUrl={AppImages.pairFelaqua}
                maxRetriesError={maxRetriesError}
              />
            </View>
          ),
          hideButton: !connected,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          interceptor: () => {
            if (connected && otherDevices.length === 0) {
              return true;
            }
            deviceSearchService.start();
            return false;
          },
        };
  }, [maxRetriesError, connected, deviceSearchService, tryTimes]);

  const enableFirstFillContinue = (indexTemplate: number) => {
    if (!lastFillTemplate) {
      setLastFillTemplate(indexTemplate === numberTemplates - 1);
    }
  };

  const enableInstructionCarouselContinue = (indexTemplate: number) => {
    if (!lastInstructionCarouselTemplate) {
      setLastInstructionCarouselTemplate(indexTemplate === numberTemplates - 1);
    }
  };

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      leaveButtonAction={() => {
        deviceSearchService?.stop();
        navigation.navigate('DashboardNavigation', { screen: 'Pets' });
      }}
      buttonTestProperties={testProperties('Continue_RoundedButtonText', '')}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: <BeforeYouContinue productId={DeviceType.FelaquaConnect} />,
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: true,
            rightButtonText: t('get_help'),
          },
          backBottomButton: true,
        },
        2: {
          ui: <PlaceInLocation />,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
        },
        3: {
          ui: (
            <PlacementInfo
              enableInstructionCarouselContinue={enableInstructionCarouselContinue}
              setNumberItems={setNumberTemplates}
            />
          ),
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
          hideButton: !lastInstructionCarouselTemplate,
          wrap: PlacementInfoWrapper,
          interceptor: () => {
            setNumberTemplates(0);
            return true;
          },
        },
        4: step4,
        5: {
          ui: NameForm,
          props: {
            setName,
            label: t('product_name'),
            title: t('name_felaqua_title'),
            subtitle: t('subtitle {{productName}} {{example}}', {
              example: t('name_felaqua_example'),
              productName: t('felaqua_connect'),
            }),
            subtitle2: t('subtitle2'),
            disableKeyboardAwoiding: true,
            hideSubtitleOnFocus: false,
          },
          formik: { schema: commonNameSchema('Felaqua name'), names: ['name'] },
          customHeaderProps: {
            withLeaveButton: true,
          },
          keyboardAwoidingProps: {
            androidAdjustType: 'custom',
          },
          buttonDisabled: name === '',
        },
        6: {
          ui: (
            <CompleteSetupProduct
              productId={DeviceType.FelaquaConnect}
              onLoadedCallback={onFelaquaSubmit}
              deviceId={selectedDevice?.id}
              onPress={() => {
                navigation.navigate('AddPets', {
                  deviceId: selectedDevice?.id,
                  deviceType: DeviceType.FelaquaConnect,
                  step: 3,
                  returnToStep: step + 1,
                });
              }}
              disableAddBtn
            />
          ),
          invisibleHeader: true,
          hideButton: true,
          handleAndroidBackPress: () => {
            navigateToProducts();
            return true;
          },
        },
        7: {
          ui: (
            <FirstFill
              enableFirstFillContinue={enableFirstFillContinue}
              setNumberItems={setNumberTemplates}
            />
          ),
          wrap: FirstFillWrapper,
          hideButton: !lastFillTemplate,
        },
        8: {
          ui: (
            <View style={[styles.center, { marginTop: 112 }]}>
              <EssenceCreated
                msg={t('setup_complete')}
                initialValue
              />
            </View>
          ),
          interceptor: () => {
            navigateToProducts();
            return false;
          },
        },
      }}
    />
  );
};

export default AddFelaqua;

const styles = StyleSheet.create({
  container: { flex: 1 },
  stepWrapper: {
    marginTop: '40%',
  },
  formWrapper: { paddingHorizontal: 24 },
  errorWrapper: {
    height: 25,
  },
  center: {
    alignItems: 'center',
  },
  inputContainer: {
    paddingHorizontal: 16,
  },
});
