import * as Localization from 'expo-localization';
import React, { Suspense, useEffect, useState } from 'react';
import 'expo-dev-client';
import { applyInterceptors } from '@api/Http';
import { LogBox, Platform, StatusBar, StatusBarStyle, StyleSheet, UIManager } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Toast, { ToastConfig } from 'react-native-toast-message';
import SpToastIcon from 'src/components/RoundIcons/SpToastIcon';
import { SpCenter } from 'src/components/SpCenter';
import { SpText } from './src/components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import Navigation from 'src/pages';
import { navigationRef } from 'src/pages/RootNavigation';

import LoadingSpinner from './src/components/Loader/Loader';

import { RemoteConfigDefault } from '@constants/RemoteConfig';
import { useReactNavigationDevTools } from '@dev-plugins/react-navigation';
import {
  Rubik_400Regular,
  Rubik_500Medium,
  Rubik_600SemiBold,
  useFonts,
} from '@expo-google-fonts/rubik';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import useInAppMessage from '@hooks/useInAppMessage';
import { useIsExpoGoOrWeb } from '@hooks/useIsExpoGoOrWeb';
import { useReconfirmTermsAndConditions } from '@hooks/useReconfirmTermsAndConditions';
import { CommonActions } from '@react-navigation/native';
import colors from '@styles/colors';
import { init } from '@utils/i18n';
import * as SplashScreen from 'expo-splash-screen';
import { ClickOutsideProvider } from './src/providers/ClickOutsideProvider';
import { SpRemoteConfig } from './src/services/RemoteConfigService';
import { DdLogs, initDdSdkReactNative } from './src/services/SPDataDogService';
import useBoundStore from './src/store/store';

import { useAsyncStorageDevTools } from '@dev-plugins/async-storage';
import useCleanLoadingControlCache from '@hooks/useCleanLoadingControlCache';
import LogRocket from '@logrocket/react-native';
import { doOtaUpdate } from '@utils/otaUpdate';

const toastConfig: ToastConfig = {
  generalToast: ({ props }) => {
    return (
      <SpView style={styles.toastContainer}>
        <SpToastIcon isError={props.isError} />
        <SpText style={[styles.text, props.fontFamily]}>{props.description}</SpText>
      </SpView>
    );
  },
};

if (Platform.OS === "android" && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
 } 
SplashScreen.preventAutoHideAsync();
export default function App() {
  useReactNavigationDevTools(navigationRef);
  useAsyncStorageDevTools();
  // useReact;
  const [fontsLoaded] = useFonts({
    Rubik_Medium: Rubik_500Medium,
    Rubik_SemiBold: Rubik_600SemiBold,
    Rubik: Rubik_400Regular,
  });
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    LogBox.ignoreAllLogs(true);
    console.log('App is running');
    init();
    const debugUser = useBoundStore.getState().accountStore.user;

    async function prepare() {
      try {
        if (isExpoGoOrWeb || SpRemoteConfig === null) {
          console.log('App is running in Expo Go or Web');
          // @ts-ignore
          return;
        }
        await SpRemoteConfig()
          .setDefaults(RemoteConfigDefault)
          .then(() => SpRemoteConfig().fetchAndActivate());
        // await appInit();
      } catch (e) {
        console.warn(e);
      } finally {
        await initDdSdkReactNative(debugUser?.last_name || null);
        await DdLogs.info('App initialized');
        if (debugUser?.last_name === '*debug*') {
          LogRocket.init('i1cyg1/surepetcare-v4');
          LogRocket.identify(String(debugUser.id), {
            name: debugUser.first_name + ' ' + debugUser.last_name,
          });
        }
        setAppIsReady(true);
      }
    }

    applyInterceptors({
      getStoreOptions: () => {
        return {
          token: useBoundStore.getState().accountStore.token,
          // locale if user is logged in, otherwise default
          colour: useBoundStore.getState().accountStore.user?.use_colour,
          locale:
            useBoundStore
              .getState()
              .languageStore.getLanguageByID(
                useBoundStore.getState().accountStore.user?.language_id || 37,
              )?.code || 'en',
        };
      },
    });

    prepare()
      .then(() => {
        DdLogs.info('Checking for updates');

        //use number feature flag to determine if we should check for updates - 0 for no, 1 for yes but dont alert, 2 for yes and alert
        return doOtaUpdate();
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (appIsReady && fontsLoaded) {
      SplashScreen.hideAsync().then(() => {});
    }
  }, [appIsReady, fontsLoaded]);

  const isAuthenticated = useBoundStore((s) => s.accountStore.isAuthenticated());
  useInAppMessage();
  // const appInit = useAppInit();
  const isExpoGoOrWeb = useIsExpoGoOrWeb();

  //this is the code that handles showing a notification on android if its received when the app is in the foreground

  // useEffect(() => {
  //   const notificationRecievedSubscription =
  //     Notifications.addNotificationReceivedListener((notification: any) => {
  //       // return if we get a local notification or if we are on iOS (default behaviour works on apple)
  //       if (
  //         !notification ||
  //         !notification.request?.trigger.remoteMessage?.notification ||
  //         Device.brand === "Apple"
  //       )
  //         return;
  //
  //       // // if we get a remote notification, schedule it
  //       //
  //       // Notifications.scheduleNotificationAsync({
  //       //   content: {
  //       //     title:
  //       //       notification.request.trigger.remoteMessage.notification.title,
  //       //     body: notification.request.trigger.remoteMessage.notification.body,
  //       //     data: { data: notification.request.trigger.remoteMessage.data },
  //       //     sound: false,
  //       //   },
  //       //
  //       //   trigger: { seconds: 1 },
  //       // });
  //     });
  //
  //   return () => {
  //     notificationRecievedSubscription.remove();
  //   };
  // }, []);

  const { isTermsChanged, daysToExpire } = useReconfirmTermsAndConditions();
  useEffect(() => {
    if (navigationRef.isReady() && isAuthenticated && isTermsChanged) {
      setTimeout(() => {
        navigationRef.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [{ name: 'ReconfirmTermsAndConditions', params: { daysToExpire } }],
          }),
        );
      });
    }
  }, [isTermsChanged, isAuthenticated, daysToExpire, navigationRef.isReady()]);
  useCleanLoadingControlCache();
  if (!appIsReady) {
    console.log('App is not ready');
    // alert('App is not ready')
    return null;
  }
  return (
    <GestureHandlerRootView style={{ flex: 1 }} key={Localization.locale}>
      <BottomSheetModalProvider>
        <ClickOutsideProvider>
          <StatusBar barStyle={'dark-content' as StatusBarStyle} backgroundColor="#FFFFFF" />
          <Suspense
            fallback={
              <SpCenter style={styles.spinnerContainer}>
                <LoadingSpinner />
              </SpCenter>
            }>
            <Navigation />
            {/* <SpText>Loaded</SpText> */}
            <Toast config={toastConfig} />
          </Suspense>
        </ClickOutsideProvider>
      </BottomSheetModalProvider>
    </GestureHandlerRootView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  spinnerContainer: {
    height: '100%',
  },
  text: {
    textAlign: 'center',
    color: colors.white.color,
  },
  toastContainer: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    width: 300,
    minHeight: 88,
    paddingRight: 54,
    paddingLeft: 54,

    alignItems: 'center',
    justifyContent: 'center',
    top: 64,
    position: 'relative',
  },
});
