import { dismissKeyboard } from '@utils/keyboardDismiss';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import useBoundStore from 'src/store/store';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'react-native-country-flag-icon';
import SpSearchList from '../../../components/SpSearchList';
import { isEqual } from 'lodash-es';

interface AccountCountriesProps {
  handleChange: (countryId: number) => void;
  closeOnSelect?: boolean;
  handleClose?: () => void;
  selectedItemId?: number;
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

const AccountCountriesWrapper = ({
  handleChange,
  closeOnSelect = false,
  handleClose,
  selectedItemId,
  setButtonDisabled,
}: AccountCountriesProps) => {
  const prevValue = useRef(selectedItemId);
  const { countries, getCountryByID } = useBoundStore(state => state.countryStore);
  const [selectedItem, setSelectedItem] = useState<number[]>(
    selectedItemId ? [selectedItemId] : [],
  );
  const { t } = useTranslation();

  useLayoutEffect(() => {
    setButtonDisabled(true);
  }, []);

  const CountriesArr = useMemo(() => {
    return countries.map(({ native_name, name, id, code }) => {
      const newName = name || native_name;
      const itemImage = (
        <CountryFlag
          isoCode={code}
          size={15}
        />
      );
      return { id, name: newName, itemImage };
    });
  }, [countries]);

  const onChangeCountry = useCallback((ids: number[]) => {
    // handleChange(getCountryByID(id).native_name || getCountryByID(id).name);
    if (!isEqual(prevValue.current, ids[0])) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);

    handleChange(ids[0]);
    setSelectedItem(ids);
    if (closeOnSelect) handleClose();
  }, []);

  return (
    <TouchableWithoutFeedback onPress={() => dismissKeyboard()}>
      <View style={styles.container}>
        <SpSearchList
          arr={CountriesArr}
          selectedItems={selectedItem}
          setItemId={onChangeCountry}
          hasInput
          withImage
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

export default AccountCountriesWrapper;
