import { Dimensions, StyleSheet, Text, TextInput, View } from 'react-native';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitErrors } from '@models/FormikTypes';

import { FormikProps } from 'formik';
import { SpInput } from 'src/components/SpInput';
import colors from 'src/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { stepFormikValid } from '@utils/stepFormikValid';

import { useTranslation } from 'react-i18next';
import { emitContinueHandler } from 'src/components/StepFormCreator';
import { testProperties } from '@utils/testProperties';
import { regex } from '@utils/checkPassword';
import { SpText } from '../../../../components/SpText/SpText';

const passwordChecksArr = [
  '8_characters_long',
  'at_lease_1_digit',
  'at_least_1_lowercase_character',
  'at_least_1_uppercase_character',
  'at_least_1_special_character',
];

export interface FormValues {
  password: string;
  retypePassword: string;
  currentPassword: string;
}

interface IPasswordFormProps {
  formik: FormikProps<FormValues>;
  submitErrors?: SubmitErrors;
  isStep?: boolean;
  label?: string;
  placeholder?: string;
  children?: React.ReactNode;
  fromRegister?: boolean;
  onContinuePress?: () => Promise<void>;
}
const PASSWORD_CHECKS_NUM = 5;
const PasswordForm = ({
  formik,
  submitErrors,
  isStep = false,
  label = 'password',
  placeholder = 'type_password_again',
  children,
  fromRegister = false,
  onContinuePress,
}: IPasswordFormProps) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordChecks, setPasswordChecks] = useState<number[]>([]);
  const [startedTyping, setStartedTyping] = useState(false);
  const retypePasswordRef = useRef() as RefObject<TextInput>;

  const { t } = useTranslation();

  const passwordCheck = useCallback(
    (password: string) => {
      if (!password) {
        setPasswordStrength(0);
        setPasswordChecks([]);
        return;
      }

      setPasswordChecks([]);

      let passed = 0;
      // Password max length previously agreed with BEDS at 128
      if (password.length >= 8 && password.length < 128) {
        setPasswordChecks(prev => [...prev, 0]);
        passed += 1;
      }

      for (let i = 1; i < regex.length + 1; i += 1) {
        const isValidCheck = new RegExp(regex[i - 1]).test(password);
        if (isValidCheck) {
          passed += 1;
          setPasswordChecks(prev => [...prev, i]);
        }
      }

      setPasswordStrength(passed);
    },
    [formik.values, setPasswordStrength],
  );

  const progressBar = useMemo(() => {
    let strengthText: string;
    let color: string;
    let msg: string;

    const weak = () => {
      color = colors.errorRed.color;
      strengthText = t('weak');
      msg = t('keep_going');
    };

    const average = () => {
      color = colors.orangeWarning.color;
      strengthText = t('average');
      msg = t('getting_there');
    };

    switch (passwordStrength) {
      case 0:
        weak();
        break;
      case 1:
        weak();
        break;
      case 2:
        weak();
        break;
      case 3:
        average();
        break;
      case 4:
        average();
        break;
      case 5:
        color = colors.toxicGreen.color;
        strengthText = t('strong');
        msg = t('well_done');
        break;
    }

    return (
      <View accessible={false}>
        <Text
          {...testProperties(strengthText, 'text')}
          style={styles.passwordStrengthText}>
          {t('{{strengthText}} password', { strengthText })}
        </Text>
        <View
          {...testProperties('view', 'progress bar')}
          style={styles.passwordStrengthWrap}>
          {Array(PASSWORD_CHECKS_NUM)
            .fill('')
            .map((_, index) => {
              return (
                <View
                  {...testProperties(`${index}_point`, 'progress bar')}
                  key={index}
                  style={[
                    styles.passwordStrengthCell,
                    index < passwordStrength && { backgroundColor: color },
                  ]}
                />
              );
            })}
          {startedTyping && (
            <SpText
              numberOfLines={2}
              style={styles.progressMsg}>
              {msg}
            </SpText>
          )}
        </View>
      </View>
    );
  }, [passwordStrength, startedTyping]);

  const progressChecks = useMemo(() => {
    return Array(PASSWORD_CHECKS_NUM)
      .fill('')
      .map((_, index) => {
        const active = passwordChecks.includes(index);
        return (
          <View
          accessible={false}
            key={index}
            style={styles.passwordCheckItem}>
            <View
              style={[
                styles.passwordCheckIcon,
                active ? styles.passwordCheckActiveCircle : styles.passwordCheckCircle,
              ]}>
              {active && (
                <View {...testProperties(`${index}_faCheck`, 'icon')}>
                  <FontAwesomeIcon
                    color={colors.white.color}
                    size={11}
                    icon={faCheck}
                  />
                </View>
              )}
            </View>
            <Text
              {...testProperties(t(passwordChecksArr[index]), 'text')}
              style={[
                styles.passwordCheckMessage,
                active ? styles.passwordCheckActiveText : styles.passwordCheckText,
              ]}>
              {t(passwordChecksArr[index])}
            </Text>
          </View>
        );
      });
  }, [passwordChecks, t]);

  const passwordError = useMemo(() => {
    if (isStep) {
      return stepFormikValid('password', submitErrors, formik.errors);
    }
    return t(formik.errors.password);
  }, [formik.errors, submitErrors, isStep, t]);

  const retypePasswordError = useMemo(() => {
    if (isStep) {
      return stepFormikValid('retypePassword', submitErrors, formik.errors);
    }
    return t(formik.errors.retypePassword);
  }, [formik.errors, submitErrors, isStep, t]);

  const actualPassword = formik.values?.password || '';
  useEffect(() => {
    if (actualPassword) {
      setStartedTyping(true);
    }
  }, [actualPassword]);

  const registerFormHeight =
    Dimensions.get('screen').height - (Dimensions.get('screen').height * 30.25) / 100;

  return (
    <View
    accessible={false}
      style={
        fromRegister
          ? {
              height: registerFormHeight,
              flex: 1,
              justifyContent: 'space-between',
            }
          : styles.container
      }>
      <View accessible={false}>
        <SpInput
          label={t(label)}
          value={actualPassword}
          onChangeText={password => {
            formik.handleChange('password')(password);
            if (password) {
              formik.setTouched({ ...formik.touched, password: true });
            }
            passwordCheck(password);
          }}
          placeholder={t(placeholder)}
          password
          error={t(passwordError)}
          returnKeyType="next"
          onSubmitEditing={() => {
            retypePasswordRef?.current?.focus();
          }}
          additionalIconTestLabel={t(label)}
        />
        {progressBar}
        <View accessible={false} style={styles.passwordCheckWrap}>{progressChecks}</View>
        <View accessible={false} style={{ paddingBottom: 30 }}>
          <SpInput
            label={t('retype_password')}
            value={formik.values?.retypePassword || ''}
            onChangeText={password => {
              formik.handleChange('retypePassword')(password);
              if (password) formik.setTouched({ ...formik.touched, retypePassword: true });
            }}
            password
            error={t(retypePasswordError)}
            ref={retypePasswordRef}
            returnKeyType="done"
            onSubmitEditing={onContinuePress}
            additionalIconTestLabel={t('retype_password')}
          />
        </View>
      </View>
      {fromRegister && children}
    </View>
  );
};

export default PasswordForm;

const styles = StyleSheet.create({
  container: {
    marginBottom: 100,
  },
  passwordStrengthWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  passwordStrengthText: {
    color: colors.greyText.color,
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    marginTop: 26, // 16
  },
  progressMsg: {
    color: colors.greyText.color,
    fontFamily: 'Rubik',
    fontSize: 12,
    marginLeft: 5,
    width: '35%',
  },
  passwordStrengthCell: {
    marginRight: 8,
    height: 4,
    width: 40,
    borderRadius: 2,
    backgroundColor: 'rgba(38, 58, 67, 0.15)',
  },
  passwordStrengthSubtext: { color: 'darkgrey', fontSize: 14 },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'darkgrey',
    marginBottom: 5,
  },
  error: {
    fontSize: 12,
    color: 'red',
    textAlign: 'center',
  },
  errorWrapper: {
    height: 16,
    marginTop: 5,
  },
  passwordCheckItem: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  passwordCheckActiveCircle: {
    width: 16,
    height: 16,
  },
  passwordCheckCircle: {
    marginLeft: 4,
    marginRight: 4,
    width: 8,
    height: 8,
  },
  passwordCheckIcon: {
    backgroundColor: colors.primary.color,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  passwordCheckMessage: {
    marginLeft: 8,
    fontFamily: 'Rubik',
    fontSize: 14,
  },
  passwordCheckActiveText: {
    color: 'rgba(3,162,177, 0.5)',
  },
  passwordCheckText: {
    color: colors.greyText.color,
  },
  passwordCheckWrap: {
    marginTop: 16,
    marginBottom: 28,
  },
});
