import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { useGetAllPetReports } from '@hooks/useGetAllPetReports';
import { MappedReportModel } from '@models/ReportModel';
import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import React, { useCallback, useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SpLoading } from 'src/components/SpLoading';

import { Env } from '../../../../Env';
import { TimeService } from '../../../services/TimeService';
import useBoundStore from '../../../store/store';
import { PetStackParamList } from '../Pets/pages';
import { ReportDashboard } from '../Pets/pages/PetDashboardReport/ReportDashboard';
import { PDFReportActions } from '../constants/PDFReport';
import { ReportRangeType } from '../constants/ReportRangeType';
import { ReportType } from '../constants/ReportType';
import { usePetDashboardReportFilterData } from '../hooks/usePetDashboardReportFilterData';

export interface ReportWrapperFilter {
  viewedDays: number;
  currentDate: any;
}

export const PetDashboardReportWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetDashboardReport'>) => {
  const [filter, setFilter] = useState<ReportWrapperFilter>({
    currentDate: route.params.currentDate
      ? TimeService.toLocal(route.params?.currentDate).toJSDate()
      : TimeService.toLocal().toJSDate(),
    viewedDays: route.params.viewedDays || ReportRangeType.SevenDays,
  });
  const [data, setData] = useState<MappedReportModel>(null);
  const [type, setType] = useState<ReportType>(null);
  const household = useBoundStore(s => s.householdStore.activeHousehold);
  const token = useBoundStore(s => s.accountStore.token);
  const { activePet, petInsights } = useBoundStore(state => state.petStore);
  const { loadDetailsReport, loading } = useBoundStore(s => s.reportStore);

  const devices = useDevicesByHousehold();
  const types = useGetAllPetReports(activePet) as ReportType[];
  const filteredData = usePetDashboardReportFilterData(data, filter);

  const loadReport = useCallback(
    async (days: number, date: any) => {
      const values = await loadDetailsReport(household?.id, activePet?.id, days, date);
      setData(values);
    },
    [household?.id, activePet?.id],
  );

  const onSetFilter = (payload: ReportWrapperFilter) => {
    setData({
      movement: [],
      feeding: [],
      drinking: [],
    });
    setFilter(payload);
    loadReport(payload.viewedDays, payload.currentDate);
  };

  useEffect(() => {
    loadReport(filter.viewedDays, filter.currentDate);
  }, []);

  useEffect(() => {
    if (!type && types.length) {
      setType(route.params?.type || types[0]);
    }
  }, [route.params?.type]);

  const generateReport = useCallback(() => {
    switch (Env.APP_BUNDLE_ID) {
      // case 'com.sureflap.surehub.devstable':
      //   Linking.openURL(
      //     `https://reports.devstable.surehub.io/reports/${household?.id}/${pet?.id}/${PDFReportActions[type]}?token=${token}`,
      //   ).then();
      //   break;
      default:
        Linking.openURL(
          `https://master.d3cdm3x7bnatip.amplifyapp.com/reports/${household?.id}/${activePet?.id}/${PDFReportActions[type]}?token=${token}`,
        ).then();
        break;
    }
  }, [household, token, activePet, type]);

  if (!data && (loading || !type)) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.white.color }}>
      <ReportDashboard
        pet={activePet}
        types={types}
        devices={devices}
        stats={filteredData}
        type={type}
        petInsights={petInsights}
        onChangeType={setType}
        currentDate={filter.currentDate}
        setFilter={onSetFilter}
        viewedDays={filter.viewedDays}
        initialActiveTab={route.params.tab}
        onGenerateReport={generateReport}
      />
    </SafeAreaView>
  );
};
