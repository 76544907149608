/* eslint-disable consistent-return */
import { useTimeTransform } from '@hooks/useTimeTransform';
import { DeviceCatFlapCurfew } from '@models/Device';
import { addHours, addMinutes, format, parse } from 'date-fns';
import { cloneDeep, last } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { getLocalTime } from 'src/services/TimeService';

import { TimeValue } from '../components/PetDoorCatFlapDevice/CurfewTimeItem';

export const useMultipleCurfew = (
  data: DeviceCatFlapCurfew[],
  onChangeCurfew: (data: DeviceCatFlapCurfew[]) => void,
) => {
  const { to24H } = useTimeTransform();
  const [enabled, setEnabled] = useState<boolean>(undefined);
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
  });
  const { fields, append, remove, update, replace } = useFieldArray({
    name: 'times',
    control,
  });
  const typedFields = fields as unknown as TimeValue[];

  useEffect(() => {
    // TODO Get format from user
    const defaultCurfew = new DeviceCatFlapCurfew({
      lock_time: '12:00',
      unlock_time: '12:01',
    });
    const curfews = data?.length
      ? cloneDeep(data).map(data => ({
          ...data,
          lock_time: getLocalTime(data.lock_time),
          unlock_time: getLocalTime(data.unlock_time),
        }))
      : [defaultCurfew];

    setEnabled(curfews.some(item => item.enabled));
    replace(curfews);
  }, [data]);
  useEffect(() => {
    const hasEnabled = typedFields.some(obj => obj.enabled === true);
    setEnabled(hasEnabled);
  }, [typedFields]);

  const saveCurfewAction = (force = false, closePopup = true) => {
    const curfewsToSave = typedFields.map(item => {
      let result;
      let unlockTime = item.unlock_time;
      if (force) {
        result = !enabled;
      } else {
        result = enabled && item.enabled;
      }
      if (item.unlock_time === item.lock_time) {
        const addMinute = addMinutes(parse(to24H(item.unlock_time), 'HH:mm', new Date()), 1);
        unlockTime = format(addMinute, 'HH:mm');
      }

      return {
        ...item,
        lock_time: to24H(item.lock_time),
        unlock_time: to24H(unlockTime),
        enabled: result,
        closePopup,
      };
    });
    onChangeCurfew(curfewsToSave as any);
  };

  const toggleCurfewAction = () => {
    setEnabled(!enabled);
  };
  const addNewCurfewAction = () => {
    const newCurfew: TimeValue = cloneDeep(last(typedFields));
    const lockMoment = addMinutes(parse(to24H(newCurfew.unlock_time), 'HH:mm', new Date()), 5);

    newCurfew.lock_time = format(lockMoment, 'HH:mm');
    newCurfew.unlock_time = format(addHours(lockMoment, 1), 'HH:mm');
    newCurfew.enabled = true;

    append(newCurfew);
    setEnabled(true);
  };
  const changeCurfewAction = (id: number) => {
    const clone = cloneDeep(typedFields);
    clone[id].enabled = !clone[id].enabled;
    update(id, clone[id]);
  };

  return {
    addNewCurfewAction,
    control,
    toggleCurfewAction,
    remove,
    handleSubmit,
    saveCurfewAction,
    enabled,
    fields: typedFields,
    update,
    replace,
    changeCurfewAction,
  };
};
