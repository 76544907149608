import { SpText } from './SpText/SpText';
import { SpVStack } from './SpVStack';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { QuickActionButton } from '@constants/Navigation';
import { SpRoundIcon } from './RoundIcons/SpRoundIcon';
import { testProperties } from '@utils/testProperties';
import LoadingSpinner from './Loader/Loader';

interface LockActionsProps {
  buttons: QuickActionButton[];
  textStyle?: React.CSSProperties;
  onSelect: (data: any) => void;
  disabled?: boolean;
  loading?: boolean;
  setPetPositionLoader?: (data: boolean) => void;
}

export const SpLockUnlockActions = ({
  buttons,
  textStyle = null,
  onSelect,
  disabled = false,
  loading = false,
  setPetPositionLoader,
}: LockActionsProps) => {
  const [innerLoader, setInnerLoader] = useState(false);

  useEffect(() => {
    if (innerLoader && loading) {
      setInnerLoader(false);
      setPetPositionLoader?.(false);
    }
  }, [loading, innerLoader, setPetPositionLoader]);

  const onPressHandler = id => {
    setInnerLoader(true);
    setPetPositionLoader?.(true);
    onSelect(id);
  };

  return (
    <SpVStack
      space={10}
      width="100%"
    >
      {buttons.map((item, index) => {
        return (
          <Pressable
            onPress={() => onPressHandler(item?.id)}
            key={`${item.text}${index}`}
            {...testProperties('button', `lockButton${index}`)}
            style={styles.stack}
            disabled={disabled}
          >
            {loading || innerLoader ? (
              <>
                <SpText style={[styles.movementTitle, textStyle]}>{item.text}</SpText>
                <LoadingSpinner />
              </>
            ) : (
              <>
                <SpText style={[styles.movementTitle, textStyle]}>{item.text}</SpText>
                <SpRoundIcon
                  icon={item.icon}
                  size={12}
                  color={colors.secondary2.color}
                  action={() => onSelect(item?.id)}
                />
              </>
            )}
          </Pressable>
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    padding: 20,
    flexDirection: 'row',
  },
  image: {
    height: 36,
    width: 36,
  },
  movementTitle: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
    width: '85%',
  },
  movementDuration: {
    fontFamily: 'Rubik',
    fontSize: 16,
    colors: colors.greyText.color,
    maxWidth: 190,
  },
  stack: {
    backgroundColor: colors.secondary3.color,
    borderRadius: 16,
    paddingHorizontal: 20,
    paddingVertical: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});
