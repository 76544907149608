import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { MenuItem } from '@models/Menu';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import LoadingSpinner from './Loader/Loader';


const SpMenuItem = ({ label, action, icon, iconSVG, isLoading, disabled }: MenuItem) => {
  return (

    <Pressable disabled={isLoading} accessible={false} style={styles.container} onPress={action}>
      <View style={[styles.contentWrap, disabled && { opacity: 0.5 }]}>
        {icon && (
          <View {...testProperties(label, 'icon')}>
            <FontAwesomeIcon color={colors.greyText.color} icon={icon} size={18} />
          </View>
        )}
        {iconSVG && (
          <View
            {...testProperties(label, 'icon')}
            style={styles.iconSVG}>
            {iconSVG}
          </View>
        )}
        <Text
          {...testProperties(label, 'text')}
          style={styles.label}>
          {label}
        </Text>
      </View>
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <View
          {...testProperties('faAngleRight', 'icon', label)}
          style={[disabled && { opacity: 0.5 }]}>
          <FontAwesomeIcon color={colors.greyText.color} size={25} icon={faAngleRight} />
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  spinnerWrapper: {
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 12,
    borderColor: colors.greyBorder.color,
    height: 56,
    borderWidth: 1,
    paddingLeft: 24,
    paddingRight: 19,
  },
  contentWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconSVG: { alignItems: 'center', justifyContent: 'space-between' },
  label: {
    fontSize: 16,
    fontFamily: 'Rubik',
    lineHeight: 24,
    marginLeft: 16,
  },
});

export default SpMenuItem;
