/* eslint-disable react/jsx-no-useless-fragment */
import { SpHouseIcon } from 'src/components/RoundIcons/SpHouseIcon';
import { SpHStack } from 'src/components/SpHStack';
import { SpText } from '../../../components/SpText/SpText';
import { SpVStack } from 'src/components/SpVStack';
import { PetPosition } from '@constants/Pet';
import { PetLocationIcons } from '@constants/PetLocationIcons';
import colors from '@styles/colors';
import { SpTreesIcon } from 'src/components/RoundIcons/SpTreesIcon';
import { SpCenter } from 'src/components/SpCenter';
import text from '@styles/text';
import { getSingleDuration } from '@utils/date';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

const CONTAINER_VERTICAL_PADDING = Math.min((Dimensions.get('screen').height / 100) * 1.6, 16);
interface PetMovementNotificationProps {
  locationSince: string;
  location: PetPosition;
  setIsModalOpen: (modalOpen: boolean) => void;

  viewStyle?: 'normal' | 'compact' | 'subCompact' | 'ultraSubCompact';
}

export const PetMovementNotification = ({
  locationSince,
  location,
  viewStyle = 'normal',
  setIsModalOpen,
}: PetMovementNotificationProps) => {
  const { t } = useTranslation();
  const PetIcons = PetLocationIcons;
  switch (viewStyle) {
    case 'ultraSubCompact':
      return locationSince ? (
        <SpHStack space={5} alignContent="center" alignItems="center" justifyContent="center">
          <SpText style={[styles.movementTitle, { fontSize: 16 }]}>
            {t(PetPosition[location])}
          </SpText>
          <SpText
            style={[styles.movementDuration, { fontSize: 14, color: colors.greyTitle.color }]}>
            {getSingleDuration(locationSince, true, false)}
          </SpText>
        </SpHStack>
      ) : (
        <TouchableOpacity disabled={!setIsModalOpen} onPress={() => setIsModalOpen(true)}>
          <SpCenter
            style={[
              styles.unknownContainer,
              { paddingHorizontal: 4, paddingVertical: 4, borderRadius: 8 },
            ]}>
            <SpText style={[{ fontSize: 12 }, styles.unknownText]}>{t('location_unknown')}</SpText>
          </SpCenter>
        </TouchableOpacity>
      );
    case 'subCompact':
      return locationSince ? (
        <SpVStack space={0.5} alignContent="center" alignItems="center" justifyContent="center">
          <SpText style={[styles.movementTitle, { fontSize: 16 }]}>
            {t(PetPosition[location])}
          </SpText>
          <SpText
            style={[styles.movementDuration, { fontSize: 14, color: colors.greyTitle.color }]}>
            {getSingleDuration(locationSince, true, false)}
          </SpText>
        </SpVStack>
      ) : (
        <TouchableOpacity disabled={!setIsModalOpen} onPress={() => setIsModalOpen(true)}>
          <SpCenter
            style={[
              styles.unknownContainer,
              { paddingHorizontal: 4, paddingVertical: 4, borderRadius: 8 },
            ]}>
            <SpText style={[{ fontSize: 12 }, styles.unknownText]}>{t('location_unknown')}</SpText>
          </SpCenter>
        </TouchableOpacity>
      );
    case 'compact':
      return locationSince ? (
        <TouchableOpacity disabled={!setIsModalOpen} onPress={() => setIsModalOpen(true)}>
          <SpHStack
            backgroundColor={colors.secondary4.color}
            borderRadius={16}
            paddingHorizontal={18}
            paddingVertical={16}
            alignItems="center"
            justifyContent="space-between"
            style={[
              styles.generalContainer,
              { paddingHorizontal: 12 },
              Dimensions.get('screen').height < 700 && { paddingVertical: 5 },
            ]}
            space={2}>
            <SpVStack space={2}>
              <SpText style={[styles.movementTitle, { fontSize: 16 }]}>
                {t(PetPosition[location])}
              </SpText>
              <SpText style={[styles.movementDuration, { fontSize: 14 }]}>
                {getSingleDuration(locationSince, true, false)}
              </SpText>
            </SpVStack>
            {PetIcons[location]({
              size: 8,
              iconSize: 18,
              action: setIsModalOpen ? () => setIsModalOpen(true) : undefined,
            })}
          </SpHStack>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity disabled={!setIsModalOpen} onPress={() => setIsModalOpen(true)}>
          <SpCenter style={[styles.generalContainer, styles.unknownContainer]}>
            <SpText style={[text.smallTitle, styles.unknownText]}>{t('location_unknown')}</SpText>
          </SpCenter>
        </TouchableOpacity>
      );
    case 'normal':
      return locationSince ? (
        <TouchableOpacity disabled={!setIsModalOpen} onPress={() => setIsModalOpen(true)}>
          <SpHStack
            backgroundColor={colors.secondary4.color}
            borderRadius={16}
            paddingHorizontal={20}
            paddingVertical={16}
            alignItems="center"
            justifyContent="space-between"
            style={styles.generalContainer}
            space={8}>
            <SpVStack space={2}>
              <SpText style={styles.movementTitle}>{t(PetPosition[location])}</SpText>
              <SpText style={styles.movementDuration}>
                {getSingleDuration(locationSince, true)}
              </SpText>
            </SpVStack>
            {PetIcons[location]({
              action: setIsModalOpen ? () => setIsModalOpen(true) : undefined,
            })}
          </SpHStack>
        </TouchableOpacity>
      ) : (
        <SpCenter style={[styles.generalContainer, styles.unknownContainer]}>
          <SpText style={[text.smallTitle, styles.unknownText]}>{t('location_unknown')}</SpText>
        </SpCenter>
      );
  }
};

const styles = StyleSheet.create({
  movementTitle: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
  },
  movementDuration: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    maxWidth: 190,
  },
  subCompactGeneralContainer: {
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  generalContainer: {
    borderRadius: 16,
    paddingHorizontal: 20,
    paddingVertical: CONTAINER_VERTICAL_PADDING,
  },
  unknownText: {
    color: colors.lightBlack.color,
  },
  unknownContainer: {
    backgroundColor: colors.greyInput.color,
    paddingVertical: 28,
  },
  subCompactUnknownContainer: {
    backgroundColor: colors.greyInput.color,
    paddingVertical: 4,
  },
});
