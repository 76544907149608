import { SpLoading } from 'src/components/SpLoading';
import { useDeviceById } from '@hooks/useDeviceById';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { DeviceFeederBowlModel, DeviceFeederControlModel } from '@models/Device';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import SpAlert from '@utils/alert';
import i18n from '@utils/i18n';
import { cloneDeep } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';
import useToastEffect from '@hooks/useToastEffect';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { BowlPortioning } from '../../components/DevicePortioning/BowlPortioning';
import { DeviceSettingContainer } from '../../components/DeviceSettingContainer';
import { useCustomToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

export const DevicePortioning = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DevicePortioning'>) => {
  const { id } = route.params;
  const device = useDeviceById(id);
  const toast = useCustomToast();
  const { t } = useTranslation();

  const { loadingDevice, updateTarget, updateTargetAsync } = useBoundStore(
    (state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        updateTarget: deviceStore.updateTarget,
        updateTargetAsync: deviceStore.updateTargetAsync,
      };
    },
  );
  const userWeightUnits = useUserWeightUnits();
  const navigation = useNavigation();

  const changeTargetAction = useCallback(
    async ($event: number[]) => {
      const bowls: DeviceFeederBowlModel = cloneDeep(
        (device.control as DeviceFeederControlModel).bowls,
      );

      const [leftOrSingle, right] = $event;
      bowls.settings[0].target = leftOrSingle;
      if (right) bowls.settings[1].target = right;

      const result = await updateTargetAsync(device.id, bowls);
      if (result) {
        toast.show({ description: t('success_portion_update') });
      } else toast.show({ description: t('error_portion_update') });
      navigation.navigate('DevicePortioning', { id: device.id });
    },
    [device, updateTarget],
  );

  const removeAction = useCallback(() => {
    SpAlert(
      i18n.t('confirm_portioning_change_title'),
      i18n.t('confirm_portioning_change_description'),
      [
        {
          text: i18n.t('cancel'),
          onPress: () => true,
        },
        {
          text: i18n.t('remove'),
          onPress: () => {
            const bowls: DeviceFeederBowlModel = cloneDeep(
              (device.control as DeviceFeederControlModel).bowls,
            );
            updateTarget(device.id, {
              ...bowls,
              settings: bowls.settings.map(item => ({ ...item, target: 0 })),
            });
            return true;
          },
          style: 'destructive',
        },
      ],
    );
  }, [device]);

  if (loadingDevice || !device) {
    return <SpLoading />;
  }

  return (
    <DeviceSettingContainer>
      <BowlPortioning
        data={(device?.control as DeviceFeederControlModel).bowls}
        userWeightUnits={userWeightUnits}
        onChangeTarget={changeTargetAction}
        onRemove={removeAction}
      />
    </DeviceSettingContainer>
  );
};
