/* eslint-disable react/no-unstable-nested-components */
import { TimelineEventType } from '@constants/Timeline';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import { SpCenter } from 'src/components/SpCenter';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

import { CurfewLockStatus } from './components/CurfewLockStatus';
import { DoorLockingMode } from './components/DoorLockingMode';
import { Feeding } from './components/Feeding';
import { IntruderMovement } from './components/IntruderMovement';
import { Movement } from './components/Movement';
import { NewDevice } from './components/NewDevice';
import { NewTag } from './components/NewTag';
import { PetName } from './components/PetName';
import { PetPermissionsChanged } from './components/PetPermissionsChanged';
import { PoseidonDrinking } from './components/PoseidonDrinking';
import { PoseidonLowWater } from './components/PoseidonLowWater';
import { PoseidonWaterFreshness } from './components/PoseidonWaterFreshness';
import { Tare } from './components/Tare';
import { TargetWeightSet } from './components/TargetWeightSet';
import { TimelineCard } from './components/TimelineCard';
import TimelineDevicePhoto from './components/TimelineDevicePhoto';
import { TimelineUserPhoto } from './components/TimelineUserPhoto';
import { TrainingMode } from './components/TrainingMode';
import { WaterRemoved } from './components/WaterRemoved';
import { WeightChanged } from './components/WeightChanged';
import LoadingSpinner from '../../../components/Loader/Loader';
import { getTimelineDeviceName } from '../hooks/useTimelineDeviceName';
import DeviceBackOnline from './components/DeviceBackOnline';
import TaringRequired from './components/TaringRequired';
import TarringOccurred from './components/TarringOccured';

interface TimelineProps {
  data: TimelineEventModel[];
  refreshing: boolean;
  lazyLoading: boolean;
  onRefresh: () => void;
  onEndReached: () => void;
}

export const Timeline = ({
  data,
  refreshing,
  lazyLoading,
  onRefresh,
  onEndReached,
}: TimelineProps) => {
  const { t } = useTranslation();
  const renderFooter = useCallback(() => {
    return (
      <SpView style={styles.footer}>{lazyLoading ? <LoadingSpinner size="small" /> : null}</SpView>
    );
  }, [lazyLoading, t]);

  const renderBlock = (event: TimelineEventModel, active: boolean) => {
    switch (event.type) {
      case TimelineEventType.Movement:
        return (
          <Movement
            active={active}
            event={event}
          />
        );
      case TimelineEventType.PoseidonWeightChanged:
        return (
          <WeightChanged
            active={active}
            event={event}
          />
        );
      case TimelineEventType.WeightChanged:
        return (
          <WeightChanged
            active={active}
            event={event}
          />
        );
      case TimelineEventType.LowBattery:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_low_battery', {
              devicename: getTimelineDeviceName(event.devices),
            })}
            date={event.created_at}
            image={
              <TimelineDevicePhoto
                active={active}
                event={event}
              />
            }
          />
        );
      case TimelineEventType.NewTag:
        return (
          <NewTag
            active={active}
            event={event}
          />
        );
      case TimelineEventType.NewDevice:
        return (
          <NewDevice
            active={active}
            event={event}
          />
        );
      case TimelineEventType.DoorLockingMode:
        return (
          <DoorLockingMode
            active={active}
            event={event}
          />
        );
      case TimelineEventType.IntruderMovement:
        return (
          <IntruderMovement
            active={active}
            event={event}
          />
        );
      case TimelineEventType.UserJoinedHousehold:
        return (
          <TimelineCard
            active={active}
            image={
              <TimelineUserPhoto
                showPhoto
                active={active}
                event={event}
              />
            }
            text={t('timeline_user_join_household', {
              user: get(event, 'users[0].name', ''),
              household: get(event, 'households[0].name', ''),
            })}
            date={event.created_at}
          />
        );
      case TimelineEventType.NewPet:
        return (
          <PetName
            active={active}
            event={event}
          />
        );
      case TimelineEventType.AccountCreated:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_account_created')}
            date={event.created_at}
            image={
              <TimelineUserPhoto
                showPhoto
                active={active}
                event={event}
              />
            }
          />
        );
      case TimelineEventType.DeviceOnline:
        return (
          <DeviceBackOnline
          active={active}
          event={event}
          />
        )
      case TimelineEventType.CurfewLockStatus:
        return (
          <CurfewLockStatus
            active={active}
            event={event}
          />
        );
      case TimelineEventType.PoseidonDrinking:
        return (
          <PoseidonDrinking
            active={active}
            event={event}
          />
        );
      case TimelineEventType.Feeding:
        return (
          <Feeding
            active={active}
            event={event}
          />
        );
      case TimelineEventType.TargetWeightSet:
        return (
          <TargetWeightSet
            active={active}
            event={event}
          />
        );
      case TimelineEventType.Tare:
        return (
          <Tare
            active={active}
            event={event}
          />
        );
      case TimelineEventType.PetPermissionsChanged:
        return (
          <PetPermissionsChanged
            active={active}
            event={event}
          />
        );
      case TimelineEventType.WeightChangedTargetMet:
        return (
          <TimelineCard
            active={active}
            text={t('notification_weight_changed_target_met', {
              devicename: getTimelineDeviceName(event.devices),
            })}
            date={event.created_at}
            image={
              <TimelineDevicePhoto
                active={active}
                event={event}
              />
            }
          />
        );
      case TimelineEventType.TrainingMode:
        return (
          <TrainingMode
            active={active}
            event={event}
          />
        );
      case TimelineEventType.CurfewTimezoneChange:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_clock_update')}
            date={event.created_at}
            image={
              <TimelineDevicePhoto
                active={active}
                event={event}
              />
            }
          />
        );
      case TimelineEventType.PoseidonWaterFreshness:
        return (
          <PoseidonWaterFreshness
            active={active}
            event={event}
          />
        );
      case TimelineEventType.PoseidonLowWater:
        return (
          <PoseidonLowWater
            active={active}
            event={event}
          />
        );
      case TimelineEventType.WaterRemoved:
        return (
          <WaterRemoved
            active={active}
            event={event}
          />
        );
        case TimelineEventType.TaringRequired:
        return (
          <TaringRequired
            active={active}
            event={event}
          />
        );
        case TimelineEventType.TarringOccurred:
        return (
          <TarringOccurred
            active={active}
            event={event}
          />
        );
    }
    return null;
  };

  const renderItem = useCallback(({ item, index }: { item: TimelineEventModel; index: number }) => {
    return <SpView>{renderBlock(item, index === 0)}</SpView>;
  }, []);

  return (
    <FlatList
      style={styles.eventContainer}
      data={data}
      renderItem={renderItem}
      keyExtractor={event => `${event.id}-timeline`}
      ItemSeparatorComponent={() => <SpView style={{ height: 20 }} />}
      ListEmptyComponent={() => (
        <SpCenter height={100}>
          <SpText
            size="xl"
            color={colors.greySmallTitle.color}>
            {t('no_entries')}
          </SpText>
        </SpCenter>
      )}
      refreshControl={
        <RefreshControl
          refreshing={false}
          onRefresh={onRefresh}
        />
      }
      ListFooterComponent={renderFooter}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.7}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  eventContainer: {
    paddingVertical: 22,
  },
  footer: {
    paddingTop: 12,
    paddingBottom: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
