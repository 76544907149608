import DeviceApi from '@api/DeviceApi';
import { DeviceStatusIDs } from '@constants/Device';
import { DeviceLoadingControl, DeviceModel, DevicePendingStatusModel } from '@models/Device';
import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useBoundStore from 'src/store/store';
const useDeviceStatusHook = (deviceId: DeviceModel['id'], active = false) => {
  const [data, setData] = useState<DevicePendingStatusModel[]>([]);
  const { setLoadingControl, loadingControl ,refreshDeviceTags} = useBoundStore((state) => state.deviceStore);
  const [prevState, setPrevState] = useState<DeviceLoadingControl>(cloneDeep(loadingControl));
  const isEmpty = useCallback(obj => !!Object.keys(obj).length, []);
  const { setAsyncRequestState } = useBoundStore(state => state.deviceStore);
  const pendingTagsAmount = useRef(0);

  const fetchDeviceStatus = async () => {
    if (!deviceId) return;
    try {
      const response = await DeviceApi.getDeviceStatus(deviceId);
      if (!response) {
        setData([]);
      } else {
        setData(response);
        setAsyncRequestState(
          deviceId,
          response.sort(
            (a, b) => new Date(a.requested_at).getTime() - new Date(b.requested_at).getTime(),
          ),
        );
      }
    } catch (e) {
      console.log('Device status ERROR =>', e);
    }
  };

  useEffect(() => {
    if (!active || !deviceId) return () => console.log('No active');
    fetchDeviceStatus();
    const interval = setInterval(() => {
      fetchDeviceStatus();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // <<<<<<< HEAD

  //   useEffect(() => {
  //     let stateObject = {};
  //     const pendingDeviceStatus = data.filter((item) => item.status === DeviceStatusIDs.Pending);
  //     if (!Array.isArray(data) || !data || pendingDeviceStatus.length === 0) {
  //       setLoadingControl({});
  //       return;
  //     }

  //     try {
  //       pendingDeviceStatus.forEach((item) => {
  //         stateObject = { ...stateObject, ...item.state };
  //       });
  //       setLoadingControl(stateObject);
  //     } catch (e) {
  //       console.log('Device status undefined', e);
  //     }

  //refresh tags data for device if amount of active pending tag_profiles items decreased
  useLayoutEffect(() => {
    if (!active || !deviceId) return;
    if (!data.length && pendingTagsAmount.current === 0) return;
    if (!data.length && pendingTagsAmount.current) {
      pendingTagsAmount.current = 0;
      refreshDeviceTags(deviceId);
    }
    const pendingTags = data?.flatMap?.(item => {
      if (
        item?.status &&
        item?.status === DeviceStatusIDs.Pending &&
        item?.state &&
        item.state.tag_profiles?.length
      ) {
        return item?.state?.tag_profiles ?? [];
      }
      return [];
    });

    const tagsForRemoveAmount = (loadingControl?.tag_profiles ?? []).filter(
      item => item?.justSent === false && !item?.request_id,
    ).length;

    if (pendingTags?.length < pendingTagsAmount.current || !!tagsForRemoveAmount) {
      refreshDeviceTags(deviceId);
    }
    pendingTagsAmount.current = pendingTags.length;
  }, [data]);

  // Added from useDeviceStatusParse.tsx
  useEffect(() => {
    if (!active || !deviceId) return;
    if (!data || !Array.isArray(data)) {
      return;
    }
    if (isEmpty(loadingControl) && !isEmpty(prevState)) setLoadingControl(prevState);
    setPrevState(cloneDeep(loadingControl));
    // Get ids of requests that already handled by server
    const requestsID = data.map(res => res.request_id);
    console.log('[DEBUG][useDeviceStatusParse]: dataStatus requestsIds', requestsID);
    const stateObject = {};

    // console.log('loadingControl', loadingControl);

    //Parse loaderControl for Items that needs to wait until server handles them;
    const justSentPending: DeviceLoadingControl = {};
    Object.keys(loadingControl).forEach(key => {
      if (!loadingControl[key]) return;
      // by default there should be an object or array, hope no one will pass anything else
      //interface DeviceLoadingControl {
      //   bowls?: DeviceFeederBowlModel & { justSent?: boolean; request_id?: string };
      //   lid?: DeviceFeederLidModel & { justSent?: boolean; request_id?: string };
      //   tare?: { justSent?: boolean; request_id?: string; value: DeviceFeederTareType };
      //   training_mode?: { justSent?: boolean; request_id?: string; value: boolean };
      //   close_delay?: { justSent?: boolean; request_id?: string; value: boolean };
      //   led_mode?: { justSent?: boolean; request_id?: string; value: number };
      //   locking?: { justSent?: boolean; request_id?: string; value: number };
      //   tag_profiles?: any[]; //[{...itemData, justSent?: boolean; request_id?: string;}]
      //   curfew?: any[]; //[{...itemData, justSent?: boolean; request_id?: string;}]
      // }
      if (Array.isArray(loadingControl[key])) {
        //There we need to check if some of the items just requested from server, or it's waiting while server handle them
        const unhandledItems = loadingControl[key].filter(
          item => item.justSent || (item.request_id && !requestsID.includes(item.request_id)),
        );
        if (!unhandledItems.length) return;
        justSentPending[key] = unhandledItems;
      } else {
        //There we need to check if item just requested from server, or it's waiting while server handle him
        const isUnhandled =
          loadingControl[key].justSent ||
          (!!loadingControl[key].request_id &&
            !requestsID.includes(loadingControl[key].request_id));
        if (!isUnhandled) return;
        justSentPending[key] = { ...loadingControl[key] };
      }
    });
    console.log('[DEBUG][useDeviceStatusParse]: justSentPending', justSentPending);
    // We got justSentPending object with unhandled items there, latter we need to pass it to loadingControl;
    // Assume that unhandled items have higher priority then handled

    // Parsing status response from server for Pending statuses
    // There seems to be a problem with server response since sometimes we got empty or undefined state object,
    // so we don't know which data should be passed to loadingControl, for now skipping
    // TODO check why backend responds with incorrect state for item
    const pendingDeviceStatus = data.filter(
      item => item.status === DeviceStatusIDs.Pending && !!item.state,
    );
    console.log('[DEBUG][useDeviceStatusParse]: pendingDeviceStatus', pendingDeviceStatus);
    // Case in which we should clear loaderControl seems to be working fine for now
    if (pendingDeviceStatus.length === 0 && !Object.keys(justSentPending).length) {
      console.log('[RESET111] loadingControl');
      setLoadingControl({});
      return;
    }

    //Start of magic and root of all problems
    try {
      //Firstly fill loaderControll with data received within status check for device;
      //Also ensure that there state in loadingControl can be an Array for tag_profiles
      // and curfew for bowls and lid it's extended model for other primitives values replaces with object with field value
      // for some reason curfew status from backend differs it can be an object for one curfew or array for multiple
      // to simplify logic and remove potential bugs within loadingControl curfew always an array (array with one item or multiple)
      pendingDeviceStatus.forEach(item => {
        Object.keys(item.state).forEach((key: keyof DeviceLoadingControl | 'substance_type'|'food_type') => {
          switch (key) {
            case 'tag_profiles':
              stateObject[key] = [...(stateObject[key] ?? []), ...item.state[key]];
              break;
            case 'curfew':
              stateObject[key] = [
                ...(stateObject[key] ?? []),
                ...(Array.isArray(item.state[key]) ? item.state[key] : [item.state[key]]),
              ];
              break;
            case 'bowls':
            case 'lid':
              stateObject[key] = { ...(stateObject[key] ?? {}), ...item.state[key] };
              break;
            case 'substance_type':
            case 'food_type':
              stateObject['bowls_control'] = {...(stateObject['bowls_control'] ?? {}), [key]: item.state[key]};
              break;
            default:
              stateObject[key] = { value: item.state[key] };
          }
        });
      });
      console.log('[DEBUG][useDeviceStatusParse]: stateObject[pendingDeviceStatus]', stateObject);
      // Next we need to add to loadingControl data for items that unhandled by server in time
      // For now these items has higher priority (not sure if this can lead to unexpected behaviour)
      // TODO check if higher priority of unhandled items can lead to unexpected behaviour
      Object.keys(justSentPending).forEach(key => {
        switch (key) {
          case 'tag_profiles':
          case 'curfew':
            stateObject[key] = [...(stateObject[key] ?? []), ...justSentPending[key]];
            break;
          case 'bowls':
          case 'lid':
          case 'bowls_control':
            stateObject[key] = { ...(stateObject[key] ?? {}), ...justSentPending[key] };
            break;
          default:
            stateObject[key] = { ...justSentPending[key] };
        }
      });
      console.log(
        '[DEBUG][useDeviceStatusParse]: stateObject[pendingDeviceStatus+justSentPending]',
        stateObject,
      );

      if (loadingControl.tag_profiles?.length) {
        refreshDeviceTags(deviceId);
      };

      setLoadingControl(stateObject);
    } catch (e) {
      console.log('[ERROR][useDeviceStatusParse]: Device status undefined', e);
    }
    return () => {
      console.log('[RESET222] loadingControl');
      setLoadingControl({});
    };
  }, [data]);

  return data;
  // >>>>>>> release/v4.0.50
};

export default useDeviceStatusHook;
