import React, { PropsWithChildren } from 'react';
import { SpView, SpViewProps } from './SpView';

interface SpVStackProps extends SpViewProps {
  space: number;
}

export const SpVStack = ({ space, children, ...props }: PropsWithChildren<SpVStackProps>) => (
  <SpView {...props}>
    {React.Children.toArray(children).map((item, index) => {
      const isFirst = index === 0;
      const isLast = React.Children.count(children) === index + 1;
      return (
        <SpView
          style={{
            marginTop: isFirst ? 0 : space,
            marginBottom: isLast ? 0 : space,
          }}
          key={index}>
          {item}
        </SpView>
      );
    })}
  </SpView>
);
