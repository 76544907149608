import { ConsumptionAlertOutcome, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';

export const ConsumptionInsightWarningOutcomes = [
  ConsumptionInsightOutcome.Decreased,
  ConsumptionInsightOutcome.Elevated,
];

export const ConsumptionAlertsWarningOutcomes = [
  ConsumptionAlertOutcome.Problematic,
  ConsumptionAlertOutcome.StillProblematic,
];
