import MeApi from '@api/MeApi';
import { AsyncStorageKeys } from '@constants/AsyncStorageKeys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { differenceInDays, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { DdLogs } from '../services/SPDataDogService';
import { SendAnalyticsDataService } from '../services/SendAnalyticsDataService';
import { MergedInterfaces } from '../store/models';
import useBoundStore from '../store/store';

export const useRefreshAnalyticsData = () => {
  const [allow, setAllow] = useState(false);
  const { activeHousehold } = useBoundStore((state: MergedInterfaces) => state.householdStore);
  const run = async () => {
    const [meData, UPLog] = await Promise.all([
      MeApi.getStartData(),
      AsyncStorage.getItem(AsyncStorageKeys.UPLog),
    ]);
    await SendAnalyticsDataService.setDebugMode(meData.user.last_name);

    const timeSinceLastOpen = UPLog
      ? differenceInDays(Date.now(), parseISO(UPLog as string).getTime())
      : null;

    if (timeSinceLastOpen !== null && timeSinceLastOpen < 7) {
      await SendAnalyticsDataService.trackEvents({ pets: meData.pets });
      return;
    }

    await AsyncStorage.setItem(AsyncStorageKeys.UPLog, new Date().toISOString());
    await SendAnalyticsDataService.trackProperties({
      activeHousehold,
      devices: meData.devices,
      households: meData.households,
      user: meData.user,
    });
    await SendAnalyticsDataService.trackEvents({ pets: meData.pets });
  };

  const allowAnalyticsRefresh = useCallback(() => {
    setAllow(true);
  }, []);

  useEffect(() => {
    if (!allow) {
      return;
    }

    if (!activeHousehold) {
      return;
    }
    setAllow(false);
    DdLogs.info('useRefreshAnalyticsData - useEffect');
    run().then();
  }, [activeHousehold, allow]);

  return allowAnalyticsRefresh;
};
