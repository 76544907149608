import { Image, StyleSheet, View } from 'react-native';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AppImages } from '@constants/AppImages';
import { SpText } from '../../../components/SpText/SpText';
import { useTranslation } from 'react-i18next';
import SpBadge from 'src/components/RoundIcons/SpBadge';
import WeightInput from 'src/components/WeightInput';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import colors from '@styles/colors';
import { FeederFoodType } from '@constants/Device';
import { testProperties } from '@utils/testProperties';
import { convertToGrams } from '@utils/convertToGrams';
import { BowlOptions, resolveBowlType } from '../StepForms/Devices/AddFeeder';

interface ISelectMealsProps {
  bowlFoodType: BowlOptions;
  weight: BowlOptions;
  setWeight: Dispatch<SetStateAction<BowlOptions>>;
  weightError: BowlOptions;
  setWeightError: Dispatch<SetStateAction<BowlOptions>>;
  isBowlSingle: boolean;
  isLeft?: boolean;
  componentName?: string;
}

const PortionAmountForm = ({
  bowlFoodType,
  weight,
  setWeight,
  weightError,
  setWeightError,
  isBowlSingle,
  isLeft = false,
  componentName,
}: ISelectMealsProps) => {
  const [values, setValues] = useState('');
  const { t } = useTranslation();
  const userWeightUnits = useUserWeightUnits();
  const { getShortUnit } = useWeightConversion(userWeightUnits);

  const unit = useMemo(() => getShortUnit(), [userWeightUnits]);

  useEffect(() => {
    const weightDetected = resolveBowlType(
      weight.single,
      weight.left,
      weight.right,
      isLeft,
      isBowlSingle,
    );
    setValues(`${convertToGrams(weightDetected, unit, true)} ${unit}`);
  }, []);

  const handleSetValue = (value: string) => {
    if (values === `0 ${unit}`) {
      setValues(value.replace(/^0+/, ''));
    } else {
      setValues(`${value} ${unit}`);
    }
    if (isBowlSingle) {
      return setWeight({
        ...weight,
        single: convertToGrams(Number(value), unit),
      });
    }
    return isLeft
      ? setWeight({
          ...weight,
          left: convertToGrams(Number(value), unit),
        })
      : setWeight({
          ...weight,
          right: convertToGrams(Number(value), unit),
        });
  };

  const foodTypeText = useMemo(() => {
    const foodTypeStr =
      FeederFoodType[
        resolveBowlType(
          bowlFoodType.single,
          bowlFoodType.left,
          bowlFoodType.right,
          isLeft,
          isBowlSingle,
        )
      ];
    return t(foodTypeStr ? `${foodTypeStr}_food` : 'No food type set');
  }, []);

  return (
    <>
      <View style={styles.portionAmountFormContainer}>
        <Image
          source={resolveBowlType(
            AppImages.bowl,
            AppImages.leftBowl,
            AppImages.rightBowl,
            isLeft,
            isBowlSingle,
          )}
          style={{
            height: 56,
            marginLeft: isBowlSingle ? -24 : -12,
          }}
          resizeMode="contain"
          {...testProperties(
            isBowlSingle ? 'portionAmount' : isLeft ? 'LeftBowl' : 'RightBowl',
            'image',
          )}
        />
        <View>
          <SpText
            color={colors.greyText.color}
            fontFamily="Rubik_Medium"
            size="xl"
            bold
            style={{ marginBottom: 8 }}>
            {t(resolveBowlType('single_bowl', 'left_bowl', 'right_bowl', isLeft, isBowlSingle))}
          </SpText>
          <SpBadge
            text={foodTypeText}
            textAlign="center"
            width="100%"
            paddingVertical={0}
          />
        </View>
      </View>
      <View style={styles.weightInputContainer}>
        <WeightInput
          value={values}
          additionalTestLabel={isBowlSingle ? '' : isLeft ? 'IsLeft' : 'IsRight'}
          isBowlSingle={isBowlSingle}
          componentName={componentName}
          setValue={handleSetValue}
          label="amount_per_serving"
          placeholder="type_amount"
          inputType={isBowlSingle ? 'single' : 'both'}
          isRequired={false}
          setError={(value: string) => {
            if (isBowlSingle) {
              return setWeightError({ ...weightError, single: value });
            }
            return isLeft
              ? setWeightError({ ...weightError, left: value })
              : setWeightError({ ...weightError, right: value });
          }}
          unit={unit}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  portionAmountFormContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: -44,
  },
  weightInputContainer: {
    marginLeft: -19,
    marginRight: -19,
  },
});

export default PortionAmountForm;
