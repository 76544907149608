/* eslint-disable class-methods-use-this */
import MeApi from '@api/MeApi';
import PetApi from '@api/PetApi';
import { CallTypes } from '@constants/Calls';
import { lens } from '@dhmk/zustand-lens';
import { PetModel } from '@models/Pet';
import { startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { isEqual } from 'lodash-es';
import { Platform } from 'react-native';
import { AnalyticsService } from 'src/services/AnalyticsService';
import PetStatisticsService from 'src/services/PetStatisticsService';
import { StateCreator } from 'zustand';

import { noConditionOption } from './ConditionStore';
import { DdLogs } from '../../services/SPDataDogService';
import { MergedInterfaces, PetSlice } from '../models';
import { TimeService } from '../../services/TimeService';
import { WithoutTimeDateFormat } from '@constants/DateFormat';
import { ConsumptionAlertsWarningOutcomes } from '@constants/ConsumptionInsightTypes';

const createPetSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  PetSlice
> = (rootSet, rootGet) => {
  return {
    petStore: lens((subSet, subGet, api) => ({
      petData: [],
      petStatistics: [],
      ignorePetStatsRefresh: false,
      loadingPet: false,
      firstRequestDataFetched: true,
      activePet: null,
      image: null,
      petStatisticsLoading: false,
      petInsights: { habits: {}, alerts: {} },
      petInsightWarningDatesLoading: false,
      setIgnorePetStatsRefresh: (ignorePetStatsRefresh: boolean) => {
        subSet({ ignorePetStatsRefresh });
      },
      resetInsights: () => {
        subSet({ petInsights: { habits: {}, alerts: {} } });
      },
      setActivePet: (petId: number) => {
        AnalyticsService.logEvent('PetStore - setActivePet');

        const activePet = subGet().getPetById(petId);
        subSet({ activePet });
      },
      getPetById: (petId: number) => {
        AnalyticsService.logEvent('PetStore - getPetById');

        const { petData } = subGet();

        return petData.filter(item => item.id === petId)[0];
      },
      getPetByTagId: (tagId: number) => {
        AnalyticsService.logEvent('PetStore - getPetByTagId');
        const { petData } = subGet();

        return petData.filter((item: PetModel) => item.tag_id === tagId)[0];
      },
      loadPet: async (force = false) => {
        await AnalyticsService.logEvent('PetStore - loadPet', {
          skipped: !force && subGet().petData.length,
        });
        if (!force && subGet().petData.length) {
          return;
        }
        subSet({ loadingPet: true });
        try {
          const petData = await PetApi.getPets();
          if (!isEqual(subGet().petData, petData)) {
            subSet({
              petData: petData.map(item => {
                return {
                  ...item,
                  // TODO Discuss case with condition 25
                  // @ts-ignore
                  conditions: item.conditions.length
                    ? item.conditions.filter(({ id }) => id !== noConditionOption)
                    : [],
                };
              }),
            });
          }
        } catch ({ response }) {
          subSet({ loadingPet: false });
        }
        subSet({ loadingPet: false, firstRequestDataFetched: false });
      },
      createPet: async (pet: PetModel) => {
        await AnalyticsService.logEvent('PetStore - createPet');
        subSet({ loadingPet: true });
        try {
          pet.date_of_birth = new Date(pet.date_of_birth);
          await PetApi.createPet(pet);
          await subGet().loadPet(true);
          const { petData } = subGet();
          await subGet().setActivePet(petData[petData.length - 1].id);
          subSet({ loadingPet: false });
          return petData[petData.length - 1].tag_id;
        } catch (e: any) {
          subSet({
            loadingPet: false,
          });
          throw e;
          return null;
        }
      },
      deletePet: async (petId: number) => {
        await AnalyticsService.logEvent('PetStore - deletePet');

        subSet({ loadingPet: true });
        try {
          await PetApi.deletePet(petId);
          subSet({
            loadingPet: false,
            petData: subGet().petData.filter(item => item.id !== petId),
            petStatistics: subGet().petStatistics.filter(item => item.pet_id !== petId),
            activePet: subGet().getPetById(petId) || null,
          });
        } catch (err) {
          subSet({ loadingPet: false });
          throw { code: CallTypes.deleting };
        }
      },
      updatePet: async (petId: number, pet: PetModel) => {
        await AnalyticsService.logEvent('PetStore - updatePet');
        subSet({ loadingPet: true });

        try {
          await PetApi.updatePet(petId, pet);
          await subGet().loadPet(true);
          await subGet().setActivePet(petId);
          subSet({ loadingPet: false });
        } catch (err) {
          subSet({ loadingPet: false });
          throw { code: CallTypes.updating };
        }
      },
      updatePetPosition: async (petId: number, position: { where: number; since: string }) => {
        await AnalyticsService.logEvent('PetStore - updatePetPosition');
        subSet({ loadingPet: true, petStatisticsLoading: true });
        try {
          await PetApi.updatePetPosition(petId, position);
          await subGet().loadPet(true);

          await subGet().setActivePet(petId);
        } catch (err) {
          throw { code: CallTypes.updating };
        } finally {
          subSet({ loadingPet: false, petStatisticsLoading: false });
        }
      },
      petReset: () => {
        AnalyticsService.logEvent('PetStore - petReset');
        subSet({
          petData: [],
          loadingPet: false,
          activePet: null,
          image: null,
          petInsights: { habits: {}, alerts: {} },
        });
      },
      updatePhoto: async uri => {
        try {
          await AnalyticsService.logEvent('PetStore - updatePhoto');
          subSet({ image: uri });
          const body = new FormData();
          const type = uri.split('.').pop();
          const uploadUri = Platform.OS === 'ios' ? uri.replace('file://', '') : uri;

          body.append('file', {
            // @ts-ignore
            uri: uploadUri,
            type: `image/${type}`,
            name: 'media', // Some name is required!!!
          } as any);

          const newPhoto = await MeApi.updatePhoto(body);
          const newPhotoFormatted: any = newPhoto.data;
          newPhotoFormatted.created_at = new Date(newPhoto.data.created_at);
          const { activePet } = subGet();
          activePet.photo = newPhotoFormatted;
          activePet.photo_id = newPhoto.data.id;

          subSet({ activePet });
          await subGet().updatePet(subGet().activePet.id, subGet().activePet);
        } catch (err) {
          console.log(err, 'we have got an error updating photo');
        }
      },
      deletePhoto: async () => {
        await AnalyticsService.logEvent('PetStore - deletePhoto');
        subSet({ loadingPet: true });
        try {
          const { activePet } = subGet();
          await MeApi.deletePhoto(activePet.photo_id);
          activePet.photo_id = null;
          subSet({ activePet });
          await subGet().updatePet(subGet().activePet.id, subGet().activePet);
        } catch (err) {
          subSet({ loadingPet: false });
        }
        subSet({ loadingPet: false });
      },
      loadPetStatistics: async (pets, devices, from, clearCache, dayshistory) => {
        const currentTimezone = rootGet().householdStore.activeHousehold.timezone.timezone.replace(
          'Kolkata',
          'Calcutta',
        );
        AnalyticsService.logEvent(`PetStore - loadPetStatistics - ${dayshistory}`);
        subSet({ petStatisticsLoading: true });
        rootSet(state => ({
          householdStore: { ...state.householdStore, activeHouseholdIsEmpty: pets.length === 0 },
        }));

        const ourDevices = rootGet().deviceStore.deviceData;

        try {
          const petStatistics = await PetStatisticsService.startLoading(
            pets,
            ourDevices,
            zonedTimeToUtc(startOfDay(utcToZonedTime(from, currentTimezone)), currentTimezone),
            clearCache ? [] : subGet().petStatistics,
            dayshistory,
          );

          subSet({ petStatistics });
        } catch (err) {
          DdLogs.error(
            'PetStore - loadPetStatistics',
            'loadPetStatisticsError',
            JSON.stringify(err),
          );
        } finally {
          AnalyticsService.logEvent('PetStore - loadPetStatistics - finished');

          subSet({ petStatisticsLoading: false });
        }
      },
      getPetStatistics: petId => {
        // AnalyticsService.logEvent('PetStore - getPetStatistics');
        const { petStatistics } = subGet();
        return petStatistics.find(item => item.pet_id === petId);
      },
      setPetStatistics: data => {
        AnalyticsService.logEvent('PetStore - setPetStatistics');
        const updatedData = subGet().petStatistics.map(item => {
          if (item?.pet_id && data?.pet_id) {
            return item.pet_id === data.pet_id ? data : item;
          }
          return item;
        });
        if (updatedData.length !== 0) {
          subSet({ petStatistics: updatedData });
        }
      },
      loadHouseholdStatistics: async (from, clearCache, dayshistory) => {
        subSet({ petStatisticsLoading: true });
        await rootGet().petStore.loadPet(true);
        await rootGet().deviceStore.loadDevice(true);

        const pets = rootGet().petStore.petData.filter(
          item => item.household_id === rootGet().householdStore.activeHousehold?.id,
        );
        const devices = rootGet().deviceStore.deviceData.filter(
          item => item.household_id === rootGet().householdStore.activeHousehold?.id,
        );
        rootSet(state => ({
          householdStore: { ...state.householdStore, activeHouseholdIsEmpty: pets.length === 0 },
        }));

        await subGet().loadPetStatistics(pets, devices, from, clearCache, dayshistory);
        subSet({ petStatisticsLoading: false });
      },
      loadPetInsights: async (petId, from, daysInHistory) => {
        // uncomment until cache will be based and date + petId
        const insights = subGet().petInsights;
        const date = TimeService.toLocal(from).toFormat(WithoutTimeDateFormat);

        if (insights.alerts[date] || insights.habits[date]) {
          return;
        }

        try {
          const [data, rolling] = await Promise.all([
            PetApi.getPetInsights(
              petId,
              TimeService.toLocal(from).endOf('day').toUTC(),
              daysInHistory,
            ),
            PetApi.getPetStatistics([petId], TimeService.toLocal().toISODate(), 1),
          ]);
          const habits = [
            ...data.consumption_habit,
            ...(rolling[0].consumption_habit || []),
          ].reduce((acc, curr) => {
            const date = TimeService.toLocal(curr.last_drinking_event_utc).toFormat(
              WithoutTimeDateFormat,
            );

            acc[date] = curr;
            return acc;
          }, {});

          const alerts = [
            ...(data.consumption_alert || []),
            ...(rolling[0].consumption_alert || []),
          ]
            // @ts-ignore
            .filter(alert => ConsumptionAlertsWarningOutcomes.includes(alert.outcome))
            .reduce((acc, curr) => {
              const date = TimeService.toLocal(curr.last_drinking_event_utc).toFormat(
                WithoutTimeDateFormat,
              );

              acc[date] = curr;
              return acc;
            }, {});

          // cache new values to habits whenever user selected a new date
          subSet({
            petInsights: {
              habits: {
                ...subGet().petInsights.habits,
                ...habits,
              },
              alerts: {
                ...subGet().petInsights.alerts,
                ...alerts,
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    })),
  };
};

export default createPetSlice;
