import { FeederFoodType, WeightContext } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import {
  ConsumptionReportDataModel,
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedConsumptionStats,
  MappedDoorStats,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
  MappedReportModel,
  MovementReportDataModel,
  PoseidonReportDataModel,
  PoseidonReportDataWeightsModel,
  ReportDataWeightsModel,
  ReportModel,
  WithDevice,
  WithDoorDevices,
} from '@models/ReportModel';
import { TagModel } from '@models/Tag';
import {
  addMonths,
  differenceInDays,
  differenceInMonths,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { last, meanBy, orderBy, sortBy, sumBy, uniqBy } from 'lodash-es';

import { AnalyticsService } from '../services/AnalyticsService';
import { TimeService } from '../services/TimeService';

export function filterFeederDeviceEvents(
  data: ConsumptionReportDataModel[],
): ConsumptionReportDataModel[] {
  AnalyticsService.logEvent('report - filterFeederDeviceEvents');

  return data.filter(a => {
    const totalWeight = (a.weights || [])
      .filter(weight => weight.change < 0)
      .reduce((acc, item) => {
        return acc + item.change;
      }, 0);

    return a.duration > 0 && a.context === WeightContext.PetClosed && Math.abs(totalWeight) > 0;
  });
}

export function filterPoseidonDeviceEvents(
  data: PoseidonReportDataModel[],
): PoseidonReportDataModel[] {
  AnalyticsService.logEvent('report - filterPoseidonDeviceEvents');
  return data.filter(a => {
    const totalWeight = (a.weights || [])
      .filter(weight => weight.change < 0)
      .reduce((acc, item) => {
        return acc + item.change;
      }, 0);

    return a.duration > 0 && a.context === WeightContext.PetClosed && Math.abs(totalWeight) > 1;
  });
}

export function filterCatFlapPetDoorDeviceEvents(
  data: MovementReportDataModel[],
): MovementReportDataModel[] {
  AnalyticsService.logEvent('report - filterCatFlapPetDoorDeviceEvents');
  return data.filter(event => {
    return event.duration > 0 && event.to && event.from;
  });
}

export function mapDeviceToEvent<T>(
  data: T[],
  devices: Record<number, DeviceModel>,
): (T & WithDevice)[] {
  return data.map(item => {
    return {
      ...item,
      // @ts-ignore
      device: devices[item?.deviceId] || null,
    };
  });
}

export function mapDeviceToDoorEvent(
  data: MovementReportDataModel[],
  devices: Record<number, DeviceModel>,
): (MovementReportDataModel & WithDoorDevices)[] {
  return data.map(item => {
    return {
      ...item,
      exitDevice: devices[item?.exit_device_id] || null,
      entryDevice: devices[item?.entry_device_id] || null,
    };
  });
}

export function groupByDay<T>(data: T[], currentTimezone?: string): GroupReportPointModel<T>[] {
  let prevDate;

  const grouped = data.reduce<Record<number, T[]>>((acc, item: T, id) => {
    const day = TimeService.toLocal(item.from).endOf('day').toMillis();
    if (new Date(item.from) < new Date(prevDate)) return acc;
    prevDate = item.from;
    
    if (!acc[day]) {
      acc[day] = [];
    }

    acc[day].push(item);

    return acc;
  }, {});

  const result = Object.keys(grouped).map(key => {
    return {
      date: Number(key),
      points: grouped[Number(key)],
    };
  });

  // @ts-ignore
  return sortBy(result, 'date');
}

export const getConsumptionStats = (
  data: GroupReportPointModel<ConsumptionReportDataModel | PoseidonReportDataModel>[],
  tagId: number,
  devices: DeviceModel[],
  date: number,
  isFelaqua = false,
): {
  totalDuration: number;
  date: number;
  lastTopupAt: any;
  bowls: any;
  averageActivity: number;
  totalWetWeight: number;
  totalActivity: number;
  averageDuration: number;
  totalDryWeight: number;
  totalWeight: number;
  lastConsumptionAt: any;
  device: any;
  totalBothWeight: number;
} => {
  // AnalyticsService.logEvent('report - getConsumptionStats');
  const events = data.map(item => item.points).flat();
  const deviceCollection = devices.reduce<Record<number, DeviceModel>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const sortedAllEvents = orderBy(events, 'from', 'desc');
  const lastEventsByDevice = uniqBy(sortedAllEvents, 'device_id').map(item => ({
    deviceId: item.device_id,
    bowls: item.weights,
    actualWeight: item.actual_weight,
  }));

  const mappedDevices: any[] = [];

  lastEventsByDevice.forEach(device => {
    const newMappedDevice = {
      device: deviceCollection[device.deviceId] || null,
      bowls: device.bowls.map(bowl => ({
        currentWeight: bowl.weight,
        actualWeight: device.actualWeight,
        foodTypeId: bowl.food_type_id,
        targetWeight: (bowl as ReportDataWeightsModel)?.target_weight,
        index: bowl.index,
        totalConsumption: 0,
        totalConsumptionSingle: 0,
        lastTopupAmount: 0,
      })),
      lastTopupAt: '',
      lastConsumptionAt: '',
    };

    orderBy(events, 'to', 'desc')
      .filter(item => device.deviceId === item.device_id)
      .forEach(item => {
        if (
          (!isFelaqua && item.context === WeightContext.UserClosed) ||
          (isFelaqua && item.context === WeightContext.DubiousClosed)
        ) {
          item.weights.forEach((weight, idx) => {
            if (
              item.weights[idx].change >= 1 &&
              newMappedDevice.bowls[idx] &&
              !newMappedDevice.bowls[idx].lastTopupAmount
            ) {
              newMappedDevice.bowls[idx].lastTopupAmount = weight.change;
            }
            if (item.weights[idx].change >= 1 && !newMappedDevice.lastTopupAt) {
              newMappedDevice.lastTopupAt = item.from.toString();
            }
          });
        }

        // WeightContext.PetClosed === 1, WeightContext.PetClosed === 1
        if (item.context === 1) {
          item.weights.forEach((weight, idx) => {
            if (weight.change <= -1 && newMappedDevice.bowls[idx]) {
              if (
                startOfDay(parseISO(item.from.toString())).getTime() ===
                startOfDay(new Date()).getTime()
              ) {
                const feedAmount = Math.round(Math.abs(weight.change));

                newMappedDevice.bowls[idx].totalConsumption += feedAmount;
                newMappedDevice.bowls[idx].totalConsumptionSingle += (
                  weight as PoseidonReportDataWeightsModel
                )?.multi
                  ? 0
                  : feedAmount;
              }

              if (!newMappedDevice.lastConsumptionAt) {
                newMappedDevice.lastConsumptionAt = item.to.toString();
              }
            }
          });
        }
      });

    mappedDevices.push(newMappedDevice);
  });

  const currentDevice =
    mappedDevices.filter(item => {
      return Boolean(item.device ? item.device.tags.find((c: TagModel) => c.id === tagId) : null);
    })[0] || null;

  const group = data.map(item => {
    const totalDuration = sumBy(item.points, 'duration');
    let totalWeight = 0;
    let wetWeight = 0;
    let bothWeight = 0;
    let dryWeight = 0;
    item.points.forEach((point: any) => {
      point.weights.forEach((weight: any) => {
        if (weight.food_type_id === FeederFoodType.wet) {
          wetWeight += Math.abs(weight.change);
        }

        if (weight.food_type_id === FeederFoodType.dry) {
          dryWeight += Math.abs(weight.change);
        }

        if (weight.food_type_id === FeederFoodType.both) {
          bothWeight += Math.abs(weight.change);
        }

        totalWeight += Math.abs(weight.change) || 0;
      });
    });

    return {
      date: item.date,
      activity: item.points.length,
      totalDuration,
      totalWeight,
      bothWeight,
      wetWeight,
      dryWeight,
    };
  });

  return {
    averageActivity: group.length ? meanBy(group, item => item.activity) : 0,
    averageDuration: group.length ? meanBy(group, item => item.totalDuration) : 0,
    totalActivity: sumBy(group, item => item.activity),
    totalDuration: sumBy(group, item => item.totalDuration),
    totalWeight: sumBy(group, item => item.totalWeight),
    totalBothWeight: sumBy(group, item => item.bothWeight),
    totalWetWeight: sumBy(group, item => item.wetWeight),
    totalDryWeight: sumBy(group, item => item.dryWeight),
    device: currentDevice?.device || null,
    bowls: currentDevice?.bowls || [],
    lastConsumptionAt: currentDevice?.lastConsumptionAt || null,
    lastTopupAt: currentDevice?.lastTopupAt || null,
    date,
  };
};

export const getDoorStats = (
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
  date: number,
): MappedDoorStats => {
  // AnalyticsService.logEvent('report - getDoorStats');
  const group = data.map(item => {
    const totalDuration = sumBy(item.points, 'duration');
    const sorted = orderBy(item.points, point => parseISO(point.from).getTime());
    const lastLeft = parseISO(last(sorted).from).getTime();
    const lastEnter = last(sorted).to ? parseISO(last(sorted).to).getTime() : null;

    return {
      date: item.date,
      activity: item.points.length,
      totalDuration,
      lastLeft,
      lastEnter,
    };
  });
  const sortedByLastLeft = orderBy(group, 'lastLeft');

  return {
    totalActivity: sumBy(group, item => item.activity),
    totalDuration: sumBy(group, item => item.totalDuration),
    lastLeft: last(sortedByLastLeft)?.lastLeft || null,
    lastEnter: last(sortedByLastLeft)?.lastEnter || null,
    date,
  };
};

export const getConsumptionStatsByRange = (
  data: GroupReportPointModel<ConsumptionReportDataModel | PoseidonReportDataModel>[],
  tagId: number,
  devices: DeviceModel[],
  from: number,
  to: number,
  isFelaqua = false,
): MappedConsumptionStats[] => {
  AnalyticsService.logEvent('report - getConsumptionStatsByRange');
  const groupByDate = data.reduce<
    Record<number, GroupReportPointModel<ConsumptionReportDataModel | PoseidonReportDataModel>>
  >((acc, item) => {
    acc[item.date] = item;
    return acc;
  }, {});

  const days = differenceInDays(to, from) + 1;

  return new Array(days).fill(true).map((item, i) => {
    const currentDay = TimeService.toLocal(from).plus({ days: i }).endOf('day').toMillis();
    const eventByDay = groupByDate[currentDay];

    return getConsumptionStats(
      eventByDay ? [eventByDay] : [],
      tagId,
      devices,
      currentDay,
      isFelaqua,
    );
  });
};

export const getDoorStatsByRange = (
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
  from: number,
  to: number,
): MappedDoorStats[] => {
  AnalyticsService.logEvent('report - getDoorStatsByRange');
  const groupByDate = data.reduce<
    Record<number, GroupReportPointModel<MappedMovementReportDataModel>>
  >((acc, item) => {
    acc[item.date] = item;
    return acc;
  }, {});

  const days = differenceInDays(to, from) + 1;

  return new Array(days).fill(true).map((item, i) => {
    const currentDay = TimeService.toLocal(from).plus({ days: i }).endOf('day').toMillis();
    const eventByDay = groupByDate[currentDay];

    return getDoorStats(eventByDay ? [eventByDay] : [], currentDay);
  });
};

export const groupEventsByMonthRangeWithGaps = (
  data: GroupReportPointModel<unknown>[],
  from: number,
  to: number,
): { date: number; data: GroupReportPointModel<unknown>[] }[] => {
  AnalyticsService.logEvent('report - groupEventsByMonthRangeWithGaps');
  const grouped = data.reduce<Record<string, GroupReportPointModel<unknown>>>((acc, item) => {
    acc[item.date] = item;
    return acc;
  }, {});
  const months = differenceInMonths(to, from) + 1;

  return new Array(months).fill(true).map((_, i) => {
    const currentMonth = startOfMonth(addMonths(from, i + 1)).getTime();

    return {
      date: currentMonth,
      data: Object.keys(grouped).reduce((acc, item) => {
        if (startOfMonth(Number(item)).getTime() === currentMonth) {
          acc.push(grouped[item]);
        }

        return acc;
      }, []),
    };
  });
};

export function mapReportData(
  data: ReportModel,
  devices: Record<number, DeviceModel>,
  timezone?: string,
): MappedReportModel {
  AnalyticsService.logEvent('report - mapReportData');
  const feeding = mapDeviceToEvent<ConsumptionReportDataModel>(
    filterFeederDeviceEvents(data.feeding.datapoints),
    devices,
  );

  const drinking = mapDeviceToEvent<PoseidonReportDataModel>(
    filterPoseidonDeviceEvents(data.drinking.datapoints),
    devices,
  );

  const movement = mapDeviceToDoorEvent(
    filterCatFlapPetDoorDeviceEvents(data.movement.datapoints),
    devices,
  );

  return {
    drinking: groupByDay<MappedPoseidonReportDataModel>(drinking),
    feeding: groupByDay<MappedConsumptionReportDataModel>(feeding),
    movement: groupByDay<MappedMovementReportDataModel>(movement),
  };
}
