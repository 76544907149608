import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { PetStatisticsModel } from '@models/Device';
import React, { useMemo } from 'react';

import { WeeklyGraph } from './WeeklyGraph';
import { ReportType } from '../../../../constants/ReportType';
import { fastTimeConvertor } from '../../../../hooks/useTotalConsumption';
import { TimeService } from '../../../../../../services/TimeService';
import { WithoutTimeDateFormat } from '@constants/DateFormat';

export interface WeeklyTrendProps {
  activeTab: ReportType;
  petStatistic: PetStatisticsModel;
  warningDates: string[];
  selectedDate: number;
  isTodaySelected?: boolean;
}

export const WeeklyTrend = ({
  activeTab,
  petStatistic,
  warningDates,
  selectedDate,
  isTodaySelected = false,
}: WeeklyTrendProps) => {
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);

  const [trend] = useMemo(() => {
    const getGraphics = () => {
      if (activeTab === ReportType?.Drinking) {
        return petStatistic?.drinking?.activity?.map((item, i) => {
          const date = TimeService.toLocal(item.date).endOf('day').toMillis();
          const warning =
            warningDates.includes(TimeService.toLocal(date).toFormat(WithoutTimeDateFormat)) &&
            date !== TimeService.toLocal(selectedDate).endOf('day').toMillis();

          return {
            x: i + 1,
            y: item.total_consumption || 0,
            label: convertWithUnits(item.total_consumption),
            date: TimeService.toLocal(item.date).endOf('day').toMillis(),
            warning,
          };
        }, {});
      }

      if (activeTab === ReportType?.Feeding) {
        return petStatistic?.feeding?.activity?.map((item, i) => {
          return {
            x: i + 1,
            y: item.total_consumption || 0,
            label: convertWithUnits(item.total_consumption, true),
            date: TimeService.toLocal(item.date).endOf('day').toMillis(),
            warning: i === 9999999, // Mock for test
          };
        }, {});
      }

      if (activeTab === ReportType?.Movement) {
        return petStatistic?.movement?.activity?.map((item, i) => {
          const days = Number(
            item.time_outside.includes('.') ? item.time_outside.split('.')[0] : 0,
          );
          const timeArray = item.time_outside.split(':');
          let value = 0;

          if (timeArray.length) {
            value =
              (+timeArray[2] || 0) * 1000 +
              (+timeArray[1] || 0) * 60000 +
              (+timeArray[0] || 0) * 3600000 +
              days * 86400000;
          }

          return {
            x: i + 1,
            y: value,
            label: fastTimeConvertor(+timeArray[0] || 0, +timeArray[1] || 0),
            date: TimeService.toLocal(item.date).endOf('day').toMillis(),
            warning: i === 9999999, // Mock for test
          };
        }, {});
      }

      return [];
    };
    const graphics = getGraphics()?.slice(0, 8);
    return [graphics, graphics?.some(item => item.y !== 0)];
  }, [
    activeTab,
    petStatistic?.drinking?.activity,
    petStatistic?.feeding?.activity,
    petStatistic?.movement?.activity,
    warningDates,
    selectedDate,
    convertWithUnits,
  ]);

  return (
    <WeeklyGraph
      withLastSegment={isTodaySelected}
      events={trend}
    />
  );
};
