import { PetDoorLockingMode } from '@constants/Device';
import { CatFlapQuickActions, PetDoorQuickActions } from '@constants/QuickActions';
import {
  DeviceCatFlapControlModel,
  DeviceCatFlapCurfew,
  DeviceModel,
  DevicePetDoorControlModel,
  DevicePetDoorCurfew,
} from '@models/Device';
import { useCallback, useMemo, useState } from 'react';
import { getUTCTime } from 'src/services/TimeService';

export const usePetDoorCatFlapCard = (
  data: DeviceModel,
  onChangeLockUnlock: (mode: PetDoorLockingMode, status: boolean) => void,
  onChangeCurfew: (data: DevicePetDoorCurfew | DeviceCatFlapCurfew[]) => void,
) => {
  const [modal, setModal] = useState<PetDoorQuickActions | CatFlapQuickActions>(null);

  const snapPoints = useMemo(() => ['40%'], []);
  const control = useMemo(() => {
    return data.control as DeviceCatFlapControlModel | DevicePetDoorControlModel;
  }, [data]);

  const dismissAction = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const lockUnlockAction = useCallback(
    (mode: PetDoorLockingMode, status: boolean) => {
      onChangeLockUnlock(mode, status);
      dismissAction();
    },
    [setModal, onChangeLockUnlock],
  );

  const changeCurfewAction = useCallback(
    (event: DevicePetDoorCurfew | DeviceCatFlapCurfew[]) => {
      const updates = Array.isArray(event)
        ? event.map(item => ({
            ...item,
            lock_time: getUTCTime(item.lock_time),
            unlock_time: getUTCTime(item.unlock_time),
          }))
        : {
            ...event,
            lock_time: getUTCTime(event.lock_time),
            unlock_time: getUTCTime(event.unlock_time),
          };
      if (
        (Array.isArray(event) && event.length !== 0 && event[0].closePopup === true) ||
        event.closePopup === true
      ) {
        setModal(null);
      }
      onChangeCurfew(updates);
    },
    [setModal, onChangeCurfew],
  );

  return {
    changeCurfewAction,
    lockUnlockAction,
    dismissAction,
    snapPoints,
    modal,
    control,
    setModal,
  };
};
