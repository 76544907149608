import { SpCenter } from 'src/components/SpCenter';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useMemo, useState } from 'react';
import ChooseBowlType from 'src/pages/Flows/Feeder/ChooseBowlType';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import StepFormCreator from 'src/components/StepFormCreator';
import SelectMeals from 'src/pages/Flows/Feeder/SelectMeals';
import { DeviceFeederBowlModel } from '@models/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { SpText } from '../../../../../../components/SpText/SpText';
import SpTitle from 'src/components/SpTitle';
import { useCustomToast } from '@hooks/useToast';
import { DeviceStackParamList } from '../..';
import PortionAmountForm from '../../../../../Flows/Feeder/PortionAmountForm';
import { BowlOptions } from '../../../../../Flows/StepForms/Devices/AddFeeder';
import InstructionsSteps from '../../../../../Flows/components/InstructionsSteps';
import PromptPage from '../../../../../Flows/Generic/PromptPage/PromptPage';
import FitBowl from '../../../../../Flows/Feeder/FitBowl';
import ZeroFeeder from '../../../../../Flows/Feeder/ZeroFeeder';
import BeforeStart from '../../../../../Flows/Feeder/BeforeStart';
import NoPortionSetup from '../../../../../Flows/Feeder/NoPortionSetup';

type Props = NativeStackScreenProps<DeviceStackParamList, 'DeviceBowlTypeSelect'>;

enum StepsEnum {
  ChooseBowlType = 1,
  BeforeStart,
  FitBowl,
  ZeroFeeder,
  SelectMeals,
  SelectMealsRight,
  PortionAmount,
  BowlSetupFinish,
  NoPortionSetup,
}

const DeviceBowlTypeSelect = ({ route }: Props) => {
  const { bowlTypeValue, id } = route.params;
  const [bowlType, setBowlType] = useState(bowlTypeValue);
  const [bowlFoodType, setBowlFoodType] = useState({
    single: null,
    left: null,
    right: null,
  });
  const optionsObj: BowlOptions = {
    single: null,
    left: null,
    right: null,
  };
  const { show } = useCustomToast();
  const device = useDeviceById(id);

  const { updateFoodTypeAsync, updateBowlTypeAsync, updateProperty } = useBoundStore(
    s => s.deviceStore,
  );
  const [step, setStep] = useState(1);
  const [weight, setWeight] = useState<BowlOptions>(optionsObj);
  const [weightError, setWeightError] = useState(optionsObj);

  const isBowlSingle = bowlType === 1;

  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();

  const updateFeeder = useCallback(async () => {
    const arr = [];
    arr.push(
      isBowlSingle
        ? {
            food_type: bowlFoodType.single,
            target: Number(weight?.single) || 0,
          }
        : {
            food_type: bowlFoodType.left,
            target: Number(weight?.left) || 0,
          },
    );
    if (!isBowlSingle) {
      arr.push({
        food_type: bowlFoodType.right,
        target: Number(weight?.right) || 0,
      });
    }

    const bowls: DeviceFeederBowlModel = {};
    bowls.settings = arr;
    bowls.type = bowlType;
    const results = await Promise.all([
      updateBowlTypeAsync(device.id, bowlType),
      updateFoodTypeAsync(device.id, bowls),
    ]);
    updateProperty({ changeFoodTypeResult: 'none' });
    if (results[0] && results[1]) {
      return true;
    }
    show({ description: t('error_update_bowl_type'), isError: true });
    return false;
  }, [bowlFoodType, isBowlSingle, bowlType, id, weight]);

  const portionAmount = () => {
    const portionFormCreator = (isLeft?: boolean) => {
      return (
        <PortionAmountForm
          bowlFoodType={bowlFoodType}
          weight={weight}
          setWeight={setWeight}
          weightError={weightError}
          setWeightError={setWeightError}
          isBowlSingle={isBowlSingle}
          isLeft={isLeft}
        />
      );
    };

    return (
      <>
        <SpTitle
          align="center"
          text={t('portion_amount')}
        />
        <SpText
          style={{ marginBottom: 44 }}
          align="center">
          {t('portion_amount_subtitle')}
        </SpText>
        {isBowlSingle ? (
          portionFormCreator()
        ) : (
          <View>
            {portionFormCreator(true)}
            <View style={{ marginTop: 48 }}>{portionFormCreator()}</View>
          </View>
        )}
        <TouchableOpacity
          style={styles.touchableContainer}
          onPress={() => {
            updateFeeder();
            setStep(9);
          }}>
          <SpText
            style={styles.link}
            size="xs">
            {t('setup_portions_later')}
          </SpText>
        </TouchableOpacity>
      </>
    );
  };

  const bowlSetupFinish = useMemo(() => {
    const weightSetted = isBowlSingle ? weight.single : weight.left || weight.right;
    return weightSetted ? (
      <>
        <View style={{ marginTop: 24 }}>
          <SpTitle
            align="center"
            text={t('bowl_portion_title')}
          />
        </View>
        <InstructionsSteps
          arr={[t('bowl_portion_subtitle'), t('bowl_portion_subtitle2')]}
          space={12}
        />
        <SpCenter marginTop={24}>
          <Image
            source={AppImages.portionIndicatorLights}
            style={{ width: 280, height: 40 }}
            resizeMode="stretch"
            {...testProperties('imgPortionIndicatorLights', 'image')}
          />
        </SpCenter>
      </>
    ) : (
      <PromptPage
        title={t('no_portion_title')}
        subtitle={t('no_portion_subtitle')}
        subtitle2={t('no_portion_subtitle2')}
        buttonTitle={t('setup_portion')}
        buttonAction={() => setStep(step - 1)}
        additionalPadding={80}
      />
    );
  }, [isBowlSingle, weight, step]);

  return (
    <View style={styles.container}>
      <StepFormCreator
        safeAreaView={false}
        step={step}
        setStep={setStep}
        enableBackSwipeHandler={true}
        enableIOSBackSwipeActions={true}
        steps={{
          [StepsEnum.ChooseBowlType]: {
            ui: (
              <View style={{ marginTop: 32 }}>
                <ChooseBowlType
                  bowlType={bowlType}
                  setBowlType={setBowlType}
                />
              </View>
            ),
            buttonDisabled: bowlType === null || bowlTypeValue === bowlType,
            hideProgressHeader: true,
            interceptor: () => {
              if (bowlType) {
                return true;
              }
              return false;
            },
            buttonText: t('update'),
          },
          [StepsEnum.BeforeStart]: {
            ui: BeforeStart,
            hideProgressHeader: true,
          },
          [StepsEnum.FitBowl]: {
            ui: FitBowl,
            props: { isBowlSingle },
            hideProgressHeader: true,
            backBottomButton: true,
          },
          [StepsEnum.ZeroFeeder]: {
            ui: ZeroFeeder,
            backBottomButton: true,
            hideProgressHeader: true,
          },
          [StepsEnum.SelectMeals]: {
            ui: SelectMeals,
            props: {
              bowlFoodType,
              setBowlFoodType,
              isBowlSingle,
              isLeft: true,
            },
            hideProgressHeader: true,
            buttonDisabled: isBowlSingle ? !bowlFoodType.single : !bowlFoodType.left,
            backBottomButton: true,
            interceptor: () => {
              if (bowlFoodType) {
                return true;
              }
              return false;
            },
          },
          [StepsEnum.SelectMealsRight]: {
            ui: (
              <View>
                <SelectMeals
                  bowlFoodType={bowlFoodType}
                  setBowlFoodType={setBowlFoodType}
                  isBowlSingle={isBowlSingle}
                />
              </View>
            ),
            backBottomButton: true,
            hideProgressHeader: true,
            skipStep: isBowlSingle,
            buttonDisabled: !bowlFoodType.right,
          },
          [StepsEnum.PortionAmount]: {
            ui: portionAmount(),
            backBottomButton: true,
            interceptor: updateFeeder,
            hideProgressHeader: true,
            extraScrollHeightAndroid: isBowlSingle ? 0 : 100,
            buttonDisabled: isBowlSingle
              ? !!weightError.single || weight.single < 10
              : !!weightError.right || !!weightError.left || weight.right < 10 || weight.left < 10,
          },
          [StepsEnum.BowlSetupFinish]: {
            ui: bowlSetupFinish,
            hideProgressHeader: true,
            interceptor: () => {
              show({ description: t('successfully_updated {{label}}', { label: 'Bowls change' }) });
              navigation.navigate('DeviceSetting', { id: device.id });
              return false;
            },
            handleAndroidBackPress: () => {
              setStep(7);
              return true;
            },
          },
          [StepsEnum.NoPortionSetup]: {
            ui: NoPortionSetup,
            props: { id: device.id, setStep },
            hideProgressHeader: true,
            customHeaderProps: {
              onBackPress: () => setStep(StepsEnum.PortionAmount),
            },
            interceptor: () => {
              show({ description: t('successfully_updated {{label}}', { label: 'Bowls change' }) });
              navigation.navigate('DeviceSetting', { id: device.id });
              return false;
            },
            handleAndroidBackPress: () => {
              setStep(7);
              return true;
            },
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  button: {
    marginBottom: 67,
    marginTop: 'auto',
  },
  center: {
    alignItems: 'center',
  },
  link: {
    color: colors.greyText.color,
    textAlign: 'center',
    paddingVertical: 24,
    textDecorationLine: 'underline',
  },
  touchableContainer: {
    marginBottom: 100,
    marginHorizontal: 80,
  },
});

export default DeviceBowlTypeSelect;
