import { TimeUnits } from '@constants/DateFormat';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeService } from '../services/TimeService';
import useBoundStore from '../store/store';

interface UseDateTransform {
  transformToHumanDateAgo: (
    dateAsString: string | number | Date | DateTime<boolean>,
    userFormat?: TimeUnits,
  ) => string;
  householdNowTime: Date;
  transformHoursToUTC: (time: string) => string;
}

export const useDateTransform = (): UseDateTransform => {
  const { t } = useTranslation();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);

  function createUTCOffsetString(seconds: number) {
    // Convert milliseconds to minutes
    let totalMinutes = seconds / 60;

    // Determine the sign for the offset
    const sign = totalMinutes >= 0 ? '+' : '-';

    // Convert total minutes to absolute value for further calculation
    totalMinutes = Math.abs(totalMinutes);

    // Calculate hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Format hours and minutes to always have two digits
    const hoursFormatted = hours.toString().padStart(2, '0');
    const minutesFormatted = minutes.toString().padStart(2, '0');

    // Construct the UTC offset string
    return `${sign}${hoursFormatted}:${minutesFormatted}`;
  }

  const transformToHumanDateAgo = useCallback(
    (dateAsString: string | number | Date | DateTime, userFormat = TimeUnits.AMPM) => {
      const date = TimeService.toLocal(dateAsString);
      const currentDay = TimeService.toLocal(new Date());
      const time = TimeService.getTimeFromDate(date, userFormat);
      const dateDiffInDays = TimeService.getDiffInDays(currentDay, date);
      switch (dateDiffInDays) {
        case 0:
          return t('today_at_{{time}}', {
            time,
          });
        case 1:
          return t('yesterday_at_{{time}}', {
            time,
          });
        default:
          return t('{{date}}_at_{{time}}', {
            date: format(date.toMillis(), 'do LLL'),
            time,
          });
      }
    },
    [activeHousehold],
  );

  const householdNowTime = useMemo(() => {
    if (!activeHousehold) {
      return null;
    }
    return zonedTimeToUtc(new Date(), activeHousehold.timezone.timezone);
  }, [activeHousehold]);

  //TODO move this to TimeService
  const transformHoursToUTC = useCallback((time: string) => {
    return TimeService.toLocal()
      .set({
        hour: Number(time.split(':')[0]),
        minute: Number(time.split(':')[1]),
      })
      .toUTC()
      .toFormat('HH:mm');
  }, []);

  return {
    transformToHumanDateAgo,
    householdNowTime,
    transformHoursToUTC,
  };
};
