import { faCheck, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import i18n from '@utils/i18n';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo, useState } from 'react';
import { KeyboardTypeOptions, Pressable, StyleSheet, Text, View } from 'react-native';
import BigList from 'react-native-big-list';
import { ScrollView } from 'react-native-gesture-handler';
import colors from 'src/styles/colors';
import { useDebouncedCallback } from 'use-debounce';

import SpBadge from './RoundIcons/SpBadge';
import { SpDivider } from './SpDivider';
import { SpInput, SpInputProps } from './SpInput';
import { SpRoundedHeaderButton } from './SpRoundedHeaderButton';
import { SpText } from './SpText/SpText';
import { SpView } from './SpView';

type ListItem = {
  id: number;
  name: string;
  itemImage?: any;
};

interface SpSearchListProps {
  arr: ListItem[];
  setItemId: (ids: number[]) => void;
  keyboardType?: KeyboardTypeOptions;
  selectedItems: number[];
  hasInput?: boolean;
  withImage?: boolean;
  withMulti?: boolean;
  multiEmptyPlaceholder?: '';
  placeholder?: string;
  inputProps?: Partial<SpInputProps>;
  headerText?: string | undefined;
  firstPlugText?: string | undefined;
  isMedical?: boolean;
  clearSelectionOnZeroIndex?: boolean;
  buttonText?: string;
  buttonCallback: () => void;
}

const SpSearchList = ({
  arr,
  selectedItems,
  setItemId,
  hasInput = false,
  keyboardType,
  withImage = false,
  withMulti = false,
  multiEmptyPlaceholder = '',
  placeholder = i18n.t('search'),
  headerText,
  inputProps,
  firstPlugText,
  clearSelectionOnZeroIndex = true,
  clearInMainListOnNegativeIndex = false,
  buttonText = '',
  buttonCallback = null,
  ...props
}: SpSearchListProps) => {
  const [filteredList, setFilteredList] = useState<ListItem[]>(
    arr.filter((item: ListItem) => {
      return item.id >= 0;
    }),
  );
  const [value, setValue] = useState<string>('');
  const [textInHeader, setTextInHeader] = useState(headerText);

  const escapeRegExp = (str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const debounceFilter = useDebouncedCallback(() => {
    const searchPhrase = value.trim().toLowerCase();
    if (!searchPhrase) {
      setFilteredList(arr);
      return;
    }

    const arrayList: ListItem[] = arr.filter((item: ListItem) => {
      return item.id >= 0;
    });

    const startsWithResults = arrayList.filter(({ name }) => {
      return name?.toLowerCase().startsWith(searchPhrase);
    });
    const matchResults = arrayList.filter(({ name }) => {
      const escapedSearchPhrase = escapeRegExp(searchPhrase);
      const regex = new RegExp(escapedSearchPhrase, 'i');
      return name?.match(regex);
    });
    const includesResults = arrayList.filter(({ name }) => {
      return name?.toLowerCase().includes(searchPhrase);
    });

    const filterderData = [...new Set([...startsWithResults, ...matchResults, ...includesResults])];

    setFilteredList(filterderData);
  }, 240);

  const changeSelectedAction = (id: number) => {
    dismissKeyboard();
    if (id === 0 && clearSelectionOnZeroIndex) {
      const index = selectedItems.indexOf(id);
      // I believe this is used to clear a multiple selection such as in conditions or when clearing a field like breeds, im not sure it it a good idea
      if (index === -1) {
        setItemId([0]);
        setTextInHeader(multiEmptyPlaceholder);
      } else {
        setItemId([]);
        setTextInHeader(headerText);
      }
      return;
    }
    if (withMulti) {
      selectedItems = [...selectedItems];

      if (selectedItems.includes(0)) {
        selectedItems = selectedItems.filter(item => item !== 0);
      }

      const index = selectedItems.indexOf(id);

      if (index === -1) {
        selectedItems.push(id);
      } else {
        selectedItems.splice(index, 1);
      }

      setItemId([...selectedItems]);

      return;
    }
    setItemId([id]);
  };

  const onSearch = useCallback((text: string) => {
    setValue(text);
    debounceFilter();
  }, []);

  const input = useMemo(() => {
    if (hasInput) {
      return (
        <SpView style={styles.input}>
          <SpInput
            value={value}
            keyboardType={keyboardType}
            onChangeText={onSearch}
            icon={faSearch}
            {...inputProps}
          />
        </SpView>
      );
    }

    return null;
  }, [hasInput, value, placeholder]);

  interface RenderItemProps {
    item: ListItem;
  }

  const selectedItemsBadges = useMemo(() => {
    if (!withMulti) {
      return null;
    }

    if (selectedItems?.[0] === 0) {
      return (
        <SpView style={[styles.selectedItemsBadges, styles.centerHeaderText]}>
          <SpText style={(styles.headerText, styles.centerHeaderText)}>
            {multiEmptyPlaceholder}
          </SpText>
        </SpView>
      );
    }

    if (!selectedItems?.length) {
      return (
        <SpView style={[styles.selectedItemsBadges, styles.centerHeaderText]}>
          <SpView>
            <SpText style={styles.headerText}>{textInHeader}</SpText>
          </SpView>
        </SpView>
      );
    }

    return (
      <SpView style={[styles.selectedItemsBadges]}>
        <ScrollView horizontal>
          <SpView style={styles.selectedItemsBadges}>
            {selectedItems.map(id => {
              return (
                <Pressable
                  key={id}
                  style={styles.selectedItemsBadge}
                  onPress={() => changeSelectedAction(id)}>
                  <SpBadge
                    text={arr.find(item => item.id === id)?.name}
                    width="auto"
                    flexDirection="row"
                    icon={
                      <FontAwesomeIcon
                        size={16}
                        color={colors.white.color}
                        icon={faXmark}
                        style={styles.badgeIcon}
                      />
                    }
                  />
                </Pressable>
              );
            })}
          </SpView>
        </ScrollView>
      </SpView>
    );
  }, [selectedItems, withMulti, multiEmptyPlaceholder, headerText]);
  const renderItem = useCallback(
    ({ item }: RenderItemProps) => {
      const selected = selectedItems.includes(item.id);

      return (
        <>
          <Pressable
            onPress={() => changeSelectedAction(item.id)}
            key={item.id}
            style={[styles.listItem, firstPlugText && item.id === 0 && styles.plugItem]}
            {...testProperties(item.name, 'button', 'SpSearchList')}>
            <View
              {...testProperties(item.name, 'listItemContent', 'SpSearchList')}
              style={styles.listItemContent}>
              {withImage && (
                <View
                  {...testProperties(item.name, 'image')}
                  style={styles.imageWrapper}>
                  {item.itemImage}
                </View>
              )}
              <View
                {...testProperties(item.name, 'text')}
                style={styles.listItemTextWrap}>
                <Text style={[styles.listItemText, withImage && styles.textOffset]}>
                  {(firstPlugText && item.id === 0) || item.id === undefined
                    ? firstPlugText
                    : item?.itemName ?? item.name}
                </Text>
              </View>
            </View>
            <View
              {...testProperties(
                props?.isMedical ? item?.name : 'icon',
                props?.isMedical ? 'CheckIcon' : `${item?.name || ''}`,
                props?.isMedical ? '' : 'FaCheck',
              )}
              style={styles.circleWrap}>
              {selected && (
                <View style={styles.circle}>
                  <FontAwesomeIcon
                    color="white"
                    size={17}
                    icon={faCheck}
                  />
                </View>
              )}
            </View>
          </Pressable>
          <SpDivider color={colors.greyAccent.color} />
        </>
      );
    },
    [selectedItems, withImage, withMulti],
  );

  return (
    <View
      testID="SpSearchList_View"
      style={styles.container}>
      {input || selectedItemsBadges ? (
        <View style={styles.header}>
          {input}
          <ScrollView>
            {/* eslint-disable-next-line no-nested-ternary */}
            {selectedItemsBadges ||
              (headerText ? <SpText style={styles.headerText}>{headerText}</SpText> : null)}
          </ScrollView>
        </View>
      ) : null}
      {buttonText && buttonCallback && (
        <SpRoundedHeaderButton
          stylesForContainer={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}
          w={200}
          backgroundColor={colors.greyText.color}
          textStyles={{
            fontSize: 16,
            fontFamily: 'Rubik_Medium',
            color: colors.white.color,
          }}
          onPress={() => buttonCallback()}
          title={buttonText}
        />
      )}
      <BigList
        scrollEnabled
        data={filteredList}
        itemHeight={(item, index) => (index === 0 && firstPlugText ? 84 : 60)}
        renderItem={renderItem}
        keyExtractor={item => `${item.id}`}
        contentContainerStyle={styles.scrollContainer}
        keyboardShouldPersistTaps="handled"
        renderHeader={null}
        renderFooter={null}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  plugItem: {},
  header: {
    marginBottom: 16,
    backgroundColor: '#fff',
    shadowColor: 'lightgrey',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.8,
    shadowRadius: 11.95,
    elevation: 18,
    zIndex: 1,
    paddingHorizontal: 20,
    paddingVertical: 12,
    maxHeight: '45%',
  },
  selectedItemsBadges: {
    maxWidth: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  centerHeaderText: {
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 14,
    lineHeight: 20,
    marginTop: 24,
    marginBottom: 14,
    width: '100%',
    justifyContent: 'center',
    color: '#263a43bf',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  input: {
    paddingVertical: 6,
  },
  listItem: {
    height: 55,
    paddingHorizontal: 16,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listItemTextWrap: {
    paddingRight: 16,
  },
  listItemText: {
    color: colors.greyText.color,
    fontSize: 16,
    fontFamily: 'Rubik',
  },
  scrollContainer: {
    minWidth: '100%',
    paddingHorizontal: 19,
    backgroundColor: '#ffffff',
    paddingBottom: 150,
    zIndex: -1,
  },
  imageWrapper: {
    height: 17,
    width: 24,
    overflow: 'hidden',
  },
  textOffset: {
    marginLeft: 16,
  },
  listItemContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  circleWrap: {
    minWidth: 24,
  },
  circle: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary.color,
    borderRadius: 50,
  },
  selectedItemsBadge: {
    flexDirection: 'row',
    marginTop: 4,
    marginRight: 8,
  },
  badgeIcon: {
    marginRight: 7,
  },
});

export default SpSearchList;
