import { DateTime } from 'luxon';

export const checkDSTTransition = (date: number, timezone: string) => {
  // Create two dates: one at the start and one at the end of the month
  const startOfMonth = DateTime.fromObject(
    { year: new Date(date).getFullYear(), month: new Date(date).getMonth() + 1, day: 1 },
    { zone: timezone },
  );
  const endOfMonth = startOfMonth.endOf('month');

  // Get the timezone offsets for these two dates
  const startOffset = startOfMonth.offset;
  const endOffset = endOfMonth.offset;

  // If offsets are different, it means there was a DST transition
  return startOffset !== endOffset;
};
