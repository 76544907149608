import SpTextButton from './SpTextButton';
import SpToggleButton from './SpToggleButton';
import { faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import { useNavigation, useNavigationState } from '@react-navigation/native';
import { testProperties } from '@utils/testProperties';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { navigationRef } from 'src/pages/RootNavigation';
import colors from 'src/styles/colors';
import { DefaultHitSlop } from '@constants/DefaultHitSlop';

export interface ICustomHeaderProps {
  title?: string;
  withTitle?: boolean;
  withRightButton?: boolean;
  rightButtonText?: string;
  rightButtonAction?: () => void;
  rightButtonActive?: boolean;
  withLeaveButton?: boolean;
  leaveButtonAction?: () => void;
  withArrowBack?: boolean;
  withCross?: boolean;
  withCrossMargin?: boolean;
  goBackFunc?: () => void;
  headerRightElement?: JSX.Element;
  backgroundColor?: string;
  styleHeader?: {
    backgroundColor: string;
    color: string;
    colorIcon: string;
  };
  onBackPress?: () => void;
}

const CustomHeader = ({
  title,
  withTitle = true,
  withRightButton = false,
  rightButtonText,
  rightButtonAction,
  rightButtonActive,
  withLeaveButton = false,
  leaveButtonAction,
  withArrowBack = false,
  withCross = false,
  withCrossMargin = false,
  goBackFunc,
  headerRightElement,
  styleHeader = {
    backgroundColor: colors.white.color,
    color: colors.black.color,
    colorIcon: colors.black.color,
  },
  onBackPress,
}: ICustomHeaderProps) => {
  const [routes, setRoutes] = useState<string[]>();
  const [name, setName] = useState<string>();
  const currentRouteName = navigationRef.current?.getCurrentRoute().name;
  const screenName = useNavigationState(state => state?.routes[state.index]);
  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    if (withTitle) {
      const routeName = navigationRef.current?.getCurrentRoute().name;
      setName(routeName || title);
    }
  }, [title]);

  useEffect(() => {
    const navState = navigationRef.current?.getRootState();
    const routesArr = navState?.routes[navState.routes.length - 1].state?.routeNames;
    setRoutes(routesArr);
  }, [name]);

  const routeName = useMemo(() => {
    if (!routes?.includes(currentRouteName)) {
      return '';
    }
    return currentRouteName;
  }, [currentRouteName, screenName, routes]);

  const openLink = () => {
    Linking.openURL(t('get_help_url'));
  };

  const icon = useMemo(() => {
    if (withArrowBack) {
      return (
        <FontAwesomeIcon
          color={styleHeader.colorIcon}
          size={24}
          icon={faChevronLeft}
        />
      );
    }

    if (withCross) {
      return (
        <FontAwesomeIcon
          color={styleHeader.colorIcon}
          size={24}
          icon={faClose}
          style={{ marginLeft: withCrossMargin ? -24 : 0 }}
        />
      );
    }

    return withTitle ? (
      <FontAwesomeIcon
        color={styleHeader.colorIcon}
        size={24}
        icon={faChevronLeft}
      />
    ) : (
      <FontAwesomeIcon
        color={styleHeader.colorIcon}
        size={24}
        icon={faClose}
      />
    );
  }, [withTitle, withArrowBack, withCross]);

  const titleName = useMemo(() => {
    return (
      withTitle && (
        <Text
          {...testProperties('headerScreenName', 'text')}
          style={[styles.title]}>
          {title || routeName}
        </Text>
      )
    );
  }, [withTitle, routeName]);

  const headerRightButton = useMemo(() => {
    return (
      withRightButton && (
        <SpToggleButton
          onPress={rightButtonAction || openLink}
          isActive={rightButtonActive}
          text={rightButtonText}
        />
      )
    );
  }, [withRightButton, rightButtonText, rightButtonAction, rightButtonActive]);

  const headerLeaveButton = useMemo(() => {
    const leaveAction = () => {
      return leaveButtonAction ? leaveButtonAction() : navigation.goBack();
    };
    return (
      withLeaveButton && (
        <SpTextButton
          text={t('leave_setup')}
          onPress={() => leaveAction()}
          textStyle={styles.leaveButton}
        />
      )
    );
  }, [withLeaveButton, leaveButtonAction]);

  return (
    <SafeAreaView
      {...testProperties('SafeAreaView', 'header')}
      accessible
      style={{ ...styles.safeArea, ...styleHeader }}>
      <View
        {...testProperties('SafeAreaViewWrapper', 'header')}
        style={{ ...styles.wrapper, ...styleHeader }}>
        <View
          {...testProperties('SafeAreaViewInner', 'header')}
          style={styles.container}>
          {headerLeaveButton || (
            <TouchableOpacity
              hitSlop={DefaultHitSlop}
              accessible
              onPress={() => {
                if (goBackFunc) {
                  goBackFunc();
                  return;
                }
                if (onBackPress) {
                  onBackPress();
                  return;
                }
                navigationRef.goBack();
              }}
              {...testProperties('close', 'button')}
              style={styles.iconWrap}>
              {icon}
            </TouchableOpacity>
          )}
        </View>
        {titleName}
        <View
          {...testProperties('RightHeaderElement', 'wrapper')}
          style={styles.container}>
          {headerRightElement || headerRightButton}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default CustomHeader;

const styles = StyleSheet.create({
  safeArea: { backgroundColor: colors.blue.color },
  wrapper: {
    flexDirection: 'row',
    paddingHorizontal: 19,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.blue.color,
    height: 56,
  },
  container: {},
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
    letterSpacing: -0.5,
    fontFamily: 'Rubik_Medium',
  },
  iconWrap: {
    height: 25,
    maxWidth: 25,
  },
  leaveButton: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    lineHeight: 16,
    color: colors.secondary.color,
  },
});
