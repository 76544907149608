import qs from 'qs';
import { CountryModel } from '@models/Country';
import Http from './Http';

class CountryApi {
  static readonly httpParams = {};

  static getCountries(): Promise<CountryModel[]> {
    return Http.get(`/api/country`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static getCountryByID(id: number): Promise<CountryModel[]> {
    return Http.get(`/api/country/${id}`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default CountryApi;
