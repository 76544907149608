import RadioCheckboxWithText from 'src/components/RadioCheckboxWithText';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React, { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet } from 'react-native';
import textStyles from '@styles/text';

interface AccountMarketingProps {
  currentPreference: boolean;
  handleChange: (unit: SetStateAction<boolean>) => void;
}

const AccountMarketingWrapper = ({ currentPreference, handleChange }: AccountMarketingProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(currentPreference);

  const handler = (bool: boolean) => {
    setValue(bool);
    handleChange(bool);
  };

  return (
    <SafeAreaView style={styles.content}>
      <SpView style={styles.inner}>
        <SpText style={[textStyles.title, styles.customTitle]}>{t('marketing_preferences')}</SpText>
        <RadioCheckboxWithText
          handler={handler}
          active={value}
          text={t('marketing_preferences_message')}
          title={t('marketing_preferences')}
        />
      </SpView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: 'white',
  },
  inner: {
    paddingHorizontal: 20,
  },
  customTitle: {
    marginBottom: 24,
    textAlign: 'left',
  },
});

export default AccountMarketingWrapper;
