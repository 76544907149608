import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { SpBurgerMenuOpenIcon } from 'src/components/RoundIcons/SpBurgerMenuOpenIcon';
import { SpNotificationsIcon } from 'src/components/RoundIcons/SpNotificationsIcon';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import * as React from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { openModal as openModalBox } from 'src/services/ModalBox';
import IndoorsSVG from 'src/components/SvgIcons/IndoorsSVG';
import { useTranslation } from 'react-i18next';
import ActionPicker from 'src/components/ActionPicker';
import AboutSVG from 'src/components/SvgIcons/AboutSVG';
import SupportSVG from 'src/components/SvgIcons/SupportSVG';
import PersonalSVG from 'src/components/SvgIcons/PersonalSVG';
import { RootStackParamList } from '../../index';

interface DashboardHeaderProps {
  householdName: string;
}

export const DashboardHeader = memo(({ householdName }: DashboardHeaderProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [open, setOpenModal] = useState<boolean>(false);
  const snapPoints = useMemo(() => ['30%'], []);
  const { t } = useTranslation();

  const buttons = useMemo(() => {
    return [
      {
        label: t('account'),
        action: () => {
          setOpenModal(false);
          navigation.navigate('AccountNavigation');
        },
        iconSVG: (
          <PersonalSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
      },
      {
        label: t('support'),
        action: () => {
          setOpenModal(false);
          navigation.navigate('WebviewScreen', {
            uri: 'https://surepetcaresupport.force.com/help/s/?language=en_US',
            title: 'Support',
          });
        },
        iconSVG: (
          <SupportSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
      },
      {
        label: t('about'),
        action: () => {
          setOpenModal(false);
          navigation.navigate('AboutScreen');
        },
        iconSVG: (
          <AboutSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
      },
    ];
  }, [navigation, setOpenModal, t]);

  const goToHousehold = useCallback(() => {
    navigation.navigate('HouseholdNavigation');
  }, [navigation]);

  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onOpenSideBar = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const onOpenNotificationsModal = useCallback(() => {
    navigation.navigate('DashboardNotifications');
  }, [openModalBox]);

  return (
    <>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.header}>
          <SpBurgerMenuOpenIcon action={onOpenSideBar} />
          <SpRoundedHeaderButton
            backgroundColor={colors.white.color}
            title={householdName || t('create_household')}
            onPress={goToHousehold}
            textStyles={styles.textStyles}
            stylesForContainer={styles.buttonContainer}
            stylesForContent={styles.buttonContent}
            iconSVG={
              <IndoorsSVG
                color={colors.greyText.color}
                width={17}
                height={17}
                viewBox="0 0 17 17"
              />
            }
          />
          <SpNotificationsIcon action={onOpenNotificationsModal} />
        </View>
      </SafeAreaView>

      <CustomBottomSheetModal
        opened={open}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        snapPoints={snapPoints}
        onDismiss={onDismiss}
        inScrollView
        scrollViewStyle={styles.bottomSheetModalContent}>
        <ActionPicker buttons={buttons} />
      </CustomBottomSheetModal>
    </>
  );
});

const styles = StyleSheet.create({
  bottomSheetModalContent: {
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: Platform.OS === 'ios' ? 24 : 15,
    paddingHorizontal: 16,
    backgroundColor: colors.white.color,
    borderBottomColor: colors.headerBorderColor.color,
    borderBottomWidth: 1,
  },
  textStyles: {
    fontFamily: 'Rubik',
    fontSize: 14,
    color: colors.greyText.color,
  },
  buttonContainer: {
    ...Platform.select({
      web: {
        flex: 1,
        marginHorizontal: 25,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.2,

        elevation: 15,
      },
      native: {
        flex: 1,
        marginHorizontal: 25,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,

        elevation: 15,
      },
    }),
  },
  buttonContent: {
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 22,
  },
  safeArea: {
    backgroundColor: 'white',
  },
});
