import DeviceApi from '@api/DeviceApi';
import WiFiDeviceAPI from '@api/WifiDeviceConnector';
import { lens } from '@dhmk/zustand-lens';
// import { WiFiDeviceSlice } from '../models/WiFiDeviceSlice';
import { AvailableNetwork } from '@models/WiFiDevice';
import { StateCreator } from 'zustand';

import { AnalyticsService } from '../../services/AnalyticsService';
import { DdLogs } from '../../services/SPDataDogService';
import { MergedInterfaces } from '../models';

export interface WiFiDeviceSlice {
  WiFiDeviceStore: {
    error: unknown;
    success: unknown;
    loading: boolean;
    settingUpWiFiDevice: boolean;
    connectedToWiFiDevice: boolean;
    WiFiDeviceConnectedToNetwork: boolean;
    WiFiDeviceConnectedToInternet: boolean;
    WiFiDeviceConnectedToServer: boolean;
    deviceProduct: string;
    deviceSSIDS: AvailableNetwork[];
    targetNetworkID: number;
    targetNetworkPassword: string;
    targetPairingCode: string;
    targetProduct: string;
    timeout: number;

    setTimeoutForWiFiProduct: (timeout: number) => void;
    setSettingUpWiFiDevice: (settingUpWiFiDevice: boolean) => void;
    startSearchForProduct: (product: string, maxRetries?: number) => void;
    startCheckDeviceStatus: () => void;
    stopSearch: (clearErrors?: boolean) => void;
    searchForDevice: (lastAttempt?: boolean) => void;
    connectDeviceToWifi: () => void;
    checkDeviceStatus: (isLastAttempt?: boolean) => void;
    startScanDevice: () => void;
    getNetworks: (lastAttempt?: boolean) => void;
    closeConnection: () => Promise<void>;

    setTargetNetworkID: (targetNetworkID: number) => void;
    setTargetNetworkPassword: (targetNetworkPassword: string) => void;
    setTargetPairingCode: (code?: string) => string;
    startPairDeviceToHousehold: () => void;
    pairDeviceToHousehold: (isLastAttempt: boolean) => void;

    pairedDeviceDeviceID: any;
    reset: () => void;
    resetStatus: () => void;
    WiFiDeviceFirmwareUpdateState: number;
    WiFiDeviceFirmwareUpdate: boolean;
    WiFiDeviceConnectedToMqtt: boolean;
    pairingToHousehold: boolean;
  };
}

const createWiFiDeviceSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  WiFiDeviceSlice
> = (set, get) => {
  return {
    WiFiDeviceStore: lens((subSet, subGet, api) => ({
      error: null,
      success: null,
      loading: false,
      settingUpWiFiDevice: false,
      connectedToWiFiDevice: false,
      WiFiDeviceConnectedToInternet: false,
      WiFiDeviceConnectedToMqtt: false,
      WiFiDeviceConnectedToNetwork: false,
      WiFiDeviceConnectedToServer: false,
      firmwareUpdate: false,
      deviceProduct: '',
      deviceSSIDS: [],
      targetPairingCode: 'f23',
      targetProduct: '',
      timeout: 3000,
      targetNetworkID: 0,
      targetNetworkPassword: '',
      pairedDeviceDeviceID: 0,
      WiFiDeviceFirmwareUpdateState: -1,
      WiFiDeviceFirmwareUpdate: false,
      pairingToHousehold: false,
      setTimeoutForWiFiProduct: timeout => {
        subSet({ timeout });
      },
      setSettingUpWiFiDevice: settingUpWiFiDevice => {
        AnalyticsService.logEvent('WiFiDeviceStore - setSettingUpWiFiDevice');

        subSet({ settingUpWiFiDevice });
      },
      startSearchForProduct: (product, maxRetries = 5) => {
        AnalyticsService.logEvent('WiFiDeviceStore - startSearchForProduct');

        subGet().stopSearch();

        subSet({
          connectedToWiFiDevice: false,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceFirmwareUpdateState: -1,
          WiFiDeviceFirmwareUpdate: false,
          WiFiDeviceConnectedToMqtt: false,
          pairingToHousehold: false,
          targetProduct: product,
          deviceSSIDS: [],
          targetNetworkID: 0,
          targetNetworkPassword: '',
          pairedDeviceDeviceID: 0,
          targetPairingCode: '',
          error: null,
          success: null,
          loading: true,
        });
        get().RetryStore.doRetry(subGet().searchForDevice, maxRetries);
      },
      startCheckDeviceStatus: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startCheckDeviceStatus');

        subGet().stopSearch();
        subSet({
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceFirmwareUpdateState: -1,
          WiFiDeviceFirmwareUpdate: false,
          WiFiDeviceConnectedToMqtt: false,
          pairingToHousehold: false,
          error: null,
            success: null,
            loading: true,
        });
        get().RetryStore.doRetry(subGet().checkDeviceStatus);
      },
      stopSearch: (clearErrors = true) => {
        AnalyticsService.logEvent('WiFiDeviceStore - stopSearch');

        get().RetryStore.stopRetry();
        subSet(clearErrors ? { loading: false, error: null } : { loading: false });
      },

      closeConnection: (): Promise<void> => {
        return WiFiDeviceAPI.closeConnection();
      },

      searchForDevice: async (lastAttempt: boolean) => {
        try {
          AnalyticsService.logEvent('WiFiDeviceStore - searchForDevice');
          const product = await WiFiDeviceAPI.getProduct(subGet().timeout);
          subSet({ deviceProduct: product.product });
          if (product.product === subGet().targetProduct) {
            subSet({ connectedToWiFiDevice: true });
            subGet().stopSearch(true);
          } else {
            subSet({ error: 'wrong product', loading: !lastAttempt });
          }
        } catch (err) {
          subSet({
            error: 'searchForDevice err',
            loading: !lastAttempt,
          });
          DdLogs.error(
            'WiFiDeviceStore - searchForDevice - error',
            'searchForDeviceError',
            JSON.stringify(err),
          );
        }
      },

      checkDeviceStatus: async (lastAttempt: boolean) => {
        try {
          subSet({ loading: true });
          const status = await WiFiDeviceAPI.checkStatus();
          console.log(status, 'status');
          AnalyticsService.logEvent('WiFiDeviceStore - checkDeviceStatus', { status });
          // @ts-ignore
          subSet({
            WiFiDeviceConnectedToNetwork:
              status.wifi_status === 'connected' || status.wifi_status === true,
            WiFiDeviceConnectedToInternet:
              status.internet === 'Pass' || status.internet === 'true' || status.internet === true,
            WiFiDeviceConnectedToServer:
              status.spc_server_status === 'Pass' ||
              status.spc_server_status === 'true' ||
              status.spc_server_status === true,
            WiFiDeviceConnectedToMqtt:
              status.mqtt_status === 'Pass' ||
              status.mqtt_status === 'true' ||
              status.mqtt_status === true,
            WiFiDeviceFirmwareUpdateState: status.FirmwareUpdate,
            WiFiDeviceFirmwareUpdate: status.FirmwareUpdate >= 0 && status.FirmwareUpdate < 100,
          });

          if (
            (status.spc_server_status === 'Pass' ||
            status.spc_server_status === 'true' ||
            status.spc_server_status === true) &&
            status.FirmwareUpdate === 100
          ) {
            subGet().stopSearch();
            subSet({ loading: false });
            return;
          }

          // Restarting the checking
          if (
            (status.spc_server_status === 'Pass' ||
              status.spc_server_status === 'true' ||
              status.spc_server_status === true) &&
            status.FirmwareUpdate < 100
          ) {
            subGet().stopSearch();
            setTimeout(function () {
              get().RetryStore.doRetry(subGet().checkDeviceStatus);
            }, 1000);
            return;
          }

          if (status.FirmwareUpdate === -100) {
            subGet().stopSearch();
            subSet({
              error: 'FirmwareUpdateErrorRecovery',
              loading: false,
            });
            subSet({ loading: false });
            return;
          }

          if (status.FirmwareUpdate === -200) {
            subGet().stopSearch();
            subSet({
              error: 'FirmwareUpdateErrorNoRecovery',
              loading: false,
            });
            subSet({ loading: false });
            return;
          }
          
          if (lastAttempt) {
            subSet({
              error: 'lastAttemptExceed',
              loading: false,
            });
          }
        } catch (err: any) {
          DdLogs.error(
            'WiFiDeviceStore - checkDeviceStatus',
            'checkDeviceStatusError',
            JSON.stringify(err),
          );
          subGet().stopSearch();
          if (err.code === 'ERR_BAD_REQUEST' && !lastAttempt) {
            subSet({ error: 'checkDeviceStatusErr', loading: false });
            // subSet({ loading: false });
            return;
          }
          if (err.code === 'ECONNABORTED' && !lastAttempt) {
            subSet({ error: 'ECONNABORTED' });
            return;
          }
          subSet({
            error: err.code === 'ECONNABORTED' ? err.code : 'checkDeviceStatusErr',
            loading: false,
          })
        }
      },
      startScanDevice: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startScanDevice');
        subGet().stopSearch();
        subSet({
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceConnectedToMqtt: false,
          deviceSSIDS: [],
          targetNetworkID: -99,
          error: null,
          success: null,
          loading: true,
        });

        get().RetryStore.doRetry(subGet().getNetworks, 3);
      },
      getNetworks: async (lastAttempt = false) => {
        try {
          AnalyticsService.logEvent('WiFiDeviceStore - getNetworks');
          const networks = await WiFiDeviceAPI.scanForWiFi();
          subSet({
            deviceSSIDS: networks.map((network, index) => ({
              ...network,
              id: index,
              name: network.ssid,
              rssi: network.rssi,
            })),
            loading: false,
          });
          if (networks.length > 0) {
            AnalyticsService.logEvent('WiFiDeviceStore - getNetworks - response', {
              numberOfNetworks: networks.length,
            });
            subGet().stopSearch();
          }
        } catch (err) {
          DdLogs.error('WiFiDeviceStore - getNetworks', 'getNetworksError', JSON.stringify(err));
          subSet({
            error: 'getNetworks err',
            loading: !lastAttempt,
          });
        }
      },
      connectDeviceToWifi: async () => {
        AnalyticsService.logEvent('WiFiDeviceStore - connectDeviceToWifi');
        subSet({
          loading: true,
          error: null,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceConnectedToMqtt: false,
        });
        try {
          const done = await WiFiDeviceAPI.connectToWiFi({
            ssid: subGet().deviceSSIDS[subGet().targetNetworkID].ssid,
            password: subGet().targetNetworkPassword,
            pairing_code: subGet().setTargetPairingCode(),
          });
          subSet({
            loading: false,
            error: null,
            targetNetworkPassword: null,
            success: true,
          });
          // subGet().startCheckDeviceStatus();
        } catch (err) {
          DdLogs.error(
            'WiFiDeviceStore - connectDeviceToWifi',
            'connectDeviceToWifiError',
            JSON.stringify(err),
          );
          subSet({
            loading: false,
            error: 'connectDeviceToWifi err',
            targetNetworkPassword: null,
          });
        }
      },
      setTargetNetworkID: targetNetworkID => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetNetworkID');
        subSet({ targetNetworkID });
      },
      setTargetNetworkPassword: targetNetworkPassword => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetNetworkPassword');
        subSet({ targetNetworkPassword });
      },
      setTargetPairingCode: (code?: string) => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetPairingCode');
        if (code !== null && code !== undefined) {
          subSet({ targetPairingCode: code });
          return code;
        }
        let hex = '';
        for (let i = 0; i < 32; i++) {
          hex += Math.floor(Math.random() * 16).toString(16);
        }
        subSet({ targetPairingCode: hex });
        return hex;
      },
      startPairDeviceToHousehold: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startPairDeviceToHousehold');
        // subGet().stopSearch();
        subSet({
          error: null,
          success: null,
          loading: true,
          pairingToHousehold: true,
        });
        get().RetryStore.doRetry(subGet().pairDeviceToHousehold, 10);
      },
      pairDeviceToHousehold: async (lastAttempt: boolean) => {
        AnalyticsService.logEvent('WiFiDeviceStore - pairDeviceToHousehold');
        //Just in case if we accidentally get here after successful pairing
        if (subGet().pairedDeviceDeviceID) {
          subSet({
            pairingToHousehold:false
          });
          subGet().stopSearch();
          return;
        }
        if (!subGet().targetPairingCode) {
          DdLogs.error(
            'WiFiDeviceStore - pairDeviceToHousehold - no code',
            'pairDeviceToHouseholdError',
            'No code',
          );
          subSet({
            error: 'Pair Device To Household Error, no code',
          });
          subGet().stopSearch(false);

          //  subSet({loading: false})
          return;
        }
        subSet({
          loading: true,
          error: null,
        });
        try {
          subGet().stopSearch(false);
          const done = await DeviceApi.pairWithCode(
            subGet().targetPairingCode,
            get().householdStore.activeHousehold.id,
          );
          subSet({
            loading: false,
            error: null,
            targetPairingCode: null,
            success: true,
            pairedDeviceDeviceID: done?.id,
            pairingToHousehold: false,
          });
          subGet().stopSearch();
        } catch (err) {
          DdLogs.error(
            'WiFiDeviceStore - pairDeviceToHousehold',
            'pairDeviceToHouseholdError',
            JSON.stringify(err),
          );
          subSet({
            loading: !lastAttempt,
            error: err.data?.error.error[0] || 'Pair Device To Household Error',
          });
        }
      },
      reset: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - reset');
        // subGet().clearCheckInterval();
        subGet().stopSearch();
        subSet({
          error: null,
          success: null,
          loading: false,
          connectedToWiFiDevice: false,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceFirmwareUpdateState: -1,
          WiFiDeviceFirmwareUpdate: false,
          WiFiDeviceConnectedToMqtt: false,
          pairingToHousehold:false,
          deviceProduct: '',
          deviceSSIDS: [],
          targetPairingCode: '',
          targetProduct: '',
          targetNetworkID: -99,
          targetNetworkPassword: '',
          pairedDeviceDeviceID: 0,
        });
      },
      resetStatus: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - reset');
        subSet({
          connectedToWiFiDevice: false,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          WiFiDeviceFirmwareUpdateState: -1,
          WiFiDeviceFirmwareUpdate: false,
          WiFiDeviceConnectedToMqtt: false,
          pairingToHousehold: false,
        });
      },
    })),
  };
};
export default createWiFiDeviceSlice;
