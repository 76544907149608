import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useLayoutEffect, useMemo } from 'react';
import useBoundStore from 'src/store/store';

import { NavigationList } from '../components/DeviceSetting/NavigationList';
import { DeviceStackParamList } from '../index';

export const DeviceSetting = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceSetting'>) => {
  const { loadingControl, refreshDeviceTags } = useBoundStore(s => s.deviceStore);
  const { getPetByTagId } = useBoundStore(s => s.petStore);
  const { id } = route.params;
  const device = useDeviceById(id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);

  const petsForRender = useMemo(() => {
    const assignedPets = petsWithTag.filter(item => item.isAssigned).map(item => item.pet);
    const assignedPetsTagIDs = assignedPets.map(pet => pet.tag_id);
    return [
      ...assignedPets,
      ...(loadingControl?.tag_profiles ?? [])
        .filter(tag => tag?.tag_id && !assignedPetsTagIDs.includes(tag.tag_id))
        .map(tag => getPetByTagId(tag.tag_id)),
    ];
  }, [petsWithTag, loadingControl?.tag_profiles, getPetByTagId]);
  useLayoutEffect(() => {
    refreshDeviceTags(id);
  }, [id]);

  return (
    <NavigationList
      device={device}
      pets={petsForRender}
    />
  );
};
