import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

interface PointDetailsItemProps {
  leftLabel: string;
  leftValue: string;
  centerLabel?: string;
  centerValue?: string;
  rightLabel: string;
  rightValue: string;
  multiPetLabel?: string;
  multiPetEvent?: boolean;
  deviceName?: string;
}

export const EventDetailsCard = ({
  leftLabel,
  leftValue,
  centerLabel,
  centerValue,
  rightLabel,
  rightValue,
  multiPetLabel,
  multiPetEvent,
  deviceName,
}: PointDetailsItemProps) => {
  const [itemDetailsWidth, setItemDetailsWidth] = useState(0);
  const labelSize = useMemo(() => {
    return itemDetailsWidth < 281 ? "xs" : "sm";
  }, [itemDetailsWidth]);

  return (
    <View style={[styles.renderItemWrapper]}>
      {deviceName && (
        <SpView style={{ width: '100%', padding: 15 }}>
          <SpText size="sm" fontFamily="Rubik_Medium" color={colors.toxicGreen.color}>
            {deviceName}
          </SpText>
        </SpView>
      )}
      {multiPetEvent ? (
        <SpView
          style={{
            width: '100%',
            alignSelf: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            color: colors.white.color,
            paddingTop: 8,
            flexDirection: 'row',
          }}>
          <View
            style={{
              alignContent: 'center',
              justifyContent: 'center',
              width: 24,
              backgroundColor: colors.toxicGreen.color,
              height: 24,
              borderRadius: 50,
            }}>
            <SpText
              style={{
                color: 'white',
                textAlign: 'center',
              }}>
              ?
            </SpText>
          </View>
          <SpText
            style={{
              paddingLeft: 6,
              color: colors.white.color,
              fontSize: 15,
            }}>
            {multiPetLabel}
          </SpText>
        </SpView>
      ) : null}
      <View 
        style={[styles.renderItemDetails, deviceName && { paddingTop: 0 }]} 
        onLayout={(e) => setItemDetailsWidth(e.nativeEvent.layout.width)}
      >
        {leftLabel && (
          <SpView
            style={[styles.center, styles.flexStart]}
            flex={1}>
            <SpText
              size={labelSize}
              fontFamily="Rubik_Medium"
              color={colors.toxicGreen.color}>
              {leftLabel}
            </SpText>
            <SpText
              color={colors.white.color}
              numberOfLines={1}
              adjustsFontSizeToFit>
              {leftValue}
            </SpText>
          </SpView>
        )}
        {centerLabel && (
          <SpView
            style={styles.center}
            flex={2}>
            <SpText
              size={labelSize}
              fontFamily="Rubik_Medium"
              color={colors.toxicGreen.color}>
              {centerLabel}
            </SpText>
            <SpText
              color={colors.white.color}
              adjustsFontSizeToFit
              numberOfLines={1}>
              {centerValue}
            </SpText>
          </SpView>
        )}
        {rightLabel && (
          <SpView
            style={[styles.center, styles.flexEnd]}
            flex={1}>
            <SpText
              size={labelSize}
              fontFamily="Rubik_Medium"
              color={colors.toxicGreen.color}>
              {rightLabel}
            </SpText>
            <SpText
              color={colors.white.color}
              numberOfLines={1}
              adjustsFontSizeToFit>
              {rightValue}
            </SpText>
          </SpView>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  renderItemWrapper: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
  },
  renderItemDetails: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 20,
    gap: 2,
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
});
