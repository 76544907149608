import { useGetAllPetReports } from '@hooks/useGetAllPetReports';
import { usePetDevices } from '@hooks/usePetDevices';
import { PetStatisticsModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Dimensions, ScrollView, StyleSheet } from 'react-native';
import CalendarModal from 'src/components/CalendarModal';
import SpDashbooardSkeleton from 'src/components/SpDashbooardSkeleton';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpView } from 'src/components/SpView';
import PetChangeModal from 'src/pages/Dashboard/components/PetDashboardHeaderMolecules/PetChangeModal';
import PetOptionsModal from 'src/pages/Dashboard/components/PetDashboardHeaderMolecules/PetOptionsModal';
import { PetsHeader } from 'src/pages/Dashboard/components/PetsHeader';
import { TimeService } from 'src/services/TimeService';

import Card from './Card';
import DateCarousel from './components/DateCarousel';
import { WeeklyTrend } from './components/WeeklyTrend';
import { Tabs } from '../../../components/Tabs';
import { ReportName, ReportType, ReportTypeIconWithProps } from '../../../constants/ReportType';
import AllPetsBottomSheet from '../PetDashboardReport/components/AllPetsBottomSheet';
import { usePetInsightWarningDates } from './hooks/usePetInsightWarningDates';
import {
  ConsumptionInsight,
  ConsumptionInsightsGroups,
  ConsumptionInsightWithType,
} from '@models/ConsumptionInsight';
import { WithoutTimeDateFormat } from '@constants/DateFormat';
import { ConsumptionAlertsWarningOutcomes } from '@constants/ConsumptionInsightTypes';

interface PetDashboardSummaryProps {
  pet: PetModel;
  dateRange: number[];
  selectedDate: number;
  onChangeDate: (data: number) => void;
  petStatistic: PetStatisticsModel;
  petStatisticsLoading: boolean;
  petInsights: ConsumptionInsightsGroups;
}

export const SummaryDashboard = ({
  selectedDate,
  dateRange,
  pet,
  onChangeDate,
  petStatistic,
  petStatisticsLoading,
  petInsights,
}: PetDashboardSummaryProps) => {
  const dataBeforeLockingDevice = useRef(null);

  const [activeTab, setActiveTab] = useState(null);
  const [petsBottomSheetShown, setPetsBottomSheetShown] = useState(false);

  const navigation = useNavigation();
  const tabs = useGetAllPetReports(pet);
  const { t } = useTranslation();
  const warningDates = usePetInsightWarningDates(
    Object.values(petInsights?.habits || {}) || [],
    activeTab,
  );
  const petDevices = usePetDevices(pet);

  const convertedDate = TimeService.toLocal(selectedDate).toFormat('yyyy-MM-dd');

  useEffect(() => {
    if (petStatistic?.movement && AppState.currentState !== 'active') {
      dataBeforeLockingDevice.current = petStatistic.movement;
    }
  }, [petStatistic, AppState.currentState]);

  useEffect(() => {
    if (activeTab) return;
    if (tabs.length) {
      setActiveTab(tabs[0]);
    } else {
      setActiveTab(null);
    }
  }, [JSON.stringify(tabs), activeTab]);

  const renderTab = useCallback(
    (item: ReportType) => {
      const Icon = ReportTypeIconWithProps[item];
      return (
        <SpView>
          <Icon
            viewBox="0 0 24 26"
            color={activeTab === item ? undefined : 'rgba(38, 58, 67, 0.3)'}
          />
        </SpView>
      );
    },
    [activeTab],
  );

  const isNotAssigned = petDevices.length === 0;

  const isToday = useMemo(() => {
    return TimeService.toLocal()
      .startOf('day')
      .equals(TimeService.toLocal(selectedDate).startOf('day'));
  }, [selectedDate]);

  const insight = useMemo((): ConsumptionInsightWithType => {
    const date = TimeService.toLocal(selectedDate).toFormat(WithoutTimeDateFormat);

    const habit = isToday
      ? petStatistic?.consumption_habit && petStatistic.consumption_habit[0]
      : petInsights.habits[date];
    const alert = isToday
      ? petStatistic?.consumption_alert && petStatistic.consumption_alert[0]
      : petInsights.alerts[date];

    // @ts-ignore
    const isSuitableAlert = ConsumptionAlertsWarningOutcomes.includes(alert?.outcome)
      ? alert
      : null;

    return {
      insight: (isSuitableAlert || habit) as ConsumptionInsight,
      isAlert: !!isSuitableAlert,
    };
  }, [isToday, petStatistic, petInsights, selectedDate]);

  const isInsightAllowed = useMemo(() => {
    return ReportName[activeTab] === ReportName[ReportType.Drinking];
  }, [activeTab]);

  return (
    <>
      <PetsHeader
        accessible={false}
        activePet={pet}
        name="PetDashboardSummary"
        navigation={navigation}
        openPetsBottomSheet={() => setPetsBottomSheetShown(true)}
      >
        <DateCarousel
          accessible={false}
          dateRange={dateRange}
          selectedDate={convertedDate}
          setSelectedDate={onChangeDate}
          allowInsights={isInsightAllowed}
          warningDates={warningDates}
        />
      </PetsHeader>
      <LinearGradient
        start={{ x: 3, y: 2 }}
        end={{ x: 3, y: 0 }}
        colors={['#e4e2e4', '#FFF']}
        style={{ height: 20, zIndex: -1 }}
      />
      <PetChangeModal modal="PetDashboardSummaryChangePet" />
      <PetOptionsModal />
      <CalendarModal
        accessible={false}
        modal="PetDashboardSummaryCalendar"
        selectedDate={convertedDate}
        setSelectedDate={onChangeDate}
        allowInsights={isInsightAllowed}
        warningDates={warningDates}
      />
      <>
        {tabs && tabs.length > 1 && (
          <Tabs
            data={tabs}
            active={activeTab}
            renderItem={renderTab}
            onChange={setActiveTab}
          />
        )}
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <SpView
            testID="PetDashboardSummary"
            style={styles.container}
          >
            {petStatisticsLoading ? (
              <SpDashbooardSkeleton />
            ) : (
              <SpView style={styles.cardWrapper}>
                <Card
                  petStatistic={petStatistic}
                  activeTab={activeTab}
                  insight={insight}
                  isNotAssigned={isNotAssigned}
                  petId={pet.id}
                  isAtThisDay={isToday}
                />
              </SpView>
            )}
            <SpView paddingBottom={20}>
              {!petStatisticsLoading && (
                <WeeklyTrend
                  selectedDate={selectedDate}
                  activeTab={activeTab}
                  petStatistic={petStatistic}
                  warningDates={warningDates}
                  isTodaySelected={isToday}
                />
              )}
              {!isToday && !petStatisticsLoading && (
                <SpRoundedHeaderButton
                  title={t('return_to_today')}
                  stylesForContainer={styles.buttonContainer}
                  backgroundColor={colors.greyText.color}
                  onPress={() => {
                    onChangeDate(+new Date());
                  }}
                />
              )}
            </SpView>
          </SpView>
        </ScrollView>
      </>
      {petsBottomSheetShown && (
        <AllPetsBottomSheet
          petId={pet.id}
          setIsShown={setPetsBottomSheetShown}
          resetTab={() => setActiveTab(null)}
        />
      )}
    </>
  );
};

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white.color,
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: windowHeight < 810 ? '1%' : 15,
    paddingBottom: windowHeight < 810 ? '0.1%' : 30,
  },
  cardWrapper: {
    paddingHorizontal: 24,
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 20,
    width: '85%',
  },
});
