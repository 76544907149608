import { SubstanceType } from '@constants/Device';

export enum ConsumptionInsightOutcome {
  Unavailable = 0,
  WillBeAvailable = 1,
  Ok = 2,
  Decreased = 3,
  WasDecreased = 4,
  Elevated = 5,
  WasElevated = 6,
  WasFluctuating = 7,
}

export enum ConsumptionAlertOutcome {
  Unavailable = 0,
  Ok = 1,
  Problematic = 2,
  StillProblematic = 3,
}
// shortcut for ConsumptionInsight
export interface ConsumptionInsight<T = ConsumptionInsightOutcome> {
  amount: number;
  created_at: string;
  last_drinking_event_local: string;
  last_drinking_event_utc: string;
  n_days: number;
  outcome: T;
  scoring: number;
  substance: SubstanceType;
  target_days: number;
}

export interface ConsumptionInsightApiResponse {
  pet_id: number;
  consumption_habit: ConsumptionInsight[];
  consumption_alert: ConsumptionInsight[];
}

export interface ConsumptionInsightWithType {
  insight: ConsumptionInsight;
  isAlert: boolean;
}

export interface ConsumptionInsightByDay {
  habit: ConsumptionInsight;
  alert: ConsumptionInsight;
}

export interface ConsumptionInsightsGroups {
  habits: Record<string, ConsumptionInsight>;
  alerts: Record<string, ConsumptionInsight>;
}
