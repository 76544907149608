import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo, useState } from 'react';
import { Image, ImageStyle, StyleSheet, View } from 'react-native';
import PersonalSVG from './SvgIcons/PersonalSVG';
import PetPlaceholderSVG from './SvgIcons/PetPlaceholderSVG';

export interface SpProfileImageProps {
  imageUrl?: string;
  width?: number;
  height?: number;
  isUser?: boolean;
  withBorder?: boolean;
  backgroundColor?: string;
  borderWidth?: number;
  borderColor?: string;
}

export const SpProfileImage = ({
  imageUrl,
  width = 70,
  height = 70,
  isUser = false,
  withBorder = false,
  backgroundColor = colors.greyAccent.color,
  borderWidth = 3,
  borderColor = colors.white.color,
}: SpProfileImageProps) => {
  const [imageLoading, setImageLoading] = useState(true);
  const borderStyle: ImageStyle = withBorder
    ? {
        borderWidth,
        borderColor,
        borderRadius: width / 2,
      }
    : {
        borderRadius: width / 2,
      };
  const sizeStyle: ImageStyle = {
    minWidth: width,
    minHeight: height,
    width,
    height,
  };

  const image = useCallback(() => {
    if (isUser) {
      return imageUrl ? (
        <Image
          {...testProperties('user profile', 'photo')}
          style={[sizeStyle, borderStyle]}
          source={{ uri: imageUrl }}
          resizeMode="cover"
        />
      ) : (
        <View
          style={borderStyle}
          {...testProperties('user profile no photo', 'icon')}>
          <PersonalSVG
            color={colors.greyText.color}
            width={width}
            height={height}
            viewBox="0 0 16 16"
          />
        </View>
      );
    }

    return (
      <>
        {!imageUrl && (
          <View
          {...testProperties('PetProfileNo', 'Photo')}
            style={borderStyle}>
            <PetPlaceholderSVG
              width={width}
              height={height}
              viewBox="0 0 120 120"
            />
          </View>
        )}
        {imageUrl && (
          <Image
            {...testProperties('pet profile', 'Photo')}
            style={[sizeStyle, borderStyle]}
            source={imageUrl && { uri: imageUrl || '' }}
            resizeMode="cover"
            onLoad={imageUrl ? () => setImageLoading(false) : undefined}
          />
        )}
      </>
    );
  }, [imageUrl, imageLoading, borderStyle, sizeStyle]);

  return (
    <View
      {...testProperties('innerWrapper', 'Image', '', true)}
      style={[styles.imageWrapper, { backgroundColor }, sizeStyle, borderStyle]}>
      {image()}
    </View>
  );
};

const styles = StyleSheet.create({
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  icon: { position: 'relative', top: '12%' },
  invisible: {
    position: 'absolute',
    opacity: 0,
  },
});
