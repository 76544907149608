import React, { useCallback, useMemo, useState } from 'react';
import BottomSheetModal, {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetFlatList,
} from '@gorhom/bottom-sheet';
import PetItem from 'src/pages/Flows/components/PetItem';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../../../../components/SpText/SpText';
import { useTranslation } from 'react-i18next';
import { ListRenderItem, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import useBoundStore from 'src/store/store';
import { PetModel } from '@models/Pet';

type Props = {
  petId: number;
  setIsShown: (value: boolean) => void;
  resetTab: () => void;
};

const AllPetsBottomSheet: React.FC<Props> = ({ petId, setIsShown, resetTab }) => {
  const pets = usePetsByHousehold();
  const { t } = useTranslation();
  const { setActivePet } = useBoundStore(state => state.petStore);

  const pressHandler = (id: number) => {
    setActivePet(id);
    setIsShown(false);
    resetTab();
  };
  const BottomSheetBackdropWrapper = useCallback(
    (drop: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...drop}
        disappearsOnIndex={-1}
        appearsOnIndex={0}
        opacity={0.5}
      />
    ),
    [],
  );

  const renderItem: ListRenderItem<PetModel> = ({ item }) => {
    const isSelected = item.id === petId;
    return (
      <PetItem
        handlePress={() => pressHandler(item.id)}
        name={item.name}
        imageUrl={item.photo?.location}
        assigned={isSelected}
        withAssignedIcon={isSelected}
      />
    );
  };

  const snapPoints = useMemo(() => {
    let num = 0;
    const { length } = pets;

    if (length <= 3) {
      num = 40;
    } else if (length < 5) {
      num = 60;
    } else {
      num = 85;
    }

    return [`${num}%`];
  }, [pets.length]);

  return (
    <BottomSheetModal
      snapPoints={snapPoints}
      enablePanDownToClose
      onClose={() => setIsShown(false)}
      backdropComponent={BottomSheetBackdropWrapper}
      style={styles.underSheet}
      backgroundStyle={styles.sheet}>
      <SpView style={styles.header}>
        <SpText style={[textStyles.loadingText, styles.headerText]}>{t('all_pets')}</SpText>
      </SpView>
      <BottomSheetFlatList
        data={pets}
        contentContainerStyle={styles.container}
        keyExtractor={item => `${item.id}`}
        renderItem={renderItem}
      />
    </BottomSheetModal>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 19,
    paddingTop: 32,
    paddingBottom: 20,
    backgroundColor: colors.white.color,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  headerText: {
    fontSize: 16,
    marginTop: 0,
  },
  sheet: {},
  underSheet: {
    backgroundColor: colors.white.color,
    borderRadius: 20,
  },
});

export default AllPetsBottomSheet;
