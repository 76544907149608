import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { MappedConsumptionReportDataModel } from '@models/ReportModel';
import { differenceInSeconds, parseISO } from 'date-fns';
import React, { useMemo } from 'react';

export function useTransformFeedingEventToReport(data: MappedConsumptionReportDataModel) {
  const userTimeFormat = useUserTimeUnits();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertSecondToFormat, timeToFormat, convertDateToTime } = useTimeTransform();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  return useMemo(() => {
    const lengthInSeconds = differenceInSeconds(
      parseISO(data.to.toString()).getTime(),
      parseISO(data.from.toString()).getTime(),
    );
    const duration = convertSecondToFormat(lengthInSeconds);
    const time = timeToFormat(convertDateToTime(data.from), userTimeFormat);
    const leftWeight = Math.abs(data.weights[0]?.change || 0);
    const rightWeight = Math.abs(data.weights[1]?.change || 0);

    const leftBowl = {
      type: data.weights[0]?.food_type_id || null,
      bowlCount: data.bowl_count,
      value: convertWithUnits(leftWeight, true),
    };
    const rightBowl = {
      type: data.weights[1]?.food_type_id || null,
      bowlCount: data.bowl_count,
      value: convertWithUnits(rightWeight, true),
    };

    return {
      time,
      duration: duration.unit === 'TIME_S_UNIT' ? `00:${duration.time}` : `${duration.time}`,
      leftBowl,
      rightBowl,
    };
  }, [data, convertSecondToFormat, userTimeFormat]);
}
