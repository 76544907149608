import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { TagProfile } from '@constants/Tag';
import { useDevicesSearchService } from '@hooks/useDeviceService';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import { StepFormStepsObj, StepsObjWrap } from '@models/FormikTypes';
import { PetModel } from '@models/Pet';
import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { navigateToProducts } from '@utils/navigationShortcuts';
import { t } from 'i18next';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AppState, Linking, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import EssenceCreated from 'src/components/EssenceCreated';
import useBoundStore from 'src/store/store';

import StepFormCreator from '../../../../components/StepFormCreator';
import useValidation from '../../../validation';
import AddProduct from '../../Generic/AddProduct/AddProduct';
import { BeforeYouContinue } from '../../Generic/BeforeYouContinue';
import { CompleteSetupProduct } from '../../Generic/CompleteSetupProduct';
import NameForm from '../../Generic/NameForm';
import { FlowStackParamList } from '../../index';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddCatFlap'>;

const AddCatFlap = ({ route }: Props) => {
  const [step, setStep] = useState(route?.params?.step || 1);
  const [deviceIdentifier, setDeviceIdentifier] = useState(route?.params?.deviceId);
  const { loadDevice, updateNameRequest, getDeviceById } = useBoundStore(s => s.deviceStore);
  const [submitValues, setSubmitValues] = useState<any>();
  const navigation = useNavigation<NavigationProp<any>>();
  const [tryTimes, setTryTimes] = useState(0);
  const { commonNameSchema } = useValidation();
  const [assignedPets, setAssignedPets] = useState<PetModel[]>([]);
  const { getPetByTagId } = useBoundStore(state => state.petStore);
  const [searchTimeOut, setSearchTimeOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [name, setName] = useState<string>('');
  const isFocused = useIsFocused();
  const [indoorModeEnabledPets, setIndoorModeEnabledPets] = useState<PetModel[]>([]);

  const { deviceSearchService, connected, isLoading, otherDevices, maxRetriesError } =
    useDevicesSearchService(DeviceType.CatFlapConnect, setSelectedDevice);

  const loadingControl = useBoundStore(state => state.deviceStore.loadingControl);
  const deviceStatus = useDeviceStatusHook(deviceIdentifier, true);

  useLayoutEffect(() => {
    setStep(route?.params?.step || 1);
  }, [route?.params?.step]);

  useEffect(() => {
    (async () => {
      await loadDevice(true);
      if (!selectedDevice && deviceIdentifier) {
        setSelectedDevice(getDeviceById(deviceIdentifier));
      }
    })();
  }, [selectedDevice, deviceIdentifier]);

  useEffect(() => {
    setAssignedPets([]);
    setLoading(true);
    (async () => {
      await loadDevice(true);
      if (loadingControl?.tag_profiles?.length > 0) {
        getDeviceById(selectedDevice?.id)?.tags.forEach(tag => {
          const pet = getPetByTagId(tag.id);
          if (pet) {
            pet.profile = tag.profile;
            setAssignedPets(old => [...old, pet]);
            if (pet.profile === TagProfile.Safe) {
              setIndoorModeEnabledPets(old => [...old, pet]);
            }
          }
        });
      }
      setLoading(false);
    })();
  }, [isFocused, selectedDevice, loadingControl]);

  useEffect(() => {
    if (!isLoading && step === 2) setSearchTimeOut(true);
  }, [isLoading, otherDevices]);

  const appStateRef = useRef(false);

  useEffect(() => {
    if (AppState.currentState !== 'active') {
      deviceSearchService.stop();
      appStateRef.current = true;
    }

    if (appStateRef.current && step === 2 && !maxRetriesError) {
      deviceSearchService.start();
      appStateRef.current = false;
    }
  }, [AppState.currentState]);

  useEffect(() => {
    return () => {
      deviceSearchService?.stop();
    };
  }, []);

  useEffect(() => {
    if (step === 2 && !maxRetriesError) {
      deviceSearchService.start();
    }
    return () => {
      return deviceSearchService ? deviceSearchService.stop() : null;
    };
  }, [step, deviceSearchService, selectedDevice]);

  const tryAgain = () => {
    if (tryTimes > 1) {
      setStep(1);
      Linking.openURL(t('get_help_url'));
      return;
    }
    deviceSearchService.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(2);
    deviceSearchService.start();
  };

  const foundDeviceStep = useMemo((): StepFormStepsObj => {
    return maxRetriesError
      ? {
          ui: (
            <AddProduct
              withSubtitle={false}
              connected={connected}
              searchMsg={t('cat_flap_looking_msg')}
              tip={t('cat_flap_tip')}
              imageUrl={AppImages.pairCatFlap}
              subtitle={t('cat_flap_tip')}
              connectedMsg={maxRetriesError ? t('cat_flap_not_found') : t('cat_flap_connected_msg')}
              maxRetriesError={maxRetriesError}
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          buttonText: tryTimes > 1 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <View style={{ marginLeft: -10, flex: 1 }}>
              <AddProduct
                withSubtitle={false}
                connected={connected}
                searchMsg={t('cat_flap_looking_msg')}
                subtitle={t('cat_flap_subtitle')}
                connectedMsg={t('cat_flap_connected_msg')}
                tip={t('cat_flap_tip')}
                imageUrl={AppImages.pairCatFlap}
                maxRetriesError={maxRetriesError}
              />
            </View>
          ),
          hideButton: !connected,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          interceptor: () => {
            if (connected && otherDevices.length === 0) {
              return true;
            }
            deviceSearchService.start();
            return false;
          },
        };
  }, [maxRetriesError, connected, deviceSearchService, tryTimes]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(selectedDevice?.id, {
          name: submitValues.name,
        });
      } catch (err) {}
    },
    [submitValues, selectedDevice],
  );

  const steps: StepsObjWrap = {
    1: {
      ui: <BeforeYouContinue productId={DeviceType.CatFlapConnect} />,
      effect: () => {
        setSearchTimeOut(false);
      },
      backBottomButton: true,
      customHeaderProps: {
        withRightButton: true,
        rightButtonText: t('get_help'),
        withCross: true,
        withLeaveButton: true,
        withArrowBack: false,
      },
    },
    2: foundDeviceStep,
    3: {
      ui: NameForm,
      props: {
        setName,
        label: t('product_name'),
        title: t('name_catflap_title'),
        subtitle: t('subtitle {{productName}} {{example}}', {
          example: t('name_catflap_example'),
          productName: t('cat_flap'),
        }),
        subtitle2: t('subtitle2'),
        disableKeyboardAwoiding: true,
        hideSubtitleOnFocus: false,
      },
      formik: { schema: commonNameSchema('CatFlap name'), names: ['name'] },
      customHeaderProps: {
        withLeaveButton: true,
        leaveButtonAction: () => {
          deviceSearchService?.stop();
          navigation.navigate('DashboardNavigation');
        },
      },
      keyboardAwoidingProps: {
        androidAdjustType: 'custom',
      },
      buttonDisabled: name === '',
    },
    4: {
      ui: (
        <CompleteSetupProduct
          productId={DeviceType.CatFlapConnect}
          onLoadedCallback={onSubmit}
          deviceId={selectedDevice?.id}
          showFooter={false}
          hideLink
        />
      ),
      textAboveButton: t('lets_add_pet_now'),
      invisibleHeader: true,
      interceptor: () => {
        navigation.navigate('AddPets', {
          deviceId: selectedDevice?.id,
          deviceType: DeviceType.CatFlapConnect,
          step: 3,
          returnToStep: step + 1,
        });
        return false;
      },
      handleAndroidBackPress: () => {
        navigateToProducts();
        return true;
      },
    },
    5: {
      ui: (
        <View style={[styles.center, { marginTop: 112 }]}>
          <EssenceCreated
            msg={t('setup_complete')}
            initialValue
          />
        </View>
      ),
      interceptor: () => {
        navigateToProducts();
        return false;
      },
      handleAndroidBackPress: () => {
        navigateToProducts();
        return true;
      },
    },
  };

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      steps={steps}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
    />
  );
};

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
});

export default AddCatFlap;
