import NetInfo from '@react-native-community/netinfo';
import i18n from '@utils/i18n';
import axios, { AxiosError } from 'axios';
import memoize from 'memoizee';
import Toast from 'react-native-toast-message';

import { Env } from '../../Env';
import useBoundStore, { resetAllStores } from '../store/store';

interface InterceptorOptions {
  getStoreOptions: () => {
    token: string;
    locale: string;
    colour: string;
  };
  envOveride?: string;
}

const Http = axios.create({
  baseURL: `https://app-api.${Env.APP_ENV}.surehub.io`,

  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});
let isOfflineToastVisible = false;

export function applyInterceptors(options: InterceptorOptions): void {
  console.log('Applying interceptors', Env.APP_ENV, options.envOveride);
  Http.interceptors.request.use(
    request => {
      const { token, locale, colour } = options.getStoreOptions();
      const envOveride = options.envOveride;
      const forceToken = request?.headers?.forceToken;
      if (envOveride) {
        request.baseURL = `https://app-api.${envOveride}.surehub.io`;
      }
      if (colour) {
        request.baseURL = `https://app-api.${colour}.${envOveride ? envOveride : Env.APP_ENV}.surehub.io`;
      }
      if (forceToken || token) {
        // console.log('This is the token: ', forceToken, token)
        request.headers.Authorization = `Bearer ${forceToken || token}`;
      }

      if (locale) {
        request.headers['Accept-Language'] = locale;
      }

      request.headers['spc-client-type'] = 'react';

      if (forceToken) {
        delete request?.headers?.forceToken;
      }
      //clear cache on put and post requests
      if (request.method === 'put' || request.method === 'post') {
        console.log('clearing cache');
        MemoizedHttp.get.clear();
      }
      return request;
    },
    error => Promise.reject(error),
  );

  Http.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const skipUnauthorizedStatus = error.config?.headers?.skipUnauthorizedStatus;
      const state = useBoundStore.getState();
      const settingUpWiFiDevice = state.WiFiDeviceStore.settingUpWiFiDevice;

      NetInfo.fetch().then(state => {
        if (!state.isConnected && !isOfflineToastVisible && !settingUpWiFiDevice) {
          Toast.show({
            type: 'generalToast',
            props: {
              description: i18n.t('offline_mode_error'),
              isError: true,
            },
            visibilityTime: 10000,
            onHide: () => {
              isOfflineToastVisible = false;
            },
          });
          isOfflineToastVisible = true;
        }
      });

      if (error?.response?.status === 401 && !skipUnauthorizedStatus) {
        // alert("Unauthorized - " + Http.defaults.baseURL);
        resetAllStores();
      }
      return Promise.reject(error?.response || error);
    },
  );
}

export const MemoizedHttp = {
  get: memoize(
    (url, config) => {
      return Http.get(url, config);
    },
    {
      promise: true,
      maxAge: 3000,
      normalizer: args => {
        // Create a unique key based on the URL and any other parameter that should affect caching
        // This assumes `args` is an array of [url, options]
        return JSON.stringify({ url: args[0] });
      },
    },
  ),
};

export default Http;
